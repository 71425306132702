import React from 'react';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../../components/Destination.css';
import CommentsSection from '../../components/CommentsSection';
import DestinationSelector from '../../components/DestinationSelector';

const Destination3 = () => {
  const location = useLocation();
  const paragraphMarker = location.state?.textIdentifier || '';
  console.log(paragraphMarker);

  useEffect(() => {
    if (paragraphMarker) {
      const elementToHighlight = document.querySelector(`.${paragraphMarker}`);
      if (elementToHighlight) {
        elementToHighlight.classList.add('highlight');
        elementToHighlight.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [paragraphMarker]);

  const imageData = [
          { key: 1, src: '../../andorra1.jpg', altText: 'Andorra-Ulaşım'},
          { key: 2, src: '../../andorra2.jpg', altText: 'Andorra-Eriyen-saat'},
          { key: 3, src: '../../andorra3.jpg', altText: 'Andorra-Puente-De-Paris'},
          { key: 4, src: '../../andorra4.jpg', altText: 'Andorra-Parc-Central'},
          { key: 5, src: '../../andorra5.jpg', altText: 'Andorra-bici-lab'},
          { key: 6, src: '../../andorra6.jpg', altText: 'Andorra-Casa-de-Vall'},
          { key: 7, src: '../../andorra7.jpg', altText: 'Andorra-Sant-Esteve'},
  ];

  const attractions = [
    { name: "ANDORRA’YA ULAŞIM", id: "andorra-ulastim" },
    { name: "Salvador Dali’nin Eriyen Saat Heykeli", id: "eriyen-saat-heykeli" },
    { name: "Puente De Paris Köprüsü", id: "puente-de-paris-koprusu" },
    { name: "Parc Central", id: "parc-central" },
    { name: "Museu Bici Lab Andorra", id: "museu-bici-lab-andorra" },
    { name: "Casa De La Vall", id: "casa-de-la-vall" },
    { name: "Sant Esteve Kilisesi", id: "sant-esteve-kilisesi" },
    { name: "Sant Pere Martir Kilisesi", id: "sant-pere-martir-kilisesi" },
    { name: "Caldea", id: "caldea" },
    { name: "Avinguda Meritxell", id: "avinguda-meritxell" },
    { name: "Önemli Bilgiler", id: "onemli-bilgiler" },
    { name: "Sonsöz", id: "sonsoz" },
    { name: "Google Haritalar için Yürüme Rotası", id: "yuruyus" },
  ];

  const scrollToAttraction = (attractionId) => {
    const elementToScroll = document.getElementById(attractionId);
    if (elementToScroll) {
      elementToScroll.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const importantTips = [
    "Eğer bilgisayar satın almak istiyorsanız en uygun ve çeşidin bol olduğu yerlerden biri Pyrénées Andorra alışveriş merkezinin içinde bulunan Fnac mağazası. Tabii ki de birçok yerde farklı seçenekler var fakat dediğimiz gibi çok fazla yer gezdik istediğimiz modelleri bulabilmek için. Sonunda en fazla modeli burada bulduk ve buradan almayı tercih ettik.Eğer bilgisayar satın almak istiyorsanız en uygun ve çeşidin bol olduğu yerlerden biri Pyrénées Andorra alışveriş merkezinin içinde bulunan Fnac mağazası. Tabii ki de birçok yerde farklı seçenekler var fakat dediğimiz gibi çok fazla yer gezdik istediğimiz modelleri bulabilmek için. Sonunda en fazla modeli burada bulduk ve buradan almayı tercih ettik.",
    "Fotoğraf makinesi ve kamera arıyorsanız mutlaka bakacağınız dükkan ‘’foto clic’’ olmalı. Lens, kamera, fotoğraf makinesi ve parçaları olarak çeşitlilik fazla. Kameranın yanında SD kart vs. alacaksanız belki biraz pazarlıkla fiyatta indirim yaptırabilirsiniz. Biz yaptırdık 😛",
    "Haziran sonu Temmuz başı gibi giderseniz Jazz Festivaline denk gelip bazı konserlere ücretsiz bir şekilde katılabilirsiniz. :) Gitmeden önce tarihleri ve programı güncel olarak buradan öğrenebilirsiniz. https://visitandorra.com/es/agenda/jazz-andorra-escaldes-engordany/ Bizim gittiğimiz gün Rita Payés ve Juan Pastor ile Lucas Delgado Trio grubu vardı ve çok da keyifli bir dinleti olmuştu.",
    "Carrefour 2000 alışveriş merkezinin otoparkına bırakmış olduğumuz araba otopark ücreti için  saat 12:30’dan akşam 9 ya da 9 buçuğa kadar sanırım 16 euro park ücreti ödedik.",
    "Eğer arabayla geldiyseniz dönüş yoluna hava kararmadan çıkmanız en iyi seçenek olacaktır. Biz hafif hava kararırken çıkmamıza rağmen Girona Havalimanına gece yarısı 00:30’da vardık. Ücretsiz yolu tercih ettiğimizden ve yolların çok dar, virajlı ve dağlık olmasından dolayı baya geç vardık. Yolda açıkçası durup dinlenecek hiçbir yer göremedik. Mutlaka yanınıza yolda tüketebilmeniz için su ve yiyecek bir şeyler alın.",
    "Son olarak araç kiralamışsanız ve depoyu fulleyip vermeniz gerekiyorsa Girona Havalimanı içinde araca benzin alabilmeniz için kredi kartının geçerli olduğu minik istasyonlar var onları kullanabilirsiniz."
  ];

  return (
    <div className="destination-container">
      <Helmet>
        <title>Andorra la Vella, Andorra - Hangiyol Seyahat Rehberi</title>
        <meta name="description" content="Andorra la Vella, Andorra'yı 1 günde keşfedin! Elektronik alışveriş cenneti, etkileyici köprüler, yeşil parklar, tarihi mekanlar ve daha fazlasıyla tanınan bu güzel şehri keşfetmeye katılın. Ziyaretinizi planlayın, faydalı ipuçları bulun ve bu detaylı seyahat rehberinde Andorra'nın özünü yaşayın. Hızlı bir kaçamak için mükemmel!" />
      </Helmet>
      <h2 className="aaa">ANDORRA’DA 1 GÜNDÜZ</h2>
      <br/>
      <div className="destination-content">
        <div>
        <ul>
          {attractions.map((attraction) => (
            <li key={attraction.name}>
              <button 
                onClick={() => scrollToAttraction(attraction.id)}
                style={{
                  border: 'none',         
                  cursor: 'pointer',          
                  borderRadius: '0.35rem',                           
                }}
              >
                {attraction.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
        <p className="ccc">
          Eveet Barselona seyahatimizin son gününe eklemiş olduğumuz elektronik alışveriş odaklı gittiğimiz fakat gezi olarak da bizi mutlu eden bir seyahat rotası oldu.Barselona’dan Andorra’ya nasıl gittik? Nereleri gezdik? Sonrasında neler oldu? Merak ediyorsanız sizleri aşağıya alalım. 🙂
        </p>
        <h3 className="ddd" id="andorra-ulastim">ANDORRA’YA ULAŞIM</h3>
        <div className='content-image-container'>
          <div className="content-image-landscape"
              style={{ backgroundImage: `url(${imageData[0].src})` }}
              aria-label={imageData[0].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="eee">
          Andorra’da havalimanı olmadığı için İspanya’dan ya da Fransa’dan otobüs, araba ya da tur şirketleriyle gidebilirsiniz. Yaklaşık 200 km olan bu yol her ne kadar az gibi gözükse de dağlara tırmanarak gidildiği için gündüz 3 buçuk gece ise 4 saate kadar ulaşabiliyor. Bu yüzden yola erken çıkmakta fayda var. Boşuna dememişler erken kalkan yol alır diye. 🙂
        </p>
        <br/>
        <p className="fff">
          Otobüsle gitmek isterseniz Barselona Havaalanından ya da Tren Garından kalkan otobüslere binip yaklaşık 3 buçuk saatte Andorra La Vella’ya varabilirsiniz. Diğer bir seçenekse araba kiralamak. Biz bilindik bir araba kiralama şirketinden Barselona’dan arabayı alıp Girona havaalanına bırakacak şekilde 1 günlük kiraladık. Eğer iki ya da daha fazla kişiyseniz araba kiralamak ekonomik olarak çok daha mantıklı olacaktır.
        </p>
        <h3 className="iii" id="eriyen-saat-heykeli">1-Salvador Dali’nin Eriyen Saat Heykeli</h3>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[1].src})` }}
              aria-label={imageData[1].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="jjj">
          Meydanda dikkat çeken ilk şey tabii ki de Salvador Dali tarafından yapılmış ve insanlığa hükmeden geçen zamanı simgeleyen göz alıcı bir heykel. Hemen arkasında Puente De Paris Köprüsü var. FOTO
        </p>
        <h3 className="kkk" id="puente-de-paris-koprusu">2- Puente De Paris Köprüsü</h3>
        <div className='content-image-container'>
        <div className="content-image-portrait"
             style={{ backgroundImage: `url(${imageData[2].src})` }}
             aria-label={imageData[2].altText}
             loading="lazy"
        ></div>
        </div>
        <p className="lll">
          Valira nehri üzerinde yer alır ve Consell d'Europa caddesi ile Doctor Mitjavila caddesini birleştirir. Muhteşem bir dağ manzarası vardır.
        </p>
        <h3 className="mmm" id="parc-central">3- Parc Central</h3>
        <div className='content-image-container'>
        <div className="content-image-landscape"
             style={{ backgroundImage: `url(${imageData[3].src})` }}
             aria-label={imageData[3].altText}
             loading="lazy"
        ></div>
        </div>
        <p className="nnn">
          Şehrin göbeğinde  bir yandan nehrin sesi bir yandan yemyeşil bir alan. Özellikle çocuklu aileler bu konumu dinlenmek için değerlendirebilir. Parkın içinde çok fazla oyun alanı da var.
        </p>
        <h3 className="ooo" id="museu-bici-lab-andorra">4- Museu Bıcı Lab Andorra</h3>
        <div className='content-image-container'>
        <div className="content-image-portrait"
             style={{ backgroundImage: `url(${imageData[4].src})` }}
             aria-label={imageData[4].altText}
             loading="lazy"
        ></div>
        </div>
        <p className="ppp">
          Maalesef vaktimiz kalmadığı için buraya girme fırsatımız olmadı. Eğer bisikletlere ilginiz varsa bir göz atın derim.
        </p>
        <h3 className="rrr" id="casa-de-la-vall">5- Casa De La Vall</h3>
        <div className='content-image-container'>
        <div className="content-image-portrait"
             style={{ backgroundImage: `url(${imageData[5].src})` }}
             aria-label={imageData[5].altText}
             loading="lazy"
        ></div>
        </div>
        <p className="sss">
          Andorra’da yer alan tarihi eski bir ev burası ayrıca Andorra Genel Konseyi Merkezi olarak günümüzde kullanılmaktadır. İçini de eğer gezmek isterseniz fotoğraflarda güncel 2023 Haziran ayı itibariyle giriş ücretleri ve saatlerini bulabilirsiniz. Siz yine de gitmeden önce resmi sitesinden kontrol etseniz iyi olur. Buraya tıklayarak görebilirsiniz.  www.museus.ad
        </p>
        <h3 className="ttt" id="sant-esteve-kilisesi">6- Sant Esteve Kilisesi</h3>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[6].src})` }}
              aria-label={imageData[6].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="uuu">
          12.yüzyılda inşa edilen bu kilise ülkenin en büyük Romanesk apsisini ve duvar resimlerinin kalıntılarını içerir ayrıca Andorra-la-vella’nın ana kilisesidir. Ücretsiz girip gezebilirsiniz. 
        </p>
        <h3 className="vvv" id="sant-pere-martir-kilisesi">7- Sant Pere Martir Kilisesi</h3>
        <p className="www">
          Neo-Romanesk tarzda inşa edilmiş 20.yy kiliselerinden biridir. Konumuna ve diğer güncel bilgilere resmi sitesinden buraya tıklayarak ulaşabilirsiniz. https://museus.ad/en/monumentos/sant-pere-martir
        </p>
        <h3 className="xxx" id="caldea">8- Caldea</h3>
        <p className="yyy">
          Andorra’ya geldiğinizde bu şehrin en yüksek binası olan Caldea’yı görmemeniz mümkün değil. Dış cephesi dağları yansıtması amacıyla aynayla kaplanmış. Avrupa’nın en büyük kaplıcalarından biridir. Eğer birkaç günlük vaktimiz olsaydı mutlaka uğrardık ama sizin vaktiniz olursa gidin ve bize de deneyimlerinizi yorum bırakmayı unutmayın. 🙂 Eğer rezervasyon yaptırmak isterseniz resmi sitesini buraya bırakıyorum. https://www.caldea.com/
        </p>
        <h3 className="zzz" id="avinguda-meritxell">9- Avinguda Meritxell</h3>
        <p className="abab">
         Andorra ve Andorre-la-vella’daki ana alışveriş bölgesidir. Gezmesi keyifli bir bölge ayrıca yiyeyecek, kıyafet, kozmetik, elektronik aklınıza gelebilecek her şeyi bulabilmeniz mümkün burada.
        </p>
        <h3 className="bcbc" id="onemli-bilgiler">ÖNEMLİ BİLGİLER</h3>
        <div>
          <ul>
            {importantTips.map((tips) => (
              <li key={tips}>{tips}</li>
            ))}
          </ul>
        </div>
        <h3 className="cdcd" id="sonsoz">SONSÖZ</h3>
        <p className="dede">Andorra bizim için hızlı ve güzeldi. Havanın da geç kararmasıyla gezmek için daha fazla vaktimiz oldu.  Eğer siz de bizim gibi kısa süreli geliyorsanız yürüme mesafesinde olan bu yerleri kolaylıkla gezebilirsiniz. Sorularınız olursa yorumlarda bekliyoruz.</p>
        <h3 className="xxxooopxpx" id="yuruyus">Google Maps için Andorra Yürüyüş Rotası</h3>
        <p className="wwwpxpxooo">
            Noktalar: Carrefour Andorra 2000 otoparkı, Parc Central, Museu Bıcı Lab Andorra, Casa De La Vall (Asansör aracılığıyla çıkılıyor, yürümek için uygun değil.), Sant Esteve Kilisesi, Avinguda Meritxell, Eriyen Saat Heykeli (Noblesa del Temps), Puente De Paris, Av. Carlemany.
        </p>
        <ul>
          <li className='map-links'>
            <a href="https://goo.gl/maps/QgEZX7FD5HL8JFxP8" target="_blank" rel="noopener noreferrer">Rota için link</a>
          </li>
        </ul>
        <p className="efef">Sevgiyle kalın!</p>
        <p className="fgfg">Hangiyol</p>
        <p className="ghgh">-The End-</p>
      </div>
      <br />
      <br />
      <DestinationSelector /> 
      <hr className="separator" /> 
      <CommentsSection componentName="destination3" /> 
    </div>
  );
};

export default Destination3;