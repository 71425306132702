import React from 'react';
import '../../components/Destination.css';
import CommentsSection from '../../components/CommentsSection';
import DestinationSelector from '../../components/DestinationSelector';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Destination1 = () => {
  const location = useLocation();
  const paragraphMarker = location.state?.textIdentifier || '';
  console.log(paragraphMarker);

  useEffect(() => {
    if (paragraphMarker) {
      const elementToHighlight = document.querySelector(`.${paragraphMarker}`);
      if (elementToHighlight) {
        elementToHighlight.classList.add('highlight');
        elementToHighlight.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [paragraphMarker]);

    const imageData = [
      { key: 1, src: '../../nur1.jpg', altText: 'nuremberg-harita' },
      { key: 2, src: '../../nur2.jpg', altText: 'nuremberg-handcrafts' },
      { key: 3, src: '../../nur3.jpg', altText: 'nuremberg-zeughaus' },
      { key: 4, src: '../../nur4.jpg', altText: 'nuremberg-lorenzKirche' },
      { key: 5, src: '../../nur5.jpg', altText: 'nuremberg-lorenzKircheAltar' },
      { key: 6, src: '../../nur6.jpg', altText: 'nuremberg-nassau' },
      { key: 7, src: '../../nur7.jpg', altText: 'nuremberg-tugendbrunnen' },
      { key: 8, src: '../../nur8.jpg', altText: 'nuremberg-museumBridge' },
      { key: 9, src: '../../nur9.jpg', altText: 'nuremberg-theShip' },
      { key: 10, src: '../../nur10.jpg', altText: 'nuremberg-market' },
      { key: 11, src: '../../nur11.jpg', altText: 'nuremberg-schoennenbrunnen' },
      { key: 12, src: '../../nur12.jpg', altText: 'nuremberg-weissgerbergasse' },
      { key: 13, src: '../../nur13.jpg', altText: 'nuremberg-Ehekarussell' },
      { key: 14, src: '../../nur14.jpg', altText: 'nuremberg-kettensteg' },
      { key: 15, src: '../../nur15.jpg', altText: 'nuremberg-kaiserburg' },
      { key: 16, src: '../../nur16.jpg', altText: 'nuremberg-derHase' },
      { key: 17, src: '../../nur17.jpg', altText: 'nuremberg-weisserTurm' },
      { key: 18, src: '../../nur20.jpg', altText: 'nuremberg-sanSebald' },
    ];

    const attractions1 = [
      { name: "Crafts Yard", id: "crafts-yard" },
      { name: "Lorenzkirche – Saint Lawrence Church", id: "lorenzkirche" },
      { name: "Nassau House", id: "nassau-house" },
      { name: "Tugendbrunnen – 7 erdem çeşmesi", id: "tugendbrunnen" },
      { name: "Museumsbrücke Köprüsü", id: "museumsbrucke-koprusu" },
      { name: "Narrenschiffbrunnen - Ship of Fools", id: "narrenschiffbrunnen" },
      { name: "Hauptmarkt Meydanı", id: "hauptmarkt-meydani" },
      { name: "Schöner Brunnen", id: "schoner-brunnen" },
      { name: "St. Sebald Kilisesi – Sebalduskirche", id: "st-sebald-kilisesi" },
      { name: "Weißgerbergasse Sokağı", id: "weissgerbergasse-sokagi" },
      { name: "Ehekarussell", id: "ehekarussell" },
      { name: "Beyaz Kule – WeiBer Turm", id: "beyaz-kule" },
      { name: "Kettensteg Köprüsü", id: "kettensteg-koprusu" },
      { name: "Tavşan Heykeli - Der Hase", id: "tavsan-heykeli" },
      { name: "Burggarten", id: "burggarten" },
      { name: "Kaiserburg (Nürnberg Kalesi)", id: "kaiserburg" },
      { name: "Albrecht Dürer Haus", id: "albrecht-durer-haus" },
      { name: "Google Maps için Yürüyüş Rotaları", id: "google-maps" },
    ];
    
  const attractions = [
    "Nürnberg Uluslararası Askerî Ceza Mahkemesi",
    "Reichsparteitagsgelände (Nazi Partisi Dokümantasyon Merkezi)",
    "Deutscher Hof (Hitlerin kaldığı otel)",
    "İnsan Hakları Evrensel Beyannamesi Anıtı",
    "Germanisches Nationalmuseum (Alman Ulusal Müzesi)",
    "Opera Binası",
    "Deutsches Museum Nuremberg",
    "Tucher Mansiyon Museum",
    "Hirsvogel Hall",
    "İletişim Müzesi",
    "DB Tren Yolu Müzesi",
    "Bratwurst Müzesi",
    "Doğa Tarihi Müzesi",
    "Faber-Castell Experience",
    "Dinazor Müzesi",
    "Cadolzburg Kalesi",
    "Oyuncak Müzesi"
  ];

  const scrollToAttraction = (attractionId) => {
    const elementToScroll = document.getElementById(attractionId);
    if (elementToScroll) {
      elementToScroll.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  return (
    <div className="destination-container">
      <Helmet>
        <title>Nürnberg, Almanya Gezi Rehberi - Hangiyol Seyahat Rehberi</title>
        <meta name="description" content="Nürnberg, Almanya gezi rehberi ile Ortaçağ atmosferini yaşayın! Nürnberg'de gezilecek yerler, tarihi yapılar ve turistik mekanlar hakkında detaylı bilgi edinin. Lorenzkirche, Schöner Brunnen, Nürnberg Kalesi ve daha fazlasını keşfedin. Nürnberg seyahatinizde unutulmaz anılar biriktirin. Daha fazla vaktiniz varsa, Nürnberg'de görmeniz gereken diğer cazibe merkezlerini de öğrenin. Hangiyol Seyahat Rehberi ile sorunsuz bir gezi deneyimi yaşayın!" />
      </Helmet>

      <h2 className="aaa">Nuremberg, Almanya</h2>
      <br />
      <div className="destination-content">
        <p className="bbb">
          Ortaçağa ışınlanalım diyorsanız işte Nürnberg tam da düşündüğünüz yer. Böylesine tatlı bir yer daha var mıdır bilemeyiz fakat burası o kadar şirin ve samimi bir yer ki tası tarağı bırakalım da buraya yerleşelim diyorsunuz. Ulaşım aracı kullanmadan eski şehir merkezini yaz mevsiminde sabahtan akşama fıtı fıtı yürüyerek gezebilirsiniz. Gezdiğimiz yerlerin litesini aşağıda yazıyorum:
        </p>
        <div>
        <ul>
          {attractions1.map((attraction1) => (
            <li key={attraction1.name}>
              <button 
                onClick={() => scrollToAttraction(attraction1.id)}
                style={{
                  border: 'none',         
                  cursor: 'pointer',          
                  borderRadius: '0.35rem',                           
                }}
              >
                {attraction1.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
        <br/>
        <div className='content-image-container'>
          <div className="content-image-landscape"
              style={{ backgroundImage: `url(${imageData[0].src})` }}
              aria-label={imageData[0].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="ccc">
          Birazdan anlatacağım rotayı zihninizde canlandırabilmeniz için şehir haritasının fotoğrafını buraya koyuyorum.
        </p>
        <br/>
        <p className="ddd" id="crafts-yard">
          Nürnberg Havaalanından çıkıp önce sırt çantalarımızı dışardaki emanet dolaplarına bıraktık ve 10 dakikada bir kalkan U2 metro hattıyla 15 dakikada Nürnberg Central Station’a geldik. İstasyondan çıktıktan sonra hemen karşıda eski şehrin surlarını görüyoruz ve Crafts Yard kapısından girdiğimiz gibi bizi Handwerkerhof ismiyle bilinen zanaatkarlar çarşısı karşılıyor.
        </p>
        <br/>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[1].src})` }}
              aria-label={imageData[1].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="eee">
          Buradaki dükkanlarda el yapımı çok güzel hediyelik ve geleneksel eşyalar bulabilirsiniz mutlaka gezip gözlerinize bayram ettirin.
        </p>
        <br/>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[2].src})` }}
              aria-label={imageData[2].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="fff">
          Çoğu gezilecek yere giderken yolunuzun üstü olacağı için %90 bu binayla karşılaşacaksınız. Zamanında cephanelik olarak inşa edilen Zeughaus günümüzde karakol olarak kullanılıyor bü yüzden de içi gezilmiyor.  
        </p>
        <br/>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[3].src})` }}
              aria-label={imageData[3].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="ggg" id="lorenzkirche">
          Gezimize yürüyerek devam ediyor ve Lorenzkirche’ye (Saint Lawrence Church) geliyoruz. Ücretsiz bir şekilde girip kendiniz gezebilir ya da bağış karşılığı 45 dakikalık rehberli tur alabilirsiniz. Biz kendimiz gezdik.
        </p>
        <br/>
        <div className='content-image-container'>
          <div className="content-image-landscape"
              style={{ backgroundImage: `url(${imageData[4].src})` }}
              aria-label={imageData[4].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="hhh">
          İçeride 14. ve 15. yy’dan kalma sunaklar ve görmeye pek alışık olmadığımız Gülümseyen Madonna Heykeli de var.
        </p>
        <br/>
        <h3 className="iii" id="nassau-house">Nassau House</h3>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[5].src})` }}
              aria-label={imageData[5].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="jjj">
          Saint Lorenz kilisesinden çıkınca karşımızda kalıyor. İki cephesinde de  güneş saati var. Hediye paketi gibi 🎉
        </p>
        <br/>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[6].src})` }}
              aria-label={imageData[6].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="kkk" id="tugendbrunnen">
          Königstraße caddesinden yürümeye devam ediyoruz ve sağ tarafımızda Tugendbrunnen yani 7 erdem çeşmesi kalıyor.
        </p>
        <br/>
        <div className='content-image-container'>
          <div className="content-image-landscape"
              style={{ backgroundImage: `url(${imageData[7].src})` }}
              aria-label={imageData[7].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="lll" id="museumsbrucke-koprusu">
          Hala aynı caddeden devam edip Museumsbrücke köprüsüne varıyoruz. Köprüden bakınca eskiden hastane olan fakat şu an restoran olarak hizmet veren Heilig Gest Spital’i görüyoruz. Bu köprüde mola verip köprünün karşısında kafede kahvaltımızı yapıp gezmeye devam ediyoruz.
        </p>
        <br/>
        <h3 className="mmm" id="narrenschiffbrunnen">Narrenschiffbrunnen - Ship of Fools</h3>
        <div className='content-image-container'>
          <div className="content-image-landscape"
              style={{ backgroundImage: `url(${imageData[8].src})` }}
              aria-label={imageData[8].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="nnn">
          Çeşme olarak düşünülen fakat tasarımsal hatalar yüzünden çeşme olamayan heykel olarak kalmış.
        </p>
        <br/>
        <div className='content-image-container'>
          <div className="content-image-landscape"
              style={{ backgroundImage: `url(${imageData[9].src})` }}
              aria-label={imageData[9].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="ooo" id="hauptmarkt-meydani">
          Ve Hauptmarkt meydanına geliyoruz. Meydanda taze meyve sebzeler ve bolca yerel lezzetlerin ayrıca eşyaların satıldığı pazar tezgahları var. Üstelik Church of our lady olarak da bilinen Frauenkirche ve Schöner Brunnen de bu meydanda bulunuyor. Kilise bizim gittiğimiz dönemde restorasyon sebebiyle kapalı olduğu için ziyaret edemedik. Fakat her gün saat 12:00 da 5 dakika süren mini bir saat gösterisi oluyor neyse ki onu yakaladık. Prensler imparatorun önünden geçerek saygılarını sunuyorlar. O saatlerde oradaysanız kaçırmayın derim.
        </p>
        <br/>
        <h3 className="ppp" id="schoner-brunnen">Schöner Brunnen</h3>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[10].src})` }}
              aria-label={imageData[10].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="rrr">
          Şehrin vebadan kurtulması şerefine yapılan 4 katlı, altın kaplamalı  görkemli çeşme karşılıyor bizi. Çeşmenin demirlerinde bulunan pirinç halkaları tam tur çevirirseniz dilekleriniz gerçek oluyormuş,hemen dileklerimizi diliyoruz biz de. 😀
        </p>
        <br/>
        <h3 className="sss" id="st-sebald-kilisesi">St. Sebald Kilisesi – Sebalduskirche</h3>
        <p>
          Bir zamanlar Nürnberg müzisyeni ve bestecisi Johann Pachelbel’in orgcu olduğu gotik bir kilisedir. II.Dünya savaşında neredeyse tamamen yıkılmış sonrasında tekrardan yapılmış harika vitraylara sahip huzur dolu bir yer.
        </p>
        <br/>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[17].src})` }}
              aria-label={imageData[17].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="ttt" id="weissgerbergasse-sokagi">
          Ve sırada Nürnberg denince ilk karşımıza çıkacak fotoğrafların çekildiği Weißgerbergasse sokağı var. Orta çağdan kalma yarı ahşap  evler çok güzel bir şekilde korunmuş. Hatta bazı evlerin sığınakları bira mahzeni olarak kullanılıyormuş.
        </p>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[11].src})` }}
              aria-label={imageData[11].altText}
              loading="lazy"
          ></div>
        </div>
        <br/>
        <h3 className="uuu" id="ehekarussell">Ehekarussell</h3>
        <div className='content-image-container'>
          <div className="content-image-landscape"
              style={{ backgroundImage: `url(${imageData[12].src})` }}
              aria-label={imageData[12].altText}
              loading="lazy"
          ></div>
        </div>
        <p>
          Şehirdeki önemli modern sanat eserlerinden biri olan, ilginç motiflerle çevrili çeşme. Dikkatli incelediğinizde evliliğin aşamalarını göreceğiniz bu tasarım, Hans Sachs'ın evlilikteki neşe ve kederi anlattığı şiirinden esinlenilmiş. Çeşmenin hemen arkasında savaştan sonra onarım çalışmaları sırasında beyaz sıvaları çıkarılan ve artık beyaz olmayan Beyaz Kule var. 
        </p>
        <br/>
        <h3 className="vvv" id="beyaz-kule">WeiBer Turm – Beyaz Kule</h3>
        <p className="www">
          Şehre açılan kapı olarak tarihi bir kuledir. Gezmek isterseniz kulenin yakınında Saint Jacob Kilisesi var, ücretsiz bir şekilde gezebilirsiniz.
        </p>
        <br/>
        <h3 className="yyy" id="kettensteg-koprusu">Kettensteg Köprüsü</h3>
        <div className='content-image-container'>
          <div className="content-image-landscape"
              style={{ backgroundImage: `url(${imageData[13].src})` }}
              aria-label={imageData[13].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="zzz">
          1824 de inşa edilen Almanya'daki ilk zincir asma köprü. Manzarası huzur dolu. Köprüden geçerken Max Köprüsünü görüyorsunuz.
        </p>
        <br/>
        <h3 className="abab" id="tavsan-heykeli">Der Hase - Tavşan Heykeli</h3>

        <p className="bcbc">
          Dürer’in genç tavşan tablosuna karşılık olarak yapılmış olan bu heykel çiftliklerde hayvanların kafeslenmesini eleştiriyormuş.
        </p>
        <br/>
        <h3 className="cdcd" id="burggarten">Burggarten</h3>
        <p className="dede">
          Çiçeklerle ve ağaçlarla donatılmış Nürnberg manzarası sunan bir bahçe. Kitabınızı alıp küçük bir dinlenme molası verebilirsiniz.
        </p>
        <br/>
        <h3 className="efef" id="kaiserburg">Kaiserburg (Nürnberg Kalesi)</h3>
        <div className='content-image-container'>
          <div className="content-image-landscape"
              style={{ backgroundImage: `url(${imageData[14].src})` }}
              aria-label={imageData[14].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="fgfg">
          Şehre yukarıdan bakmak istiyorsanız haydi kaleye. İşte Nürnberg ayaklarımızın altında tüm gezdiğimiz yerleri panoramik bir şekilde görüyoruz. Kalenin iç içe geçmiş bahçelerini gezdikten sonra burada bulunan müzeyi gezebilir isterseniz kuleye de çıkabilirsiniz. Biz kapanmaya yakın saatlerde gittiğimiz için müzeye girmedik. Kalenin surlarının yanından inerek Albrecht Dürer Haus’un olduğu meydana iniyoruz.
        </p>
        <div className='content-image-container'>
          <div className="content-image-landscape"
              style={{ backgroundImage: `url(${imageData[15].src})` }}
              aria-label={imageData[15].altText}
              loading="lazy"
          ></div>
        </div>
        <br/>
        <h3 className="ghgh" id="albrecht-durer-haus">Albrecht Dürer Haus</h3>
        <p className="hihi">
          Meydan o kadar kalabalık ki her yer tıklım tıklım dolu. Oturacak tek bir masa yok. İnsanlar taşlarda oturuyor. Biz de meydanda bardan aldığımız biralarla hemen bir banka ilişiyoruz ve biralarımızı yudumlarken burada yaşasaydık şöyle böyle olurdu diye olmazsa olmaz sohbetimizi ediyoruz. 😂 Derin sohbetimizi bitirdikten sonra Beyaz Kule’nin olduğu meydana tekrar gidiyoruz. Lebenshilfe etkinliğinden dolayı meydanda hem müzik hem de yemek tezgahları vardı. Burada geleneksel sosisli sandviçlerimizi de yedikten sonra Nürnberg’e artık veda zamanı geliyor. Tin tin yürüyerek tren istasyonuna gidip yine U2 metrosuyla havalimanına varıyoruz. Biz Nürnberg’i çok sevdik ve anılarımızı sizinle de paylaşmak istedik. Umarız sizlerin de işinize yarar.
        </p>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[16].src})` }}
              aria-label={imageData[16].altText}
              loading="lazy"
          ></div>
        </div>
        <br/>
        <h3 className="xxxooopxpx" id="google-maps">Google Maps için Yürüyüş Rotaları</h3>
        <p className="wwwpxpx">
            Yürüyüş rotası 1. kısım: Nürnberg Hauptbahnhof, Handwerkwehof, Zeughaus, Lorenzkirche, Nassauer Haus, Tugendbrunnen, Museumsbrücke, Narrenschiffbrunnen, Hauptmarkt.
        </p>
        <ul>
          <li className='map-links'>
            <a href="https://maps.app.goo.gl/aWg5fxkHzTRxu1u69" target="_blank" rel="noopener noreferrer">Rota 1. kısım için link</a>
          </li>
        </ul>
        <p className="xxxpxpx">
            Yürüyüş rotası 2. kısım: St. Sebalduskirche, Weissgerbergasse, Ehekarussell, Weisser Turm, Kettensteg, Der Hase, Burggarten, Nuremberg Kalesi.  
        </p>
        <ul>
          <li className='map-links'>
            <a href="https://maps.app.goo.gl/1mWswaLwAp1dBMxi9" target="_blank" rel="noopener noreferrer">Rota 2. kısım için link</a>
          </li>
        </ul>
        <br/>
        <h3 className="ijij">NOT:</h3>
        <p className="jkjk">
          Nürnberg bizim için aktarma şehri olduğu için her yeri gezelim, her deliğe girelim mantığıyla değil de günümüzü keyifli geçirelim rahatça görebildiğimiz kadar gezelim mantığıyla geldiğimiz bir şehirdi. Bu yüzden bizim gitmediğimiz fakat gezmek isteyeceğiniz diğer yerleri de buraya yazıyorum. Eğer daha fazla vaktiniz varsa ve gezmek isterseniz alternatif yerler aşağıda.
        </p>
        <div>
          <ul>
            {attractions.map((attraction) => (
              <li key={attraction}>{attraction}</li>
            ))}
          </ul>
        </div>
        <br/>
        <p className="klkl">
          Sevgiyle Kalın!
        </p>
      </div>
      <br />
      <br />
      <DestinationSelector /> 
      <hr className="separator" /> 
      <CommentsSection componentName="destination1" /> 
    </div>
  );
};

export default Destination1;
