import React from 'react';
import '../../components/Destination.css';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CommentsSection from '../../components/CommentsSection';
import DestinationSelector from '../../components/DestinationSelector';

const Destination4 = () => {
  const location = useLocation();
  const paragraphMarker = location.state?.textIdentifier || '';
  console.log(paragraphMarker);

  useEffect(() => {
    if (paragraphMarker) {
      const elementToHighlight = document.querySelector(`.${paragraphMarker}`);
      if (elementToHighlight) {
        elementToHighlight.classList.add('highlight');
        elementToHighlight.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [paragraphMarker]);

      const imageData = [
        { key: 1, src: '../../flor1.jpg', altText: 'flor-floransaManzara' },
        { key: 2, src: '../../flor2.jpg', altText: 'flor-park' },
        { key: 3, src: '../../flor3.jpg', altText: 'flor-vecchioPalace' },
        { key: 4, src: '../../flor4.jpg', altText: 'flor-laRepubblica' },
        { key: 5, src: '../../flor5.jpg', altText: 'flor-dellAcademia' },
        { key: 6, src: '../../flor6.jpg', altText: 'flor-uffizi1' },
        { key: 7, src: '../../flor7.jpg', altText: 'flor-uffizi2' },
        { key: 8, src: '../../flor8.jpg', altText: 'flor-vasariCorridor' },
        { key: 9, src: '../../flor9.jpg', altText: 'flor-miniato' },
        { key: 10, src: '../../flor10.jpg', altText: 'flor-michaelangeloSquare' },
        { key: 11, src: '../../flor11.jpg', altText: 'flor-rompePoggi' },
        { key: 12, src: '../../flor12.jpg', altText: 'flor-terzo' },
        { key: 13, src: '../../flor13.jpg', altText: 'flor-croce' },
        { key: 14, src: '../../flor14.jpg', altText: 'flor-florencDuomo' },
        { key: 15, src: '../../flor15.jpg', altText: 'flor-gatesOfHaven' },
        { key: 16, src: '../../flor16.jpg', altText: 'flor-pizza' },
        { key: 17, src: '../../flor17.jpg', altText: 'flor-conadMarket' },
        { key: 18, src: '../../flor18.jpg', altText: 'flor-cappuccino' },
      ];

  return (
    <div className="destination-container">
      <Helmet>
        <title>Floransa, İtalya - Hangiyol Seyahat Rehberi</title>
        <meta name="description" content="Pisa Gezi Rehberi: Pisa'nın görülmeye değer yerleri ve tüyolar. Ulaşım, konaklama, yeme-içme ve daha fazlası." />
      </Helmet>
      <h2 className="aaa">İTALYA-FLORANSA GEZİ REHBERİ</h2>
      <br/>
      <div className="destination-content">
      <div className='content-image-container'>
          <div className="content-image-landscape"
              style={{ backgroundImage: `url(${imageData[0].src})` }}
              aria-label={imageData[0].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="bbb">
            Dünya üzerinde Floransa’da gördükleriniz kadar sizi tatmin edecek bir yer daha var mıdır bilemiyorum gerçekten. Gezinizin başından sonuna kadar ağzınız açık gezeceğinizden şüpheniz olmasın pek tabii sanata da birazcık ilginiz varsa. İtalya’da en sevdiğin yer neresi diye sorsanız açık ara farkla ne Venedik ne Roma kesinlikle Floransa derdim. Bomboş tüm sokaklarına dahi aşık olabilirsiniz. Şehre adım attığınız andan itibaren büyülü bir masalda gibi geziyorsunuz. Bizde bıraktığı etki buydu en azından 🙂 Bizim buraya ayırdığımız süre iki yarım  ve bir tam gündü. Bu süreye neler sığdırdık bir bakalım.   
        </p>
        <h3 className="ccc">Pisa Centrale’den, Florence Santa Maria Novella (SMN) Tren</h3>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[1].src})` }}
              aria-label={imageData[1].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="ddd">
            Station’a 1 saatte trenle saat 15:30 gibi ulaştık. Merkezde bir ev ayarladığımız için tren istasyonundan yürüyerek kalacağımız yere gittik. Hava yağmurlu olmasına rağmen eşyalarımızı odaya bırakıp hazır olan sandviçlerimizi de alıp hem dinlenip hem de pikniklemeye yakında gezi rotamızda da bulunan Giardino D’Azeglio Firenze parkına gittik. Seyahate çıktığınızda bu gibi durumlara hazırlıklı olup, beklenmedik hava koşullarını da avantaja çevirebilmek önemli. Parkta dolaştıktan sonra Mercato Centrale’ye yemek pazarına gittik. Burada her türlü taze meyve, sebze, balık ve hazır yemekler bulabilirsiniz. Şimdilik tok olduğumuz için gezmekle yetiniyoruz.  
        </p>
        <br/>
        <h3 className="eee">Duomo Meydanı</h3>
        <p className="fff">
            Santa Maria Katedrali’nin olduğu Duomo Meydanı’na geldik.Böyle bir meydan enerjisi yok. Aman allahım her yer sokak sanatçılarıyla dolu. Bir yanda müzik yapanlar bir yanda resim. Signoria Meydanı’ndan devam ettik. Bu geceyi biraz daha yorulana kadar gezip güzel müzikler dinleyerek bitirdik. Akşam meydan turlaması yapmak daha da keyifli bizden söylemesi🙂
        </p>
        <br/>
        <h2 className="ggg">2. Gün</h2>
        <p className="hhh">
            Evde ya da seyahatte farketmeksizin her zaman erkenci olduğumuz için yine sabah 7 buçuk da evimizden çıktık ve Duomo Meydanı’na geldik. Akşam ki kalabalıktan eser yok. Her yer bomboş eğer siz de fotoğraflarınızda kalabalık yapan insanları görmek istemiyorsanız yoğun olan turistik bölgelere sabah erken saatlerde gidebilirsiniz. Signorio Meydanı’na geçtik ve bu meydanda da zıpırca gezip; ‘ya millet neler yapmış nasıl korumuş bunları diye’ nutuklarımızı da attıktan sonra yine aynı meydanda bulunan Palazzo Vecchio’ya girdik. Kapıda bizi bir Davut heykeli karşılıyor içeri giriyoruz. Bahçeyi ücretsiz içeriyi ücretli bir şekilde gezebilirsiniz. Eğer vaktiniz varsa değerlendirip hem gezip hem de gözlem kulesine çıkabilirsiniz fakat biz şansımızı birazdan anlatacağımız başka iki müzeden yana kullandık. 
        </p>
        <p className="iii">
            *Palazzo Vecchio giriş ücreti 2023 itibariyle 10 Euro.
        </p>
        <br/>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[2].src})` }}
              aria-label={imageData[2].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="jjj">
           Meydanda bulunan diğer dikkat çeken sanat eserlerinden biri de Neptün Çeşmesi; Deniz Tanrısı Neptün'ün heykeli, mermer atlar ve etrafında deniz kızları ve erkek deniz tanrıları var. Kendisinden sonra yapılan bir çok çeşmeye de ilham kaynağı olmuş.  
        </p>
        <br/>
        <p className="kkk">
            Sırada Palazzo Vecchio’onun yanında bulunan Loggia dei Lanzi var. Açık hava heykel galerisi olarak inşa edilip hala aynı amaç için kullanılmaktadır. Gittiğimiz saat erken olduğu için kimsecikler yokken rahatça gezip çıktık. 
        </p>
        <p className="kkkkkkkkk">
            *Ücretsiz bir şekilde ziyaret edebilirsiniz.
        </p>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[3].src})` }}
              aria-label={imageData[3].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="lll">
            Sonrasında ise Piazza Della Repubblica meydanına varıyoruz. Sanat tarihi açısından çok önemli bir meydan ve meydanın ortasında sizi çocukluğunuza götüren tarihi bir atlıkarınca var. Meydana akşam gelirseniz hem atlıkarıncanın rengarenk ışıklarını görebilir hem de bu meydanda ki sokak sanatçılarının yaptığı müziğin keyfine varabilirsiniz.  
        </p>
        <br/>
        <h3 className="mmm">Galleria dell’Accademia</h3>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[4].src})` }}
              aria-label={imageData[4].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="nnn">
            Evet bugün asıl planımız olan Galleria dell’Accademia’ya geldik. Giriş saatimiz 9 buçuktaydı. Rehberli tur satın aldığımız için biraz gelecek insanları bekleyip saatinde galeriye girdik. Burada dünyaca ünlü Michelangelo’nun Davud heykelini, Giambologna’nın “Sabine Kadınına Tecavüz” heykelini(fakat buradaki kopyası, orjinali sabah gitmiş olduğumuz Loggia dei Lanzi’de), Michelangelo’nun tamamlanmamış eserlerini, Floransa Gotik Resimlerini ve daha fazlasını görebilirsiniz.
        </p>
        <p className="nnnnnnnnn">
            *Giriş ücreti 2023 itibariyle resmi sitesinde tam bilet 17 Euro, 18 yaş altı ücretsiz. 
        </p>
        <p className="nnnnnnnnnnnn">
            **Biletinizi seyahatinizi belirlediğiniz gibi online almalısınız çünkü aylar öncesinden biletler tur şirketleri tarafından toplu satın alınıp tükeniyor. Yoksa bizim gibi tur şirketinden kişi başı 35 Euro’ya giriş bileti aldığınızı zannedip sonrasında hayır o tur ücreti sabah nakit olarak da bilet için 16 Euro ödemeniz gerekiyor denilmesiyle kişi başı 51 Euro vermek zorunda kalıp far görmüş tavşan gibi gözlerinizi belertip ya da sokaklara çıkıp bağırarak koşabilirsiniz çünkü 1 Euro’nun 30 TL olması bunu gerektirir😀 tabi Davud’u görmek isterseniz. Neyse sonuç olarak müzeden mutlu ayrıldık 😀
        </p>
        <br/>
        <h3 className="ooo">La Galleria Degli Uffizi</h3>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[5].src})` }}
              aria-label={imageData[5].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="ppp">  
            Hemen ardından La Galleria Degli Uffizi’ye gittik. Anlatamayacak kadar çok şey  görmüş olmamızın yanı sıra her şeyin bu kadar olağanüstü güzel olması ağzımızı açık bıraktı. Bu müze Dünya’nın en iyi İtalyan Rönesansı resim koleksiyonuna ev sahipliği yapıyor. Botticelli’nin Spring tablosundan The Birth Of Venus tablolarına kadar dahası Medusa, Urbino Venüs’ü, Doni Tondo ve bir  çok heykel de görebileceğiniz önemli eserler arasında.
        </p>
        <p className="ppppppppp">
            *2023 itibariyle giriş ücreti 26 Euro.
        </p>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[6].src})` }}
              aria-label={imageData[6].altText}
              loading="lazy"
          ></div>
        </div>
        <br/>
        <h3 className="sss">Vasari Koridoru</h3>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[7].src})` }}
              aria-label={imageData[7].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="ttt">
            La Galleria Degli Uffizi’den görünen Vasari Koridoru Palazzo Pitti ile Palazzo Vecchio’yu birbirine bağlar. Zamanında bu koridor Medici Dükleri’nin halka karışmadan saraylar arası geçişini sağlıyormuş.
        </p>
        <p className='rrr'>          
            *2016 yılında ziyarete kapatılan koridor 2023 itibariyle tekrar ziyarete açılması planlanıyormuş fakat biz gittiğimizde hala kapalıydı. Yakın tarihlerde gidip açık olduğunu gören bizi de bilgilendirirse burayı güncelleyelim 🙂
        </p>
        <br/>
        <h3 className="uuu">Basilica di San Miniato</h3>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[8].src})` }}
              aria-label={imageData[8].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="vvv">
            Michelangelo tepesine giderken rotamızı bu kiliseye konumlandırıp devam ettik. Öncelikle çok güzel bir manzarası var merdivenlere oturup dinlenebilirsiniz. Kilisenin içinde İsa ve 12 havarisinin de heykelleri bulunuyor. İnanılmaz bir işçilik hakim mutlaka gidip görün kilisenin mezarlığına da uğrayabilirsiniz. Mezarlık kilisenin bahçesinde.
        </p>
        <p className='vvvvvvvvv'>
            *Kiliseye giriş ücretsiz.
        </p>
        <br/>
        <h3 className="xxxuuu">Michelangelo Tepesi</h3>
        <div className='content-image-container'>
          <div className="content-image-landscape"
              style={{ backgroundImage: `url(${imageData[9].src})` }}
              aria-label={imageData[9].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="xxxvvv">
          Biz tepeye çıkarken hala yağmur yağıyordu ama geçtiğimiz sokaklar, inip çıktığımız yokuşlar öyle güzel öyle huzurluydu ki çıktığım en güzel yokuşlardandı. Michelangelo Tepesi’ne vardığımızda bizi aşık olunası bir Floransa manzarası karşıladı. Saatlerce o yağmurda orada oturup manzarayı izledik. Eğer yanınızda atıştırmalık getirmediyseniz yiyecek ve içeçek satılan tezgahlar mevcut.
        </p>
        <br/>
        <h3 className="xxxaaa">Sistema Delle Rampe Del Poggi</h3>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[10].src})` }}
              aria-label={imageData[10].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="xxxaaaxxx">
            Michelangelo tepesinden nehir kenarına indiğimizde bu 5 mağaralı çeşmeyle karşılaştık. Neo-Maniyerizm örneklerinden biri olan bu çeşme Giuseppe Poggi tarafından yapılmış. Karşısında köpük balon gösterisi yapan amca onu çektiğimi zannedip para istedi mecbur verdik :D Ama benim balonlarımı ortada zıvanadan çıkmış gibi koşup duran başka bir çocuk patlattı 🙁
        </p>
        <br/>
        <h3 className="xxxbbbxxx">Terzo Giordino</h3>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[11].src})` }}
              aria-label={imageData[11].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="xxxbbbwww">
            Merdivenlerden Arno Nehri’ne doğru indik ve Terzo Giordino’nun yanına çıktık. Akşam güneşi bahçeye öyle güzel vuruyordu ki köprü üstünde bu güzelliğin karşısında bir ağlama molası verdik lujklişj 😀 Bahçede nehir kenarında yürüyüş yapabilir ya da Floransa’da yaşayıp sabah akşam köpeğinizi burada gezdirme hayalini ağlayarak kurabilirsiniz 😀
        </p>
        <br/>
        <h3 className="xxxcccwww">National Central Library</h3>
        <p className="xxxcccttt">
            İtalya’nın en büyük kütüphanesi Avrupa’nın da en büyük kütüphanelerinden biri olan bu kütüphane 1747’de açılmış. 1966’da Arno Nehri’nde meydana gelen sel sebebiyle kütüphane varlıklarının yaklaşık üçte biri zarar görmüş. 
        </p>
        <p className='xxxccccccyyy'>
            *Araştırmalarımdan öğrendiğim kadarıyla turistler sadece cumartesi günü rezervasyon yaptırıp özel bir turla ücretsiz gezebiliyormuş ne kadar doğru bilmiyorum. Kütüphanenin içini ziyaret etmeyi planlıyorsanız resmi sitesinde bulunan mail adresine mail atıp lütfen gitmeden önce teyit ediniz.
        </p>
        <br/>
        <h3 className="xxxdddyyy">Santa Croce Bazilikası</h3>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[12].src})` }}
              aria-label={imageData[12].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="xxxdddduuu">
            Floransa’da Francizkan mezhebinin en önemli kilisesidir. Michelangelo, Galileo, Rossini, Raffaello ve birçok ünlü italyan sanatçılarının cenaze anıtları da buradadır. Mutlaka gidip görülmeli. Kapanış saati 17:00 olduğu için erken gitmelisiniz. 
        </p>
        <p className='xxxddddddaa'>
            *Giriş ücreti 2023 itibariyle 8 Euro.
        </p>
        <br/>
        <p className='xxxddddddddda'>
            Marketlerde gördüğümüz çeşit çeşit taze makarnaları denemek için önce market alışverişi yapıp eve geçtik. Akşam yemeğimizi hazırladık ve bizi çok uzun bir sohbete tutan çinli arkadaşla akşam yemeğimizi paylaşıp sohbet edip bugünü bitirmiş olduk. Yarın son günümüz.
        </p>
        <br/>
        <h2 className="xxxeee">3. Gün</h2>
        <p className="xxxeeeeee">
            Sabah erkenden çantalarımızla kaldığımız evden ayrılıp Duomo Meydanı’na gelip Brunelleschi’s Dome manzaralı tatlı bir kafede kahvaltı yaptık. 
        </p>
        <br/>
        <h3 className="xxxhhh">Duomo Katedrali</h3>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[13].src})` }}
              aria-label={imageData[13].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="xxxiii">
            İtalyan Rönesansı’nın en önemli örneklerinden biri olan bu katedral Santa Maria del Fiore olarak da bilinir. Duomo Katedrali’ne girebilmek için daha kargalar kahvaltısını yapmadan gelip sıra olmuş insanların arkasında saat 9:30 gibi sıraya giriyoruz. Oysa biz de erken geldiğimizi zannetmiştik, yanılmışız lasşdsd 😀 11:00’da içeri girebildik. Sorun değil bugünü tren saatimize kadar kahvaltı ve burası için ayırmıştık zaten. Tabii ki sıra bize geldiğinde sırt çantalarımızla giremeyeceğimiz için birbirimizi bekleyerek sırayla girdik. Bavul ya da sırt çantasıyla girişler yasak. Emanet dolabı da yok.
        </p>
        <p className='xxxjjj'>
            *2023 itibariyle katedrale giriş ücretsiz fakat bununla birlikte  Dome, Bell Tower, Baptistery, Museum ve Santa Reparata’yı da görmek isterseniz Brunelleschi Pass diye geçen bileti 30 Euro’ya satın alabilirsiniz.
        </p>
        <br/>
        <h3 className="xxxlll">The Gates of Paradise</h3>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[14].src})` }}
              aria-label={imageData[14].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="xxxmmm">
            Katedralden çıkınca Michelangelo’nun Cennetin Kapıları olarak isimlendirdiği kapılara gittik. İncilde ki cennet sahnesini tasvir ettiği için bu isim verilmiş. Meydanda bulunan kapılar kopyası orjinali ise Floransa Ulusal müzesinde sergileniyor.
        </p>
        <br/>
        <p className='xxxnnn'>
            Buradan sonra Venedik’e yola çıkmak üzere Tren istasyonuna gittik ve kalbimizin bir parçasını Floransa,’da bırakarak seyahatimizi bitirmiş olduk.
        </p>
        <br/>
        <h2 className="xxxlllppp">Yiyecekler Hakkında</h2>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[15].src})` }}
              aria-label={imageData[15].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="xxxmmmpppo">
            Bize göre herhangi bir yerden alacağınız pizza sizi hayal kırıklığına uğratmayacaktır. Genellikle margaritha pizzalar 8 euro civarına satılıyor (mekana oturunca istenen kuver ücreti hariç) ve bir kişi için doyurucu oluyor. Ayrıca All’Antico Vinaio adında bir sandwich zinciri de mevcut ve oldukça popüler. Biz sıra beklemeyi çok sevmedğimiz için burayı tercih etmedik. Ama fırsatınız olursa insanların uğruna onlarca dakika sıra beklediği bu yere de uğranabilir. 
        </p>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[16].src})` }}
              aria-label={imageData[16].altText}
              loading="lazy"
          ></div>
        </div>
        <p className='xxxnnnpppppo'>
            Ekonomik seçenekleri değerlendirmek isteyenler için en iyi tercih tabii ki de yiyecekleri marketten almak. Genel olarak şehrin her yerinde uygun fiyatlarıyla ön plana çıkan Conad market zinciri ve fiyat olarak bir tık daha pahalı olsa da Carrefour tercih edilebilir. Conad markette özellikle 1.4 euro fiyatla satılan ve bizdeki mantıya benzeyen tortellini makarnalar iki kişi için doyurucu bir seçenek olacaktır. Biz bir de İtalya'da yaygın olarak satılan ve trüf mantarından yapılan tartufo sosu (1.5 euro civarı) çok beğendik. Makarnaların üzerine dökünce ekstra bir lezzet kattı. Ayrıca yaptığımız sandwichlerde de bolca, hiç sıkılmadan kullandık. Elimizde olsa bir kasa alıp Türkiye'ye bile getirebilirdik, o derece yani. Ayrıca Conad peynir seçimi için de iyi bir tercih oldu. Türkiye'de kilosu bin liraya varmasına rağmen burada kilosunu 7 euro yani günün kuruyla 200 lira gibi uygun fiyatlara bulabiliyorsunuz. Ayrıca sandwichlerimiz için en iyi ekmekleri Carrefour'da bulduk hep (genel olarak 0.50 euro), diğer yerlerdeki ekmekler ya aşırı pahalıydı ya da sandwich yapmaya uygun değildi. 
        </p>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[17].src})` }}
              aria-label={imageData[17].altText}
              loading="lazy"
          ></div>
        </div>
        <p className='xxxnnnpppppoxxxx'>
            İtalyanlar cappucciono'yu yalnızca kahvaltıda tercih ediyorlar. Floransa'da kruvasan, cappucciono ve portakal suyundan oluşan klasik bir kahvaltı menüsünün fiyatı genel olarak kişi başı 7 euro civarında. Buraya gelip güzel bir meydanda kruvasanınızı cappuccino'nuza bandırarak yemeden gitmek olmaz. Kesinlikle tavsiye ederiz.
        </p>
        <br/>
        <h2 className="xxxooopxpx">Google Maps için Yürüyüş Rotaları</h2>
        <p className="wwwpxpx">
            Yürüyüş rotası 1: Galleria dell’Accademia, Floransa Katedrali, Piazza della Repubblica, Loggia dei Lanzi, Vecchio Sarayı, Signoria Meydanı, Neptün çeşmesi, Uffizi müzesi, Vasari Corridor. 
        </p>
        <ul>
          <li className='map-links'>
            <a href="https://maps.app.goo.gl/GS5i1WSvzuT59gzG8" target="_blank" rel="noopener noreferrer">Rota 1 için link</a>
          </li>
        </ul>
        <p className="xxxpxpx">
            Yürüyüş rotası 2: Ponte Vecchio, San Miniato Al Monte, Michelangelo Meydanı, Sistema delle Rampe del Poggi, Terzo Giardino, National Central Library, Santa Croce Bazilikası.
        </p>
        <ul>
          <li className='map-links'>
            <a href="https://maps.app.goo.gl/Jy5FSxLHn7t1hQj59" target="_blank" rel="noopener noreferrer">Rota 2 için link</a>
          </li>
        </ul>
        <h2 className="xxxooo">Özet</h2>
        <p className="www">
            Floransa hala yaşayan rönesans. Gerek mimarisi gerekse kültürüyle sizi baştan aşağıya büyüleyen bir yer. Giriş biletlerinizi online olarak mutlaka alın. Tekrar ne zaman gideriz hiçbir fikrimiz yok ama tekrar ziyaret edeceğimiz şehirlerden.
        </p>
        <p className="xxx">
        Sevgiyle kalın!
        </p>
      </div>
      <br />
      <br />
      <DestinationSelector /> 
      <hr className="separator" /> 
      <CommentsSection componentName="destination4" /> 
    </div>
  );
};

export default Destination4;
