import React from 'react';
import './NotFoundPage.css';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/CountryPage');
  };

  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <button onClick={handleButtonClick}>Böyle bir sayfamız bulunmamaktadır!</button>
      </div>
    </div>
  );
};

export default NotFoundPage;
