import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './sideComponents.css';

const SearchResultsPage = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Add error state
  const location = useLocation();
  const query = location.state.searchQuery;
  const navigate = useNavigate(); // Add the useNavigate hook

  // Function to fetch search results based on the query
  const fetchSearchResults = async (query) => {
    try {
      const response = await fetch(`/api/search?q=${encodeURIComponent(query)}`);
      const data = await response.json();
  
      // Filter the texts array for each search result based on matches in content
      const resultsWithMatches = data.map((result) => {
        const matches = result.texts.filter((text) => {
          const regex = new RegExp(`(${query})`, 'gi');
          return regex.test(text.content);
        });
  
          return { ...result, texts: matches };
        });
    
            setSearchResults(resultsWithMatches);
          } catch (error) {
            console.error('Error fetching search results:', error);
          }
        };

  // Function to handle navigation when the user clicks on a result
  const handleResultClick = (componentName, textIdentifier) => {
    const transformedPath = transformComponentNameToPath(componentName);
    navigate(transformedPath, { state: { textIdentifier } });
  };

  // Function to transform component name to path
  const transformComponentNameToPath = (componentName) => {
    const [prefix, suffix] = componentName.split(/(\d+)/).filter(Boolean);
    return `/${prefix}/${suffix}`;
  };

  // Fetch search results whenever the query changes
  useEffect(() => {
    fetchSearchResults(query);
  }, [query]);

  useEffect(() => {
    setIsLoading(true); // Set loading state
    fetchSearchResults(query)
      .finally(() => setIsLoading(false)); // Clear loading state
  }, [query]);

  useEffect(() => {
    setIsLoading(true);
    setError(null); // Clear previous errors
    fetchSearchResults(query)
      .catch((err) => setError(err)) // Set error state
      .finally(() => setIsLoading(false));
  }, [query]);


  return (
    <div className="sideComponents-container">
      <h2 className="sideComponents-title">Arama Sonuçları: {query}</h2>
      <div>
        {isLoading ? (
          <p className="loading-message">Aranıyor...</p>
        ) : error ? (
          <p className="error-message">Bir hata oluştu: {error.message}</p>
        ) : searchResults.length > 0 ? (
          <div>
            {searchResults.map((result) => (
              <div className="search-results-container" key={result._id}>
                {result.texts.map((text, index) => (
                  <p
                    className="search-results-container"
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: text.content.replace(
                        new RegExp(`(${query})`, 'gi'),
                        '<span class="highlight">$1</span>'
                      ),
                    }}
                    onClick={() => handleResultClick(result.component, text.textIdentifier)}
                  />
                ))}
              </div>
            ))}
          </div>
        ) : (
          <p className="no-results-message">Sonuç bulunamadı.</p>
        )}
      </div>
    </div>
  );
};

export default SearchResultsPage;
