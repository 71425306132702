import React from 'react';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../../components/Destination.css';
import CommentsSection from '../../components/CommentsSection';
import DestinationSelector from '../../components/DestinationSelector';

const Destination8 = () => {
    const scrollToAttraction = (attractionId) => {
        const elementToScroll = document.getElementById(attractionId);
        if (elementToScroll) {
            elementToScroll.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const location = useLocation();
    const paragraphMarker = location.state?.textIdentifier || '';
    console.log(paragraphMarker);

    useEffect(() => {
        if (paragraphMarker) {
            const elementToHighlight = document.querySelector(`.${paragraphMarker}`);
            if (elementToHighlight) {
                elementToHighlight.classList.add('highlight');
                elementToHighlight.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [paragraphMarker]);

    const attractions = [
        { name: "Four Fountains", id: "four-fountains" },
        { name: "Galleria Nazionale D’Arte Antica", id: "galleria-nazionale" },
        { name: "Fontana Del Tritone (Tritone Çeşmesi)", id: "fontana-deltritone" },
        { name: "Trinita Dei Monti Kilisesi ve Sallustiano Obelisk", id: "sallustiano-obelisk" },
        { name: "İspanyol Merdivenleri", id: "ispanyol-merdivenleri" },
        { name: "Fontana Della Barcaccia", id: "fontana-della" },
        { name: "Santa Maria di Montesanto", id: "santa-maria" },
        { name: "Porta Del Popolo", id: "porta-del" },
        { name: "Chiesa Sacro Cuore del Suffragio (Katolik Kilise)", id: "chiesa-sacrocuore" },
        { name: "Corte Suprema Di Cassazione", id: "corte-suprema" },
        { name: "Ponte Sant’Angelo", id: "ponte-santangelo" },
        { name: "Castel Sant’Angelo", id: "castel-santangelo" },
        { name: "Piazzo Navona (Navona Meydanı)", id: "piazzo-navona" },
        { name: "Pantheon", id: "pantheon" },
        { name: "Macuteo Obelisk (Macuteo Dikilitaş)", id: "macuteo-obelisk" },
        { name: "Chiesa di Sant'Ignazio di Loyola (Katolik Kilisesi)", id: "chiesa-di-santignazio" },
        { name: "Trevi Çeşmesi", id: "trevi-fountain" },
        { name: "Colosseum (Kolezyum)", id: "colosseum" },
        { name: "II. Vittorio Emanuele Anıtı", id: "ii-vittorio" },
        { name: "Marcus Aurelius Sütunu", id: "marcus-aurelius" },
        { name: "Vatican", id: "vatican" },
        { name: "Fontana dei Dioscuri (Dioscuri Çeşmesi)", id: "fontana-dei" },
        { name: "Basilica di Santa Maria Maggiore (Santa Maria Maggiore Bazilikası)", id: "basilica-di-santamaria" },
        { name: "Galleria Sciarra", id: "galleria-sciarra" },
        { name: "Basilica di Sant’Andrea della Valle (Sant’Andrea della Valle Bazilikası)", id: "basilica-di-santandrea" },
        { name: "Cordonata Capitolina (Capitalina Kordonu)", id: "cordonata-capitolina" },
        { name: "Antik Roma Forumu", id: "antik-roma-forumu" },
        { name: "Palatine Hill (Palatino Tepesi)", id: "palatine-hill-palatino-tepesi-" },
        { name: "Roma’da Nerede Ne Yenir?", id: "yemek" },
    ];

    return (
        <div className="destination-container">
            <Helmet>
                <title>Roma, İtalya Gezi Rehberi - Hangiyol Seyahat Rehberi</title>
                <meta name="description" content="" />
            </Helmet>

            <h2 className="aaaaaa">Roma, İtalya</h2>

            <br />

            <div className="destination-content">

                <p className="jklccc">Herkese merhaba :) Konu başlıklarımızı aşağıdaki listede özetledik:</p>
                <div>
                    <ul>
                        {attractions.map((attraction) => (
                            <li key={attraction.name}>
                                <button
                                    onClick={() => scrollToAttraction(attraction.id)}
                                    style={{
                                        border: 'none',
                                        cursor: 'pointer',
                                        borderRadius: '0.35rem',
                                    }}
                                >
                                    {attraction.name}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>

                <p className="def">İtalya’nın başkentine gitmeye hazırsanız bilmeniz gerekenler de bu yazıda.</p>
                <p className="ghi">Bari’den Roma’ya ulaşımımız otobüsle oldu. Bari - Roma otobüs biletlerimizi önceden Flixbus’ın internet sitesinden alıp, biniş kartlarımızı telefonumuza internetimiz varken indirdik ve ne olur ne olmaz diye seyahatimiz boyunca almış olduğumuz tüm uçak, otobüs, tren biletlerimizi ve otel rezervasyonlarımızın belgelerini buraya gelmeden önce çıktı aldık.</p>
                <p className="jkl">Saat 23:30 civarı Roma Tiburtina Otobüs Terminali’ne geldik ve Termini bölgesinde yürüme mesafesinde olan otelimize vardık. Bu oteli tercih etmemizin sebebi giriş saatinin sorun olmamasıydı.</p>
                <p className="mno">Not: Otel ücretine ek olarak 3 günlük şehir konaklama vergisi iki kişi 1 oda için 15 Euro ödedik. Genel olarak İtalya’da konaklayacağınız yerlerde otel ücreti dışında vergi ödeyip ödemeyeceğinizi mail atarak öğrenebilirsiniz. Bazı AirBnb’ler çöp vergisi de isteyebiliyor. Kaldığınız yerin insiyatifine bağlı.</p>
                <p className="pqr">Bu arada Roma’da bolca vaktimiz olduğundan ve bizim için Roma’nın her taşı görülesi olduğundan hiç toplu taşıma kullanmadık. Neredeyse her yer hep yürüme mesafesindeydi bu yüzden de zorlanmadık. Eğer 3 gün gibi uzun bir vaktiniz varsa önerimiz yürüyerek gezmenizdir. Yoksa bazı yerlere giriş imkanı veren ve ulaşımın da dahil olduğu Roma Pass edinebilirsiniz.</p>
                <h3 className="stu">Haydi şimdi gezmeye başlayalımm 🙂</h3>
                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome1.jpg)` }}
                        aria-label="Haydi şimdi gezmeye başlayalımm 🙂"
                        loading="lazy"
                    ></div>
                </div>

                <h3 className="vwx">3 Gün Roma’da Gezilecek Yerler</h3>

                <h3 className="bcd" id="four-fountains" >Four Fountains</h3>

                <p className="yza" >Sabah erkenden kalkıp Four Fountains ile gezimize başlıyoruz.</p>
                
                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome2.jpg)` }}
                        aria-label="Four Fountains"
                        loading="lazy"
                    ></div>
                </div>

                <p className="efg">Dört Çeşme, Via delle Quattro Fontane ile Via del Quirinale'nin kesiştiği noktada bulunan ve bu kavşakta Via 20 Settembre'ye dönüşen dört geç rönesans çeşmesidir. Kavşağın her köşesinde, o köşedeki binaya karşı birer çeşme bulunmaktadır. Kavşak, adını dört köşesini süsleyen çeşmelerden almıştır ve Quirinal Tepesi'nin en yüksek noktasıdır. Antik çağlarda tapınakların, asilzade konutlarının ve ayrıntılı Konstantin Hamamlarının bulunduğu yerdir.  Arno, Tiber ve Juno çeşmeleri Domenico Fontana'nın eseridir. Diana çeşmesi, ressam ve mimar Pietro da Cortona tarafından tasarlanmıştır.</p>
                
                <h3 className="hij" id="galleria-nazionale">Galleria Nazionale D’Arte Antica</h3>
                
                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome3.jpg)` }}
                        aria-label="Galleria Nazionale D’Arte Antica"
                        loading="lazy"
                    ></div>
                </div>
                
                <p className="klm">Roma’da bulunan antik ulusal sanat müzesidir. Girmek isterseniz biletini 
                    <a href="https://www.barberinicorsini.org/" target="_blank" rel="noopener noreferrer">buradan</a> 
                alabilirsiniz.</p>
                
                <h3 className="nop" id="fontana deltritone">Fontana Del Tritone (Tritone Çeşmesi)</h3>
                
                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome4.jpg)` }}
                        aria-label="Fontana Del Tritone (Tritone Çeşmesi)"
                        loading="lazy"
                    ></div>
                </div>

                <p className="tuv">Yürüyerek Barberini meydanına geliyoruz. Bu meydanda bulunan Triton çeşmesi Gian Lorenzo Bernini tarafından 1643 yılında tamamlanmıştır.</p>

                <h3 className="wxy" id="sallustiano-obelisk" >Trinita Dei Monti Kilisesi ve Sallustiano Obelisk</h3>
                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome5.jpg)` }}
                        aria-label="Sallustiano Obelisk (Sallustiano Dikilitaş)"
                        loading="lazy"
                    ></div>
                </div>
                <p className="zab">Roma’da bulunan birçok dikilitaştan biri olan Sallustiano Dikilitaş’ı İspanyol Merdivenlerinin hemen üzerinde bulunur ve kırmızı granitten yapılmıştır.</p>
                <p className="fgh">Rönesans dönemi ünvansal Roma Katolik kilisesi olan Trinita Dei Monti, Sallustiano Dikilitaş’la aynı yerdedir. İspanyol merdivenlerine gittiğinizde bu kiliseyi de ziyaret edebilirsiniz.</p>
                <p className="ijk">Giriş ücretsizdir.</p>

                <h3 className="lmn" id="ispanyol-merdivenleri">İspanyol Merdivenleri</h3>

                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome6.jpg)` }}
                        aria-label="İspanyol Merdivenleri"
                        loading="lazy"
                    ></div>
                </div>

                <p className="opq">135 basamaktan oluşan bu merdivenler Avrupa’nın en ünlü merdivenlerinden biridir. Zamanında bu meydanda İspanyol Elçiliği olduğundan merdivenlerin adı da İspanyol merdivenleri olarak kalmış. İspanyol merdivenlerini görmeden Roma’dan ayrılmak olmaz. Fakat basamaklarda oturmak, bir şeyler yemek içmek yasak ve 160-400 Euro arası bir cezası var.</p>
                
                <h3 className="rst" id="fontana-della">Fontana Della Barcaccia</h3>
                
                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome7.jpg)` }}
                        aria-label="Fontana Della Barcaccia"
                        loading="lazy"
                    ></div>
                </div>
                <p className="uvw">İspanyol merdivenlerinin altında bulunan barok tarzıyla yapılmış bu tatlı su çeşmesinde mataralarınızı doldurup, su içebilirsiniz.</p>
                
                <h3 className="xyz" id="santa-maria" >Santa Maria di Montesanto</h3>
                
                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome8.jpg)` }}
                        aria-label="Santa Maria di Montesanto"
                        loading="lazy"
                    ></div>
                </div>
                <p className="abc">Sanatçılar kilisesi olarak bilinen  Santa Maria di Montesanto kilisesi aynı zamanda Santa Maria dei Miracoli kilisesinin ikizidir ve Piazza Del Popolo meydanında bulunurlar.</p>
                
                <h3 className="def" id="porta-del">Porta Del Popolo</h3>
                
                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome9.jpg)` }}
                        aria-label="Porta Del Popolo"
                        loading="lazy"
                    ></div>
                </div>
                <p className="ghi">Piazza Del Popolo meydanında bulunan bir diğer önemli noktalardan biri de Porta Del Popolo’dur. (Popolo Kapısı). Başlangıçta Porta Flaminia olarak biliniyormuş fakat sonrasında Roma’nın kuzeyinden şehire geçiş kapısı olduğu için halkın kapısı yanı Porta Del Popolo olarak anılmaya başlanmış.</p>
                
                <h3 className="jkl" id="chiesa-sacrocuore">Chiesa Sacro Cuore del Suffragio (Katolik Kilise)</h3>
                
                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome10.jpg)` }}
                        aria-label="Chiesa Sacro Cuore del Suffragio"
                        loading="lazy"
                    ></div>
                </div>
                <p className="mno">Milano’nun küçük katedrali olarak adlandırılan bu kilise Prati nehri kıyısında binaların arasına sıkışıp kalmış olmasına rağmen Neo-Gotik tarzıyla dikkatleri üzerine çekiyor. Gitmenize değecek bir diğer ilginç özelliği ise içinde dünyada huzursuz ruhların ve arafın varlığına dair kanıtların toplandığı bir müze var. Giriş ücretsiz.</p>
                
                <h3 className="pqr" id="corte-suprema">Corte Suprema Di Cassazione</h3>
                
                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome11.jpg)` }}
                        aria-label="Corte Suprema Di Cassazione"
                        loading="lazy"
                    ></div>
                </div>

                <p className="stu">Roma bizim için tam bir açık hava müzesi. Bu yargıtay binası da burada göreceğiniz eşsiz yapılardan. İtalyanlar ise bu binayı Palazzaccio olarak adlandırıyorlar.</p>
                
                <h3 className="vwx" id="ponte-santangelo">Ponte Sant’Angelo</h3>
                
                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome12.jpg)` }}
                        aria-label="Ponte Sant’Angelo"
                        loading="lazy"
                    ></div>
                </div>
                <p className="yza">Aelian Köprüsü veya Pons Aelius olarak da bilinen bu köprü Roma İmparatoru Hadrian tarafından yapılmıştır. Traverten mermeriyle kaplıdır ve köprü üzerinde birçok melek heykeli bulunur. Sant’Angelo kalesine giderken bu köprüyü kullanabilirsiniz. Köprü çok kalabalık olduğundan hırsızlığa karşı dikkatli olmak gerek.</p>
                
                <h3 className="bcd" id="castel-santangelo">Castel Sant’Angelo</h3>
                
                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome13.jpg)` }}
                        aria-label="Castel Sant’Angelo"
                        loading="lazy"
                    ></div>
                </div>
                <p className="efg">Roma İmparatoru Hadrian’ın kendisi ve ailesi için yaptırmış olduğu mozole, daha sonrasında papalar tarafından kale ve şato olarak kullanılmış. Günümüzde müze olarak kullanılan bu yer bir zamanlar Roma’nın en yüksek binasıymış.</p>
                <p className="hij">Eğer kalenin içini gezmeyi planlıyorsanız giriş biletinizi online olarak seyahatiniz başlamadan önce almanızda fayda var. Hem kapıda saatlerce bilet sırasında beklememiş olursunuz hem de biletlerin tükenmiş olma olasılığıyla karşılaşmazsınız. Biletinizi resmi sitesinden alabilirsiniz. https://www.gebart.it/musei/museo-nazionale-di-castel-santangelo/?utm_source=magpietravelttd</p>
               
                <h3 className="klm" id="piazzo-navona">Piazza Navona (Navona Meydanı)</h3>
               
                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome14.jpg)` }}
                        aria-label="Piazzo Navona (Navona Meydanı)"
                        loading="lazy"
                    ></div>
                </div>
                <p className="nop">Sırada son derece dinamik bir yapıya sahip Navona meydanı var. Fountain of Neptune(Neptün çeşmesi) ve Bernini’nin Rome Fontana Dei Quattro(Dört Nehir Çesmesi) ve Fontana Del Moro(Moro Çeşmesi) burada bulunur. Eğer Barok Mimariyi seviyorsanız mutlaka bu meydana uğrayın. Üstelik Pantheon’a çok yakın. Meydanda bulunan kafeler ortalamanın üzerinde fiyatlara sahip.</p>
                
                <h3 className="qrs" id="pantheon">Pantheon</h3>
                
                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome15.jpg)` }}
                        aria-label="Pantheon (Panteon)"
                        loading="lazy"
                    ></div>
                </div>

                <p className="tuv">Antik Roma'nın bütün tanrıları için tapınak olarak inşa edilen yapı günümüzde Jean-Jacques Rousseau gibi ünlü insanların  gömülü olduğu bir anıttır. Roma yapıları içinde en iyi korunmuş olanı ve muhtemelen de dünyada döneminin en iyi korunmuş binası olarak geçmektedir. 2023 yılına kadar  ücretsiz ziyaret edebileceğiniz bu tapınağın giriş ücreti artık 5 Euro. Biletinizi gitmeden önce online olarak almak sizi uzun kuyruklarda zaman kaybetmekten kurtaracaktır.</p>
                
                <h3 className="wxy" id="macuteo-obelisk">Macuteo Obelisk (Macuteo Dikilitaş)</h3>

                <p className="zab">Piazza dell’Esquilino’da Pantheon’un karşısında bulunan ve İmparator Augustus tarafından Roma'ya getirilmiş  antik mısır dikilitaşıdır.</p>
                
                <h3 className="cde" id="chiesa-di-santignazio">Chiesa di Sant'Ignazio di Loyola (Katolik Kilisesi)</h3>
                
                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome17.jpg)` }}
                        aria-label="Chiesa diSant'Ignazio diLoyola(Katolik Kilisesi)"
                        loading="lazy"
                    ></div>
                </div>
                <p className="fgh">Andrea Pozzo’nun nefis fresklerini görmek istiyorsanız bu ihtişamlı kiliseye mutlaka gitmelisiniz. Tavan düz olmasına rağmen Andrea buraya kubbeli bir görünüm vermiş. Giriş ücretsiz ve genelde sıra olmuyor. Fakat içeride tavan süslemelerini daha yakından inceleyebilmeniz için bir ayna konulmuş, işte o aynada biraz sıra olabilir 😀</p>
                
                <h3 className="ijk" id="trevi-fountain">Trevi Fountain (Trevi Çeşmesi)</h3>

                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome18.jpg)` }}
                        aria-label="Trevi Fountain (Trevi Çeşmesi)"
                        loading="lazy"
                    ></div>
                </div>
                <p className="lmn">Çeşmeleriyle ünlü olan Roma şehrinin en büyük barok çeşmesidir. Ayrıca  aşk çeşmesi olarak da anılır. Efsane ise şöyle; eğer havuza bir tane  para atarsanız tekrar Roma’ya geleceksiniz. İki tane para atarsanız da hayatınızın aşkını bulacaksınız demekmiş. Tabii birde bu dileklerin etkili olması için parayı sağ elinizle tutup sol omzunuzun üzerinden atmalısınız. Söylemesi bizden denemesi sizden. 😀 Burayı sakin görmek istiyorsanız saat 07:30’dan önce buraya gelmelisiniz. Saat 8 ya da 9 dan sonrasını hayal bile edemezsiniz hele ki öğle ve akşam saatlerinde tam bir mahşer yeri oluyor. Değil fotoğraf çekmek, çeşmeye yaklaşabilmeniz dahi mümkün değil. Bu kalabalıkta cüzdanınıza ve pasaportlarınıza daha da dikkat etmelisiniz.</p>
                
                <h3 className="opq" id="colosseum">Colosseum (Kolezyum)</h3>
                
                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome19.jpg)` }}
                        aria-label="Colosseum (Kolezyum)"
                        loading="lazy"
                    ></div>
                </div>
                <p className="rst">Flavianus olarak da bilinen bu oval amfitiyatro, bugüne kadar yapılmış en büyük antik amfitiyatrodur ve bunca yıla rağmen dünyanın ayakta kalan en büyük amfitiyatrosudur. Ortalama 65.000 izleyici kapasitesi olan bu yer tarihin  kanlı gladyatör savaşlarına, hayvan avlarına, infazlar gibi halka açık gösterilere tanıklık etmiş. Dışını ve bahçesini ücretsiz gezip görebilirsiniz fakat içini de görmek isterseniz (ki bence muhakkak görmelisiniz) biletlerinizi özellikle de yaz sezonunda gitmeyi planlıyorsanız internetten almalısınız çünkü oraya gittiğinizde neredeyse 1 ve 2 ay gibi sürelerle biletlerin tükenmiş olduğunu göreceksiniz. Online biletinizi aldınız diyelim biletinizde saat kaç yazıyorsa o saatte orada olmalısınız 10 dakika var 5 dakika kalmış alırlar demeyin almıyorlar. Giriş ücreti 18 Euro. https://www.coopculture.it/en/products/ticket-colosseum-roman-forum-palatine_24h/?utm_source=magpietravelgttd Resmi sitesinden alabilirsiniz.</p>
                <p className="uvw">Eğer öncesinde buradan almazsanız ve yine de girmek isterseniz 30-38 euro civarı ya da daha fazlasına biletleri farklı sitelerden bulabilirsiniz.</p>
                
                <h3 className="xyz" id="ii-vittorio">II. Vittorio Emanuele Anıtı</h3>
                
                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome20.jpg)` }}
                        aria-label="II. Vittorio Emanuele Anıtı"
                        loading="lazy"
                    ></div>
                </div>
                <p className="abc">Piazza Venezia ve Capitol Tepesi arasında yer alan bu abide Birleşmiş İtalya Krallığı'nın ilk kralı II. Vittorio Emanuele'yi onurlandırmak için yapılmış. Anıtı ücretsiz gezebilirsiniz fakat panoramik teras ve içindeki müze ücretli.</p>
                
                <h3 className="def" id="marcus-aurelius">Marcus Aurelius Sütunu</h3>
                
                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome21.jpg)` }}
                        aria-label="Marcus Aurelius Sütunu"
                        loading="lazy"
                    ></div>
                </div>
                <p className="ghi">Bir Roma zafer sütunu olan Marcus Aurelius Sütunu Piazza Colonna’dadır. Üzerinde Trajan’ın modellendiği görülmektedir ve sarmal kabartmaya sahip dor sütunudur. Marcus Aurelius’un onuruna inşa edilmiştir.</p>
                
                <h3 className="jkl" id="vatican">Vatican (Vatikan)</h3>
                
                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome22.jpg)` }}
                        aria-label="Vatican (Vatikan)"
                        loading="lazy"
                    ></div>
                </div>
                <p className="mno">En küçük bağımsız ülke olarak bilinen Vatikan’ın resmi adı Vatikan Şehir Devleti’dir. Devlet Başkanı Papa’dır ve sözleri yasa hükmündedir. Vatikan’ın İsviçreli Muhafızlar olarak adlandırılan, geleneksel kıyafetler giyen ve 100 kişiden oluşan sembolik bir ordusu vardır. Vatikan müzelerini de gezmek istiyorsanız sadece Vatikan’a 1 gün ayırmalısınız. Vatikan ayrıntılı gezi rehberi için buraya tıklayabilirsiniz.</p>
                <p className="pqr">Chiesa di Gesù e Maria (İsa ve Meryem Kilisesi)</p>
                <p className="stu">Via Del Corso’daysanız girip görebileceğiniz barok tarzda inşa edilmiş bir kilise.</p>
                <p className="vwx">Giriş ücretsiz.</p>

                <h3 className="yza" id="fontana-dei">Fontana dei Dioscuri (Dioscuri Çeşmesi)</h3>

                <p className="bcd">İtalya Cumhurbaşkanı’nın resmi konutu Palazzo del Quirinale’nin karşışında yer alır.</p>
                
                <h3 className="efg" id="basilica-di-santamaria">Basilica di Santa Maria Maggiore (Santa Maria Maggiore Bazilikası)</h3>
                
                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome24.jpg)` }}
                        aria-label="Basilica di Santa Maria Maggiore"
                        loading="lazy"
                    ></div>
                </div>

                <p className="hij">Her köşesi ayrı bir sanatla dolu olan bu bazilikayı mutlaka görün. Evet artık içiniz dışınız kilise ve bazilika olmuş olabilir ama bu bazilikayı mutlaka görüp içini incelemelisiniz. Roma’nın dört papalık bazilikasından biri olmasının yanında Roma'daki orijinal erken Hıristiyan yapısını koruyan tek bazilika olma özelliğindedir.</p>
                <p className="klm">Giriş ücretsiz.</p>

                <h3 className="nop" id="galleria-sciarra">Galleria Sciarra</h3>

                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome25.jpg)` }}
                        aria-label="Galleria Sciarra"
                        loading="lazy"
                    ></div>
                </div>

                <p className="qrs">Trevi bölgesinde bulunan Galleria Sciarra görülmeye değer yerlerden biridir. Fresklerle çevrili ve cam tavanlı küçük bir avludur.</p>
                <p className="tuv">Giriş ücretsiz.</p>

                <h3 className="wxy" id="basilica-di-santandrea">Basilica di Sant’Andrea della Valle (Sant’Andrea della Valle Bazilikası)</h3>

                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome26.jpg)` }}
                        aria-label="Basilica di Sant’Andrea della Valle"
                        loading="lazy"
                    ></div>
                </div>
                <p className="cde">Pucci’ninin ünlü operası Tosca’nın ilk perdesinde geçen Barberini Şapeli burada olduğu için ‘Tosca Şapeli’ olarak da bilinir. Mutlaka gezilmesi gereken yerlerden biridir. Giriş ücretsiz.</p>
                
                <h3 className="fgh" id="cordonata-capitolina">Cordonata Capitolina (Capitalina Kordonu)</h3>
                
                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome27.jpg)` }}
                        aria-label="Cordonata Capitolina (Capitalina Kordonu)"
                        loading="lazy"
                    ></div>
                </div>
                <p className="ijk">Capitol Meydanı’nın ana girişi olan Cordonata’nın eğimli merdivenlerini  Michelangelo 16.yy da tasarlamış. Heykellerle donatılmış, yolları taş döşeli bu ünlü kordon Piazza del Campidoglio’ya kadar gider.</p>
                
                <h3 className="lmn" id="antik-roma-forumu">Antik Roma Forumu</h3>
                
                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome28.jpg)` }}
                        aria-label="Antik Roma Forumu"
                        loading="lazy"
                    ></div>
                </div>
                <p className="opq">Antik kentliler  bir pazar yeri olan bu yere Forum Magnum veya kısaca Forum adını vermişler. Bizim bildiğimiz çarşı.  Kolezyum’a çok yakın olduğu için iki yeri aynı güne planlayabilirsiniz. Geniş bir alana yayılmış açık hava müzesi en az 3 saatinizi buraya ayırmalısınız. Buraya gelmişken Palatino Tepesi’ne de mutlaka çıkın.</p>
                
                <h3 className="rst" id="palatine-hill">Palatine Hill (Palatino Tepesi)</h3>
                
                <div className="content-image-container">
                    <div className="content-image-landscape"
                        style={{ backgroundImage: `url(../../rome29.jpg)` }}
                        aria-label="Palatine Hill (Palatino Tepesi)"
                        loading="lazy"
                    ></div>
                </div>
                <p className="uvw">Roma’nın yedi tepesinden en merkezde olan tepesi Palatino Tepesi’dir. Hem bahçeleri gezip hem de Roma’yı yukarıdan izleyebilirsiniz. Eğer Roma Pass’iniz yoksa Kolezyum, Forum ve Palatine Hill için kombine bilet satın alabilirsiniz.</p>
                
                <h3 className="xyz" id="yemek">Roma’da Nerede Ne Yenir?</h3>
                
                <h3 className="abc">Pastaficio</h3>

                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome30.jpg)` }}
                        aria-label="Roma’da Nerede Ne Yenir?"
                        loading="lazy"
                    ></div>
                </div>

                <p className="def">İspanyol merdivenlerine çok yakın olan bu makarnacı her gün iki çeşit makarna çıkarıyor ve genelde biri sebzeli diğeri etli oluyor.</p>
                <p className="ghi">Oturup yiyebileceğiniz masalar yok fakat içeride ayakta yiyebileceğiniz bir masa var. Ayrıca yanında dilerseniz ve orada içecekseniz şarap da ikram ediyor. Porsiyonları doyurucu ve lezzetli. Porsiyon 5 Euro.</p>
                
                <h3 className="jkl">Alice Pizza</h3>
                
                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome31.jpg)` }}
                        aria-label="Alice Pizza"
                        loading="lazy"
                    ></div>
                </div>
                <p className="mno">Sadece Roma’da olan bir pizza zinciri Alice Pizza. Tepsilerde binbir çeşit taze pizzalar oluyor ve kare dilimlerle tartılarak satılıyor. 1 dilim ortalama 4 Euro’ya geliyor. Mutlaka bu pizza zincirlerinden birine uğrayıp çeşit çeşit pizzaların tadına bakabilirsiniz.</p>
                
                <h3 className="pqr">Pompi Tiramisu</h3>
                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome32.jpg)` }}
                        aria-label="Pompi Tiramisu"
                        loading="lazy"
                    ></div>
                </div>
                <p className="stu">Biz Pastaficio’nun karşısında bulunan Pompi’den tiramisu alıp yedik. Ortalama fiyatlar 5-6 Euro civarı. Taze ve lezzetliydi. Roma’nın farklı yerlerinde şubeleri de var.</p>
                <h3 className="vwx">Forno Sant’Angelo</h3>
                <div className="content-image-container">
                    <div className="content-image-portrait"
                        style={{ backgroundImage: `url(../../rome33.jpg)` }}
                        aria-label="Forno Sant’Angelo"
                        loading="lazy"
                    ></div>
                </div>
                <p className="yza">Hem pizza hem de her yerde göreceğiniz soğuk sandviçlerden satan küçük ama çok samimi bir yer. Sant’Angelo Kalesi yakınlarında. Ben patlıcanlı sandviçlerinden Yusuf’sa etli olanlardan yedi. İkisi de son derece başarılıydı. Sonrasında fırın tatlılarından da denedik. Her zaman taze ürünlerle karşılaştık. Kesinlikle önerebileceğimiz bir yer. Ve zincir yerlere nazaran çok daha uygun 7 Euro civarı. Bu tarz sandviç satan All’Antico Vinaio isimli zincir sandviççiler de var. Burada da sandviç fiyatları 10 Eurodan başlıyor. Ayrıca içine konulacak malzemeleri damak zevkinize göre ayarlayabiliyorsunuz.</p>
                
                <h3 className="xxxooopxpx" id="rota">Google Maps için Madrid Yürüyüş Rotası</h3>


        <p className="wwwpxpxooo">
            Yürüyüş rotası 1: Four Fountains, Ancient Art in Barberini, Fontana del Tritone, Trinità dei Monti, İspanyol Merdivenleri, Fontana della Barcaccia, Santa Maria in Montesanto, Porta del Popolo
        </p>
        <ul>
          <li className='map-links'>
            <a href="https://goo.gl/maps/tw8c4Werkr7TKABn7" target="_blank" rel="noopener noreferrer">Rota 1 için link</a>
          </li>
        </ul>

        <p className="xxxpxpx">
            Yürüyüş rotası 2: Fontana dei Dioscuri, Trevi Çeşmesi, Marcus Aurelius Sütunu, Chiesa di Sant'Ignazio di Loyola, Pantheon, Piazza Navona, Ponte Sant'Angelo, Castel Sant'Angelo, Corte Suprema di Cassazione, Chiesa Sacro Cuore del Suffragio
        </p>
        <ul>
          <li className='map-links'>
            <a href="https://goo.gl/maps/ik4sdXUa4dyVAn6g7" target="_blank" rel="noopener noreferrer">Rota 2 için link</a>
          </li>
        </ul>

        <p className="wxxxpxpxowow">
            Yürüyüş rotası 3: Colosseum, Monument to Victor Emmanuel II, Cordonata Capitolina, Roman Forum, Palatine Hill
        </p>
        <ul>
          <li className='map-links'>
            <a href="https://goo.gl/maps/YtAXGj8UQu97i2BZ7" target="_blank" rel="noopener noreferrer">Rota 3 için link</a>
          </li>
        </ul>   
        <p className="wqwqrssdsdtwq">NOT: Vatikan zaten küçük bir yer olduğu için buraya ayrı bir yürüyüş rotası koymuyoruz ama Papa'nın kouşması veya müze ziyaretleri gibi Vatikan'daki tüm atraksiyonlara katılmak istiyorsanız buraya tam bir gün ayırıp sabahın en erken vaktinde yola koyulmanızı öneririz.</p>
                <p className="rssdsdt">Keyifli gezmeler.</p>
                <p className="uvwsdsd">Sevgiyle kalın.</p>
            </div>
            <br />
            <br />
            <DestinationSelector />
            <hr className="separator" />
            <CommentsSection componentName="destination8" />
        </div>
    );
};

export default Destination8;
