import React from 'react';
import '../../components/tipsPage.css';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import CommentsSection from '../../components/CommentsSection';
import DestinationSelector from '../../components/DestinationSelector';

const Tip2 = () => {
  const location = useLocation();
  const paragraphMarker = location.state?.textIdentifier || '';
  console.log(paragraphMarker);

  useEffect(() => {
    if (paragraphMarker) {
      const elementToHighlight = document.querySelector(`.${paragraphMarker}`);
      if (elementToHighlight) {
        elementToHighlight.classList.add('highlight');
        elementToHighlight.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [paragraphMarker]);

  return (
    <div className={`tipsPage-container`} >
        <Helmet>
            <title>Vize Rehberi - Hangiyol Seyahat Rehberi</title>
            <meta name="description" content="Türk vatandaşları için vize rehberi! Vize uygulamaları ülkelere göre değişir ve her ülkenin farklı vize politikaları vardır. Vizesiz seyahat edilebilen ülkelerin listesi ve vize başvurusu yapma süreçleri hakkında bilgi edinin. Turistik vize ve transit geçiş vizesi arasındaki farkları öğrenin. Tatil planlamanıza etkisi olacak vize işlemleri hakkında daha fazla bilgi için Türkiye Cumhuriyeti Dışişleri Bakanlığı'nın web sitesini ziyaret edin ve Hangiyol Seyahat Rehberi ile sorunsuz seyahatler yapın!" />
        </Helmet>

      <h2 className="aaa">Vize</h2>
      <br />
      <div className="tipsPage-content">
        <p className="bbb">
          Türk vatandaşları için vize uygulamaları, seyahat edilecek ülkeye göre değişmektedir. Bu konudaki en doğru bilgi Türkiye Cumhuriyeti Dışişleri Bakanlığı'nın <a href="https://www.mfa.gov.tr/turk-vatandaslarinin-tabi-oldugu-vize-uygulamalari.tr.mfa" target="_blank" rel="noopener noreferrer">web sitesinde</a> yer almaktadır. Vize başvurusu yapmak için, ilgili ülkenin Türkiye'deki temsilciliğine veya konsolosluğuna şahsen veya posta yoluyla başvuru yapmak gerekmektedir. Vize başvurusu için gerekli belgeler, ülkeye göre değişmektedir. Genel olarak, vize başvurusunda pasaport, fotoğraf, doldurulmuş vize başvuru formu, vize ücreti ve seyahat amacını gösteren belgeler talep edilmektedir. Vize başvurusu sonuçlanma süresi, ülkeye göre değişmektedir. Vize başvurusu sonuçlanmadan önce, seyahat planı yapılmamalıdır.
        </p>
        <h4 className="ccc">Transit vizeye dikkat!</h4>
        <p className="ddd">
          Turistik vize, bir kişinin turistik amaçlarla bir ülkeye seyahat etmesine izin veren bir vize türüdür. Transit geçiş vizesi ise, bir kişinin bir ülkeden başka bir ülkeye seyahat ederken, o ülkede yalnızca kısa bir süreliğine kalmak için gerekli olan bir vize türüdür. Turistik vize, genellikle bir kişinin seyahat amacını, konaklama yerini ve seyahat tarihlerini gösteren belgelerle birlikte başvuru yapılır. Transit geçiş vizesi ise, genellikle bir kişinin pasaportu ve uçuş bilgilerini gösteren belgelerle birlikte başvuru yapılır.
        </p>
        <p className="eee">
          Her ülkenin transit geçiş vizesi uygulamaları farklıdır. Bu nedenle, eğer aktarmalı bir uçuşunuz varsa, seyahat planı yapmadan önce aktarma yapmak için uçaktan inmeniz gereken ülkenin transit vize uygulamalarını kontrol etmek önemlidir. Bu konudaki güncel bilgilere, ilgili ülkenin Türkiye'deki temsilciliklerinden ulaşabilirsiniz.
        </p>
        <p className="fff">Sevgiyle kalın!</p>
        <p className="ggg">Hangiyol</p>
        <p className="hhh">-The End-</p>
      </div>
      <br />
      <br />
      <DestinationSelector /> 
      <hr className="separator" />
      <CommentsSection componentName="tip2" /> 
    </div>
  );
};

export default Tip2;
