  const cityDestinations = [
    { countryId: 1, countryName: 'Almanya', countryImage: './germany.jpg', cityId: 1, cityName: 'Nürnberg', cityImage: './nuremberg.jpg' },
    { countryId: 2, countryName: 'İtalya', countryImage: './italy.jpg', cityId: 2, cityName: 'Pisa', cityImage: './pisa.jpg' },
    { countryId: 3, countryName: 'Andorra', countryImage: './andorra.jpg', cityId: 3, cityName: 'Andorra la Vella', cityImage: './andorralavella.jpg' },
    { countryId: 2, countryName: 'İtalya', countryImage: './italy.jpg', cityId: 4, cityName: 'Floransa', cityImage: './florence.jpg' },
    { countryId: 2, countryName: 'İtalya', countryImage: './italy.jpg', cityId: 5, cityName: 'Venedik', cityImage: './venice.jpg' },
    { countryId: 4, countryName: 'İspanya', countryImage: './spain.jpg', cityId: 6, cityName: 'Madrid', cityImage: './madrid.jpg' },
    { countryId: 1, countryName: 'Almanya', countryImage: './germany.jpg', cityId: 7, cityName: 'Düsseldorf', cityImage: './dusseldorf.jpg' },
    { countryId: 2, countryName: 'İtalya', countryImage: './italy.jpg', cityId: 8, cityName: 'Roma', cityImage: './rome.jpg' },
  ];
  
  export default cityDestinations;
  