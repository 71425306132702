import React from 'react';
import '../../components/tipsPage.css';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import CommentsSection from '../../components/CommentsSection';
import DestinationSelector from '../../components/DestinationSelector';

const Tip1 = () => {
  const location = useLocation();
  const paragraphMarker = location.state?.textIdentifier || '';
  console.log(paragraphMarker);

  useEffect(() => {
    if (paragraphMarker) {
      const elementToHighlight = document.querySelector(`.${paragraphMarker}`);
      if (elementToHighlight) {
        elementToHighlight.classList.add('highlight');
        elementToHighlight.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [paragraphMarker]);

  return (
    <div className={`tipsPage-container`} >
      <Helmet>
        <title>Ucuz Uçak Bileti - Hangiyol Seyahat Rehberi</title>
        <meta name="description" content="Ucuz uçak bileti bulmanın püf noktalarını keşfedin! Seyahat planlarınızı uygun fiyatlı ve cebinize dost bir şekilde yapmak artık mümkün. Skyscanner ve diğer bilet karşılaştırma sitelerini kullanarak erken rezervasyon fırsatlarını değerlendirin, esnek seyahat tarihleriyle tasarruf edin ve farklı havayolu şirketleri arasında fiyat karşılaştırması yaparak sürpriz kombinasyonlarla uygun biletler bulun. Ayrıca, alternatif havalimanlarından yararlanın, geceyarısı uçuşlarından faydalanın ve uzun aktarmalı uçuşlarla uygun fiyatlı seyahatler planlayın. Hangiyol Seyahat Rehberi ile cebinize uygun tatil planlayın!" />
      </Helmet>
      <h2 className="aaa">Ucuz Uçak Bileti</h2>
      <br />
      <div className="tipsPage-content">
        <p className="bbb">
          Hepimizin hayalidir ucuz uçak bileti bulmak. Her şeyin bir püf noktası olduğu gibi bununda var ve ucuz bir tatil yapmak  sandığınız kadar zor değil! Eğer siz de cebinize dost bir seyahat planlamak istiyorsanız, doğru adrestesiniz. Bugünkü yazımızda, sizi uygun fiyatlı uçak biletlerinin gizemli dünyasına davet ediyoruz. Hazırsanız, işte ucuz uçak biletlerini yakalamanızı sağlayacak ipuçları!
        </p>
        <h4 className="ccc">Ne kadar erken o kadar ucuz</h4>
        <p className="ddd">
          Erken planlayan kazanır! Havayolu şirketlerinin e-mail listelerine kayıt olmak kampanyalardan haberdar olmak demek. Ayrıca ucuz uçak biletleri genellikle erken rezervasyon yapanlara sunulur. Tatil planınızı belirlediğiniz anda, hemen havayolu şirketlerinin web sitelerine göz atın ve erken rezervasyon indirimlerinden yararlanın. Tabii istisnalar mevcut. Havayolu şirketleri eğer uçağa yeterli yolcu bulamazsa son dakika daha da uyguna bilet çıkarabilir. Ama siz yine de ah o bileti alsaydım dememek için en azından satın almasanız bile rezervasyonunuzu yaptırın :D
        </p>
        <h4 className="eee">Esneklik Ödüllendirir</h4>
        <p className="fff">
          Ucuz uçak bileti bulmanın bir başka sırrı da seyahat tarihlerinde esnek olmaktır. Belki de tatilinizi bir gün önce veya sonra alarak bütçenize dost bir fırsat yakalayabilirsiniz. Özellikle fiyatlandırmayı tüm ay olarak seçtiğinizde en uygun bilet hangi günse gösterecektir.
        </p>
        <h4 className="ggg">Detaylı İnternet Taraması</h4>
        <p>
          Farklı havayolu şirketlerinin web sitelerini ve rezervasyon platformlarını araştırın. Farklı günlerde ve saatlerde yapılan uçuşların fiyatlarını karşılaştırın. Bazen sürpriz bir kombinasyon size hayalini kurduğunuz tatili uygun fiyatlarla sunabilir. Örneğin 2019 yılında Bali seyahatimiz Singapur aktarmalı olduğu için çok uyguna gelmişti. Fiyat karşılaştırması yapmak için kullanabileceğiniz bazı siteler: Kayak, Momondo, Skycanner, Kiwi. Çoğu siteyi kontrol etmekte fayda var. Örneğin bir bilet Kayak sitesinde satıştayken Momondo’da çıkmayabiliyor.
        </p>
        <h4 className="hhh">Alternatif Havalimanları</h4>
        <p className="iii">
          Ulaşım kolaylığı sağlayan büyük havalimanlarının dışında, yakın çevredeki daha küçük havalimanları da bulunur. Bu havalimanları genellikle daha az talep gördüğü ve uygun fiyatlı uçak firmaları geldiği için uçak bileti fiyatları daha uygun olabilir. Barselona’dan Nürnberg’e gidiş biletlerimizi Barselona Havalimanı yerine yine Barselona'da bulunan Girona Havalimanından yarı fiyatına almıştık.
        </p>
        <h4 className="jjj">Geceyarısı Uçuşları</h4>
        <p>
          Gece geç saatlerde veya sabahın erken saatlerinde yapılan uçuşlar genellikle daha az talep gördüğü için fiyatlar daha uygundur. Saat 03:00 de olan bir uçuşunuzda havaalanına erken gideceğiniz için hem konaklama ücreti ödemezsiniz hem de daha uyguna uçabilirsiniz.
        </p>
        <h4 className="kkk">Uzun Mola Ucuzluğu</h4>
        <p className="lll">
          Eğer uzun yolculuklar yapacaksanız, aktarmalı uçuşlar sizin için bir avantaj olabilir. Örnek vermek gerekirse  İspanya seyahatine aldığımız biletlerin ilk durağı Romanya’nın Yaş şehriydi. Sabaha karşı Yaş şehrine uçtuk ve tüm gün Yaş’ı gezip akşam 22:00 da da farklı bir uçak firmasıyla Madrid’e gittik. Bu şekilde uzun aktarmalı uçuşlarla uygun fiyatlara bilet bulmanız mümkün.
        </p>
        <h4 className="kkkxxx">Kara Yoluyla Tamamlama</h4>
        <p className="lllxxx">
          Bazen ne yaparsanız yapın ucuza uçak bileti bulmak mümkün olmaz. Bu nedenle çıkış veya geliş şehrinizin yakınlarındaki diğer şehirlere olan biletleri de kontrol edebilirsiniz. Örneğin Amsterdam'dan Ankara'ya gelmek istediğimiz bir seyahatmizde bu güzergahta kişi başı tek yön yaklaşık 5000 liradan aşağıda bir fiyata sahip bilet bulamadık ve yakındaki şehirleri araştırmaya karar verdik. Otobüsle dört buçuk saat uzakta olan Düseldorf şehrinden yaklaşık 2000 liraya aktarmasız ve bagaj hakkı da olan bir uçuş keşfettik (bu tarihteki euro kuru 29.50 lira). Amsterdam'dan buraya otobüs ile yalnızca 9.90 euro gibi düşük bir fiyata gelinebileceğini görünce hemen bu bileti aldık. Hem daha az ödemiş olduk hem de gezilecek bir şehrimiz daha oldu.
        </p>
        <h4 className="kkk">Kredi Kartı Kampanyaları</h4>
        <p className="lll">
          Eğer çok alışveriş yapan ve çok gezmek isteyen biriyseniz bu tüyo tam size göre olabilir. Bazı bankalar harcamalarınızı uçuş miline dönüştürebileceğiniz kredi kartları sunmakta. Bir bileti tamamen ücretsiz alabilmek için yüklü miktarda para harcamış olmak gerekiyor tabii ki, ama bankalar size avans mil kullanma seçeneği de sunabiliyor. Örneğin biriktirdiğiniz mil bir bileti almak için yeterli değil ama banka size diyor ki: -Tamam bu bileti ücretsiz alabilirsin ama kredi kartından 1 yıl içinde şu kadar para harcamayı taahhüt edeceksin! Eğer bu miktarı yakalayamazsanız da yine sorun değil çünkü sonuç olarak bu biletin ücretini ödemeyi baya uzun bir süre ertelemiş oluyorsunuz. Bazı durumlarda sadece ödemeyi ertelemiş olmak bile size büyük bir avantaj sağlamış olabiliyor.
        </p>
        <p className="mmm">Sevgiyle kalın!</p>
        <p className="nnn">Hangiyol</p>
        <p className="ooo">-The End-</p>
      </div>
      <br />
      <br />
      <DestinationSelector /> 
      <hr className="separator" />
      <CommentsSection componentName="tip1" /> 
    </div>
  );
};

export default Tip1;
