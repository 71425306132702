import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './DestinationSelector.css';
import cityDestinations from './destinationIndex';

const DestinationSelector = () => {
  const [selectedDestination, setSelectedDestination] = useState(null);
  const navigate = useNavigate();

  const handleDestinationChange = (event) => {
    const selectedId = parseInt(event.target.value);
    const selected = cityDestinations.find((destination) => destination.cityId === selectedId);
    setSelectedDestination(selected);
    navigate(`/destination/${selectedId}`);
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <h3>Bu şehirlere de bir göz atın:</h3>
      <select className="destination-select" onChange={handleDestinationChange} value={selectedDestination?.cityId || ''}>
        <option value="">Şehir seçiniz...</option>
        {cityDestinations.map((destination) => (
          <option key={destination.cityId} value={destination.cityId} >
            {destination.cityName}
          </option>
        ))}
      </select>
      {selectedDestination && (
        <div>
          <h4 className="selected-destination">Seçilen şehir: {selectedDestination.cityName}</h4>
        </div>
      )}
    </div>
  );
};

export default DestinationSelector;
