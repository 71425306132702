import React from 'react';
import './navigationMenu.css';

const NavigationMenu = () => {
  return (
    <ul className="nav-links">
      <li className="active"><a href="/">Anasayfa</a></li>
      <li><a href="/CountryPage">Ülkeler</a></li>
      <li><a href="/TipsPage">Tüyolar</a></li>
      <li><a href="/AboutPage">Hakkımızda</a></li>
      <li><a href="/ContactPage">İletişim</a></li>
    </ul>
  );
};

export default NavigationMenu;

