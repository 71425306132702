import React from 'react';
import "./FeaturedDestinations.css";
import { useNavigate } from 'react-router';

const FeaturedDestinations = ({ isNightMode }) => {

  const cityDestinations = [
    { countryId: 1, countryName: 'Almanya', countryImage: './germany.jpg', cityId: 1, cityName: 'Nürnberg', cityImage: './nuremberg.jpg' },
    { countryId: 2, countryName: 'İtalya', countryImage: './italy.jpg', cityId: 4, cityName: 'Floransa', cityImage: './florence.jpg' },
    { countryId: 3, countryName: 'Andorra', countryImage: './andorra.jpg', cityId: 3, cityName: 'Andorra la Vella', cityImage: './andorralavella.jpg' },
  ];

  const navigate = useNavigate();

  const handleButtonClick = (destination) => {
    navigate(`/Destination/${destination.cityId}`);
    window.scrollTo(0, 0);
  };

  return (
    <section
      className={`featured-destinations ${isNightMode ? 'night-mode' : ''}`}
    >
      <h2>Öne Çıkarılan Şehirler</h2>
      <div className="destination-list">
        {cityDestinations.map((destination) => (
          <div key={destination.cityId} className="destination-card">          
              <img src={destination.cityImage} alt={destination.cityName} onClick={() => handleButtonClick(destination)}/>         
            <h3>{destination.cityName}</h3>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FeaturedDestinations;
