import React, { useEffect, useState } from 'react';
import "./CommentSection.css";

const CommentSection = ({ componentName }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [commentInputs, setCommentInputs] = useState({});
  const [replyToAncestry, setReplyToAncestry] = useState(null);
  const [isSubcommentOpen, setIsSubcommentOpen] = useState(false);  
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);
  const [showSubcommentContainer, setShowSubcommentContainer] = useState(false);
  const [emptyCommentError, setEmptyCommentError] = useState(false);

  const generateMathProblem = () => {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    const operator = '+';
    const result = num1 + num2;
  
    return { problem: `${num1} ${operator} ${num2}`, result };
  };

  const [captcha, setCaptcha] = useState(generateMathProblem());

  const handleSubcommentClick = (ancestry) => {
      setReplyToAncestry(ancestry);
      setIsSubcommentOpen(true);
      setShowSubcommentContainer(true);
  };

  const handleCloseSubcomment = () => {
      setReplyToAncestry(null);
      setIsSubcommentOpen(false);
      setShowSubcommentContainer(false); 
  };

  // Helper function to format the date and time
  const getFormattedDateTime = (dateTime) => {
    const date = new Date(dateTime);
    const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    const formattedTime = `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
    return `${formattedDate} ${formattedTime}`;
  };

  const generateAncestryCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const codeLength = 10;
    let ancestryCode = '';

    for (let i = 0; i < codeLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      ancestryCode += characters[randomIndex];
    }

    return ancestryCode;
  };

  const fetchComments = () => {
    fetch(`/api/comments?component=${componentName}&isApproved=true`)
      .then(response => response.json())
      .then(data => {
        // Filter the comments to include only those with isApproved === true
        const approvedComments = data.filter(comment => comment.isApproved === true);
        // Filter the approved comments based on the destination component name
        const filteredComments = approvedComments.filter(comment => comment.component === componentName);
        setComments(filteredComments);
      })
      .catch(error => console.error(error));
  }; 
  
  useEffect(() => {
    fetchComments();
  }, []);

  const hideAlertsAfterDelay = () => {
    setTimeout(() => {
      setShowSuccessAlert(false);
      setShowErrorAlert(false);
      setCaptchaError(false);
      setEmptyCommentError(false);
    }, 3000); // 3 seconds (3000 milliseconds)
  };

  const handleSubmit = (event, commentId, parentLevel, ancestry) => {
    event.preventDefault();
  
    if (captcha.result !== captcha.userInput) {

      setCaptchaError(true);
      hideAlertsAfterDelay();
      return; 
    }
  
    const isSubComment = commentId !== undefined;
    let lineage = generateAncestryCode();
    let ancestryValue = lineage;
    let level = 0;
    let text = newComment.trim();
    let parent = null;
    let common = lineage;
  
    if (isSubComment) {
      const commentBeingCommentedOn = comments.find(comment => comment.ancestry === commentId);
      if (commentBeingCommentedOn) {
        lineage = `${commentBeingCommentedOn.lineage}-sub-${commentBeingCommentedOn.ancestry}`;
        level = parentLevel + 1;
        text = commentInputs[commentId] || ''; 
        ancestryValue = generateAncestryCode();
        parent = commentBeingCommentedOn.ancestry;
        common = commentBeingCommentedOn.lineage.slice(0, 10);
      }
    }
  
    if (isSubComment && text === '') {
      // For subcomments
      setShowSuccessAlert(false);
      setEmptyCommentError(true);
      hideAlertsAfterDelay();
      return; // Return early if subcomment is empty
    } else if (!isSubComment && text === '') {
      // For main comments
      setShowSuccessAlert(false);
      setEmptyCommentError(true);
      hideAlertsAfterDelay();
      return; // Return early if main comment is empty
    }
  
    const comment = {
      text: text,
      ancestry: ancestryValue,
      dateTime: new Date(),
      lineage: lineage,
      level: level,
      parent: parent,
      common: common,
      isAdministrator: false,
      component: componentName,
      isApproved: false,
    };
  
    fetch('/api/comments', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(comment)
    })
      .then(response => response.json())
      .then(data => {
        setShowSuccessAlert(true);
        hideAlertsAfterDelay();
        setCaptcha(generateMathProblem());
        setReplyToAncestry(null);
        if (!showErrorAlert && !captchaError) {
          setComments([...comments, data]);
          setNewComment('');
          setCommentInputs({ ...commentInputs, [commentId]: '' }); // Reset sub-comment input
          fetchComments();
          setIsSubcommentOpen(false);
          setShowSubcommentContainer(false);
        }
      })
      .catch(error => {
        console.error(error);
        setShowSuccessAlert(false); 
        setShowErrorAlert(true); 
        hideAlertsAfterDelay(); 
        setCaptcha(generateMathProblem());
      });
  
    setCaptcha(generateMathProblem());
  };
  

  const handleInputChange = (event, commentId) => {
    const { value } = event.target;
    setCommentInputs({ ...commentInputs, [commentId]: value });
  };

  return (
    <div className="comment-section">
      {showSuccessAlert && (
        <div className="alert alert-success">
          Yorumunuz başarılı bir şekilde gönderildi!
        </div>
      )}
      {showErrorAlert && (
        <div className="alert alert-error">
          Yorum göndermede hata oluştu!
        </div>
      )}
      {captchaError && (
        <div className="custom-alert-danger">
          Devam edebilmek için lütfen işlem sonucunu giriniz!
        </div>
      )}
      {emptyCommentError && (
        <div className="custom-alert-danger">
          Yorum kutusu boş olmamalıdır!
        </div>
      )}
      <h4>YORUMLAR</h4>
      {comments.map(comment => (
        <div
          key={comment.ancestry}
          className="comment-box"
          style={{ marginLeft: `${comment.level * 15}px` }}
        >
          <span className="comment-date">{getFormattedDateTime(comment.dateTime)}</span>

          {comment.isAdministrator && (
            <div className="administrator-badge">
              <img src="/mp_logo.svg" />
            </div>
          )}

          <p className='CommentBoxText'>{comment.text}</p>
          <form onSubmit={event => handleSubmit(event, comment.ancestry, comment.level)}>
            
          {!replyToAncestry || replyToAncestry !== comment.ancestry ? (
            <button 
              type="button" 
              className="subcommentVisible" 
              onClick={() => handleSubcommentClick(comment.ancestry)}
            >
              Cevapla
            </button>
              ) : showSubcommentContainer ? (
                <React.Fragment>
                  <div className='subcommentContainer'>
                    <div className='subcommentTextarea'>
                      <textarea
                        value={commentInputs[comment.ancestry] || ''}
                        onChange={event => handleInputChange(event, comment.ancestry)}
                        placeholder="Bu yoruma moderatöre sunulup yayınlanmak üzere bir cevap yazınız..."                        
                      />
                      <button className="closeSubcommentButton" onClick={handleCloseSubcomment}>
                        X
                      </button>
                    </div>
                    <div className="subcommentCaptcha">
                      <p>{captcha.problem}</p>
                      <p>=</p>
                      <input
                        className='subcommentCaptchaInput' // Correct class name
                        type="number"
                        value={captcha.userInput || ''}
                        onChange={event => setCaptcha({ ...captcha, userInput: parseInt(event.target.value, 10) })}
                        placeholder="?"
                      />
                    </div>
                    <div className='subcommentSubmit'>
                      <button className='subcommentTextareaButton' type="submit">
                        Gönder
                      </button>
                    </div>
                  </div>
                </React.Fragment>
            ) : null}
          </form>
        </div>
      ))}
      <form onSubmit={handleSubmit}>
        <div className='commentContainer'>
          <div className='commentTextarea'>
            <textarea
              value={newComment}
              onChange={event => setNewComment(event.target.value)}
              placeholder="Moderatöre sunulup yayınlanmak üzere bir yorum yazınız..."
            />
          </div>
          <div className="commentCaptcha">
            <p>{captcha.problem}</p>
            <p>=</p>
            <input
              className='commentCaptchaInput' // Correct class name
              type="number"
              value={captcha.userInput || ''}
              onChange={event => setCaptcha({ ...captcha, userInput: parseInt(event.target.value, 10) })}
              placeholder="?"
            />
          </div>
          <div className='commentSubmit'>
          <button className="commentTextareaButton" type="submit">
              Gönder
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CommentSection;
