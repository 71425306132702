import React from 'react';
import "./header.css"

const Footer = ({ isNightMode, toggleNightMode }) => {
  return (
    <footer className="header">
      <div className="nav">
        <div className="navigation">
          <p>&copy; {new Date().getFullYear()} Hangiyol Seyahat Rehberi. Tüm hakları saklıdır.</p>
        </div>
        <div className="logo">
          <a href="/">
            <img src="./mp_logo.svg" alt="Hangi Yol Seyahat Rehberi" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
