import React, { useState } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import "./searchForm.css";

const SearchForm = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  const navigate = useNavigate(); // Initialize the navigate function

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (searchQuery.trim() !== '') {
      navigate(`/SearchResultsPage`, { state: { searchQuery } });
    }
    setIsSearchVisible(false); // Revert back to icon form after submitting
  };

  const handleIconClick = () => {
    setIsSearchVisible(true);
  };

  const handleSearch = () => {
    setSearchQuery('');
    setIsSearchVisible(false);
  };

  return (
    <form className="search-form" onSubmit={handleSubmit}>
      {!isSearchVisible ? (
        <button 
          type="button" 
          className="search-icon" 
          onClick={handleIconClick}
          title="Arama"
        >
          <FontAwesomeIcon icon={faSearch} />
        </button>
      ) : (
        <React.Fragment>
          <input
            type="text"
            placeholder="Ara..."
            value={searchQuery}
            onChange={handleInputChange}
          />
          <button 
            type="submit" 
            onClick={handleSubmit}
            title="Arama"
          >
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </React.Fragment>
      )}

    </form>
  );
};

export default SearchForm;
