import React from 'react';
import '../../components/Destination.css';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CommentsSection from '../../components/CommentsSection';
import DestinationSelector from '../../components/DestinationSelector';

const Destination2 = () => {
  const location = useLocation();
  const paragraphMarker = location.state?.textIdentifier || '';
  console.log(paragraphMarker);

  useEffect(() => {
    if (paragraphMarker) {
      const elementToHighlight = document.querySelector(`.${paragraphMarker}`);
      if (elementToHighlight) {
        elementToHighlight.classList.add('highlight');
        elementToHighlight.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [paragraphMarker]);

      // Define an array of image data with height multipliers
      const imageData = [
        { key: 1, src: '../../pisa1.jpg', altText: 'pisa-manzara' },
        { key: 2, src: '../../pisa2.jpg', altText: 'pisa-sokaklar' },
        { key: 3, src: '../../pisa3.jpg', altText: 'pisa-tuttomundo' },
        { key: 4, src: '../../pisa4.jpg', altText: 'pisa-corsoItalia' },
        { key: 5, src: '../../pisa5.jpg', altText: 'pisa-ponteMezzo' },
        { key: 6, src: '../../pisa6.jpg', altText: 'pisa-mucize' },
        { key: 7, src: '../../pisa7.jpg', altText: 'pisa-piknik' },
        { key: 8, src: '../../pisa8.jpg', altText: 'pisa-cabalieri' },
        { key: 9, src: '../../pisa9.jpg', altText: 'pisa-garibaldi' },
      ];

      const attractions = [
        { name: "Ulaşım", id: "ulasim" },
        { name: "Konaklama", id: "konaklama" },
        { name: "Yeme-İçme", id: "yeme-icme" },
        { name: "Tuttomundo - Keith Haring’in Muralı", id: "tuttomundo" },
        { name: "Corso Italia", id: "corso-italia" },
        { name: "Ponte Di Mezzo Köprüsü - Arno Nehri Ve Santa Maria Della Spina", id: "ponte-di-mezzo-koprusu" },
        { name: "Mucizeler Meydanı", id: "mucizeler-meydani" },
        { name: "Piazza Dei Cavalieri-Şövalyeler Meydanı", id: "piazza-dei-cavalieri" },
        { name: "Piazza Garibaldi", id: "piazza-garibaldi" },
        { name: "Google Haritalar için Yürüme Rotası", id: "yuruyus" },
      ];
    
      const scrollToAttraction = (attractionId) => {
        const elementToScroll = document.getElementById(attractionId);
        if (elementToScroll) {
          elementToScroll.scrollIntoView({ behavior: 'smooth' });
        }
      };

  return (
    <div className="destination-container">
      <Helmet>
        <title>Pisa, İtalya - Hangiyol Seyahat Rehberi</title>
        <meta name="description" content="Pisa Gezi Rehberi: Pisa'nın görülmeye değer yerleri ve tüyolar. Ulaşım, konaklama, yeme-içme ve daha fazlası." />
      </Helmet>
      <h2 className="aaa">Pisa, İtalya</h2>
      <br/>
      <div className="destination-content">
      <div className='content-image-container'>
          <div className="content-image-landscape"
              style={{ backgroundImage: `url(${imageData[0].src})` }}
              aria-label={imageData[0].altText}
              loading="lazy"
          ></div>
        </div>
        <div>
        <ul>
          {attractions.map((attraction) => (
            <li key={attraction.name}>
              <button 
                onClick={() => scrollToAttraction(attraction.id)}
                style={{
                  border: 'none',         
                  cursor: 'pointer',          
                  borderRadius: '0.35rem',                           
                }}
              >
                {attraction.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
        <p className="bbb">
          Kuleyi itmek için eller ve ayaklar hazırsa sizi Pisa Gezi Rehberime alayım asfadsfags 😂
          Şaka bir yana kuleyi itiyormuş gibi yapmayalım arkadaşlar! Pisa minicik içi sürprizlerle dolu sevimli bir şehir olduğu için kalbi kırılabilir. Dikkatli olalım. 
        </p>
        <h3 className="ccc" id="ulasim">ULAŞIM</h3>
        <br/>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[1].src})` }}
              aria-label={imageData[1].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="ddd">
          Roma’dan sonra Floransa’ya geçeceğimiz için yolda Pisa’ya uğrayalım dedik ve Roma üzerinden trenle geldik. Roma’dan trenle gelmek isterseniz Roma Termini tren istasyonundan - Pisa Centrale’ye 4 saatte, otobüsle gelmek isterseniz Rome Tibirtuna Bus Station’dan kalkan otobüs firmalarından birini tercih ederek yaklaşık 6 saatte gelebilirsiniz. Floransa’dan gelmek isterseniz de Florence Villa Costanza’dan kalkan otobüslerle trafiğin durumuna bağlı olarak 1 buçuk 2 saate ulaşabilirsiniz.
        </p>
        <br/>
        <h3 className="eee" id="konaklama">KONAKLAMA</h3>
        <p className="fff">
          Burayı dinlenme durağı olarak seçtiğimiz için 1 gece konakladık ve sabah uyanıp rahatça etrafta kimseler yokken gezebildik. Fakat Floransa’dan geliyorsanız yarım günde çok rahat gezebileceğiniz için konaklamaya gerek yok.
        </p>
        <br/>
        <h3 className="ggg" id="yeme-icme">YEME-İÇME</h3>
        <p className="hhh">
          Biz AirBnb’de kaldığımız için o gün yemeklerimizi kendimiz hazırlamıştık. Cebinizi sarsmadan karnınızı doyurmak isterseniz meydanlardan ziyade ara sokaklardaki restoran ve kafeleri tercih edebilirsiniz. A yok ben illa meydan kahvesi içeceğim derseniz de bir sürprizle karşılaşmamak için İtalya’da çoğu mekanda olan kuver fiyatlarına bakmayı unutmayın. Maslow’un temel ihtayaçlar piramidinin ilk basamaklarını Pisa’da nasıl karşılayacağımızı öğrendiysek aşkınlık ihtiyaçlarına geçip bir gezelim bakalım neler varmış 🙂
        </p>
        <br/>
        <h3 className="iii" id="tuttomundo">1-TUTTOMUNDO - Keith Haring’in Muralı</h3>
        <div className='content-image-container'>
          <div className="content-image-landscape"
              style={{ backgroundImage: `url(${imageData[3].src})` }}
              aria-label={imageData[3].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="jjj">
          Şimdi bu ne demeyin arkadaşlar durun meydanları da gezeceğiz. Keith Haring’in ölmeden önce yapmış olduğu son halka açık duvar resimlerinden biridir ve 30 tane figürü tasvir eder. Karşısında ki banka oturup incelenesi mural. Nasıl olsa vakit çok.
        </p>
        <br/>
        <h3 className="kkk" id="corso-italia">2-CORSO ITALIA</h3>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[4].src})` }}
              aria-label={imageData[4].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="lll">
          Taşıtlara kapatılmış olan bu cadde sadece yayalar için. Pisa’nın ana alışveriş caddesidir. Buradan devam edip Arno Nehri’ni görüp kıyısından yürümeye devam ediyoruz.
        </p>
        <br/>
        <h3 className="mmm" id="ponte-di-mezzo-koprusu">3-PONTE DI MEZZO KÖPRÜSÜ - ARNO NEHRİ VE SANTA MARIA DELLA SPINA</h3>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[5].src})` }}
              aria-label={imageData[5].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="nnn">
          Santa Maria Della Spina kilisesine doğru yürüyüp hemen önündeki Ponte di Mezzo Köprüsünden karşıya geçiyoruz. Denk gelirseniz kiliseyi de ücretsiz gezebilirsiniz bizim ziyaretimizde maalesef kapalıydı. Dış cephesi ve ayrıntıları gerçekten çok güzel.
        </p>
        <br/>
        <h3 className="ooo" id="mucizeler-meydani">4-MUCİZELER MEYDANI</h3>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[8].src})` }}
              aria-label={imageData[8].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="ppp">  
          Çoğu görmeniz gereken yerler bu meydanda. Pisa Kulesi, Pisa Katedrali, Vaftizhane, Sinopie Müzesi, Camposanto Monumentale(Anıtsal Mezarlık). Tek tek ayrıntılı tarihi bilgiler vermeyeceğim zaten her yerden ulaşabilirsiniz. Fakat Katedrali görmek istiyorsanız giriş hem ücretsiz hem de kişi sayısı sınırlı olduğu için sabah erkenden gidip ücretsiz biletinizi gişeden temin etmeniz gerekiyor online satılmıyor. Diğer tüm girişleri (Pisa Kulesi, Vaftizhane, Sinopie Müzesi, Camposanto Monumentale) online alırsanız burada uzun kuyruklarda vakit kaybetmemiş olursunuz. Hem bilet hem de giriş sırası beklemek biraz yorabilir. Dilerseniz resmi sitesinden hepsine kombine bilet de alabilirsiniz.
        </p>
        <div className='content-image-container'>
          <div className="content-image-landscape"
              style={{ backgroundImage: `url(${imageData[2].src})` }}
              aria-label={imageData[2].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="rrr">
          Not: Marketten yiyeceklerinizi alıp oturulan çim bölümde pikniklemeyi unutmayın! 🙂
        </p>
        <br/>
        <h3 className="sss" id="piazza-dei-cavalieri">5-PIAZZA DEI CAVALIERI-ŞÖVALYELER MEYDANI</h3>
        <div className='content-image-container'>
          <div className="content-image-landscape"
              style={{ backgroundImage: `url(${imageData[6].src})` }}
              aria-label={imageData[6].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="ttt">
          Şehirdeki önemli meydanlardan bir diğeri de bu meydan. Meydanda Palazzo Della Carovana (Carovana Sarayı) karşınıza çıkacak. 1810’dan beri Pisa Üniversitesi merkez binası olarak kullanılıyor. Ayrıca orta çağda hapishane olan fakat günümüzde kütüphane olarak kullanılan Palazzo Dell'Orologio ve Cavalieri Kilisesi de burada bulunmakta.
        </p>
        <br/>
        <h3 className="uuu" id="piazza-garibaldi">6-PIAZZA GARIBALDI</h3>
        <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[7].src})` }}
              aria-label={imageData[7].altText}
              loading="lazy"
          ></div>
        </div>
        <p className="vvv">
          Giuseppe Garibaldi, İtalya Devleti'nin kurulmasına öncülük etmiştir.Tren istasyonuna ya giderken ya gelirken buradan mutlaka geçersiniz. Tüm bu yerleri yarım günde çok rahat gezebileceğiniz için seyahat planınızı ona göre yapabilirsiniz.
        </p>
        <h3 className="xxxooopxpx" id="yuruyus">Google Maps için Pisa Yürüyüş Rotası</h3>
        <p className="wwwpxpxooo">
            Noktalar: Pisa Tren İstasyonu, Tuttomundo, Corso Italia, Ponte di Mezzo, Garibaldi Meydanı, Mucizeler Meydanı, Şövalyeler Meydanı, Santa Maria Della Spina.
        </p>
        <ul>
          <li className='map-links'>
            <a href="https://goo.gl/maps/yFjgtqpenQqwvWd19" target="_blank" rel="noopener noreferrer">Rota için link</a>
          </li>
        </ul>
        <p className="www">
        Keyifli gezmeler.
        </p>
        <p className="xxx">
        -The End-
        </p>
      </div>
      <br />
      <br />
      <DestinationSelector /> 
      <hr className="separator" /> 
      <CommentsSection componentName="destination2" /> 
    </div>
  );
};

export default Destination2;
