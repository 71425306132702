import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './DestinationSelector.css';
import cityDestinations from './destinationIndex';

const DestinationPage = () => {
  const location = useLocation();
  const countryQuery = location.state.countrySelection;

  const filteredDestinations = cityDestinations.filter(
    (destination) => destination.countryId === countryQuery
  );

  return (
    <div className="DestinationPageParent">
      <div>
        <h2>Şehirler</h2>
        <div className="destination-list-dp">
          {filteredDestinations.map((destination) => (
            <div key={filteredDestinations.cityId} className="destination-card">
              <Link to={`/destination/${destination.cityId}`}>
                <img src={destination.cityImage} alt={destination.cityName} loading="lazy"/>
              </Link>
                <h5>{destination.cityName}</h5>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DestinationPage;
