import React from 'react';
import { Link } from 'react-router-dom';
import "./sideComponents.css";

const TipsPage = () => {
  const tipsIndex = [
    { id: 1, name: 'Ucuz Uçak Biletleri', image: './ucakbileti.jpg' },
    { id: 2, name: 'Vize', image: './vize.jpg' },
    { id: 3, name: 'Konaklama', image: './konaklama.jpg' },
    { id: 4, name: 'Yolcu Sorumluluğunda Aktarma', image: './aktarma.jpg' },
  ];

  return (
    <div className='DestinationPageParent'>
      <div> 
        <h2>Tavsiyeler</h2>
        <div className="destination-list-dp">
          {tipsIndex.map((tip) => (
            <div key={tip.id} className="destination-card">
              <Link to={`/tip/${tip.id}`}>
                <img src={tip.image} alt={tip.name} loading="lazy"/>
              </Link>  
                <h5>{tip.name}</h5>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TipsPage;
