import React from 'react';
import { useLocation, Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import FeaturedDestinations from './components/FeaturedDestinations';
import AboutPage from './components/AboutPage';
import ContactPage from './components/ContactPage';
import NotFoundPage from './components/NotFoundPage';
import Destination1 from './articles/destinations/destination1';
import Destination2 from './articles/destinations/destination2';
import Destination3 from './articles/destinations/destination3';
import Destination4 from './articles/destinations/destination4';
import Destination5 from './articles/destinations/destination5';
import Destination6 from './articles/destinations/destination6';
import Destination7 from './articles/destinations/destination7';
import Destination8 from './articles/destinations/destination8';
import Tip1 from './articles/destinations/tip1';
import Tip2 from './articles/destinations/tip2';
import Tip3 from './articles/destinations/tip3';
import Tip4 from './articles/destinations/tip4';
import SearchResultsPage from './components/SearchResultsPage';
import CountryPage from './components/CountryPage';
import TipsPage from './components/TipsPage';
import DestinationPage from './components/DestinationPage';
import Footer from './components/Footer';
import { Helmet } from 'react-helmet';

const App = () => {
  const location = useLocation();
  // console.log(location);

  const [isNightMode, setIsNightMode] = useState(false);
  const toggleNightMode = () => {
    const newNightMode = !isNightMode;
    setIsNightMode(newNightMode);
    localStorage.setItem('nightMode', JSON.stringify(newNightMode));
  };
  
  useEffect(() => {
    const storedNightMode = localStorage.getItem('nightMode');
    if (storedNightMode) {
      setIsNightMode(JSON.parse(storedNightMode));
    }
  }, []);
  
  return (
    <div className={`App ${isNightMode ? 'night-mode' : ''}`}>
      <Helmet>
        <title>Hangiyol Seyahat Rehberi</title>
        <meta name="description" content="Keşfetmeye hazır mısınız? Hangiyol Seyahat Rehberi ile dünyanın en güzel köşelerini keşfedin. Seyahat ipuçları, gezi rehberleri ve daha fazlası sizi bekliyor!" />
      </Helmet>
      <Header className={isNightMode ? 'night-mode' : ''} isNightMode={isNightMode} toggleNightMode={toggleNightMode} />
      <div className="content-wrapper">
        <div className="content-column">
          <Routes>
            <Route index element={<HeroSection />} />
            <Route path="/TipsPage" element={<TipsPage />} />
            <Route path="/tip/1" element={<Tip1 />} />
            <Route path="/tip/2" element={<Tip2 />} />
            <Route path="/tip/3" element={<Tip3 />} />
            <Route path="/tip/4" element={<Tip4 />} />
            <Route path="/AboutPage" element={<AboutPage />} />
            <Route path="/ContactPage" element={<ContactPage />} />
            <Route path="/CountryPage" element={<CountryPage />} />
            <Route path="/DestinationPage" element={<DestinationPage />} />
            <Route path="/destination/1" element={<Destination1 />} />
            <Route path="/destination/2" element={<Destination2 />} />
            <Route path="/destination/3" element={<Destination3 />} />
            <Route path="/destination/4" element={<Destination4 />} />
            <Route path="/destination/5" element={<Destination5 />} />
            <Route path="/destination/6" element={<Destination6 />} />
            <Route path="/destination/7" element={<Destination7 />} />
            <Route path="/destination/8" element={<Destination8 />} />
            <Route path="/SearchResultsPage" element={<SearchResultsPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </div>
      <div className="Featured">
        <FeaturedDestinations isNightMode={isNightMode} />
      </div>
      <div className="footer">
        <Footer className={isNightMode ? 'night-mode' : ''} isNightMode={isNightMode} toggleNightMode={toggleNightMode}/>
      </div>
    </div>
  );
};

export default App;
