import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles.css';
import '@fortawesome/fontawesome-svg-core/styles.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <React.StrictMode>
    
      <App />
    
  </React.StrictMode>
  </BrowserRouter>
);

reportWebVitals();
