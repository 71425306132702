import React from 'react';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../../components/Destination.css';
import CommentsSection from '../../components/CommentsSection';
import DestinationSelector from '../../components/DestinationSelector';

const Destination5 = () => {
  
  const scrollToAttraction = (attractionId) => {
    const elementToScroll = document.getElementById(attractionId);
    if (elementToScroll) {
      elementToScroll.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const location = useLocation();
  const paragraphMarker = location.state?.textIdentifier || '';
  console.log(paragraphMarker);
  
  useEffect(() => {
    if (paragraphMarker) {
      const elementToHighlight = document.querySelector(`.${paragraphMarker}`);
      if (elementToHighlight) {
        elementToHighlight.classList.add('highlight');
        elementToHighlight.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [paragraphMarker]);

  const attractions = [
    { name: "Palazzo Ducale", id: "palazzo-ducale" },
    { name: "San Marco Bell Tower", id: "san-marco-bell-tower" },
    { name: "Bridge of Sighs (Ponte dei Sospiri) - Ahlar Köprüsü", id: "bridge-of-sighs" },
    { name: "Monument to Victor Emmanuel II", id: "monument-to-victor-emmanuel-ii" },
    { name: "The Arsenal of Venice", id: "the-arsenal-of-venice" },
    { name: "St. Mark's Clock Tower", id: "st-marks-clock-tower" },
    { name: "San Marco Bazilikası", id: "san-marco-bazilikasi" },
    { name: "San Silvestro", id: "san-silvestro" },
    { name: "Santa Maria della Salute", id: "santa-maria-della-salute" },
    { name: "Rialto Bridge", id: "rialto" },
    { name: "Museo Correr - The National Archaelogical Museum - Biblioteca Marciana", id: "museo" },
    { name: "Venedik Yeme-İçme", id: "venedik-yeme-icme" },
    { name: "Ulaşım", id: "ulasim" },
    { name: "Haritalı Yürüyüş Rotası", id: "yuruyus" },
  ];

  return (
    <div className="destination-container">
      <Helmet>
        <title>Venedik, İtalya Gezi Rehberi - Hangiyol Seyahat Rehberi</title>
        <meta name="description" content="Venedik'te görülmesi gereken yerler arasında Palazzo Ducale, San Marco Bell Tower, Bridge of Sighs, Monument to Victor Emmanuel II gibi önemli yapılar bulunuyor. Ayrıca Venedik Arsenali askeri amaçla kullanılan bir alan olup, müzelerde sergiler de düzenleniyor. San Marco Bazilikası, Venedik'in en ünlü yapısıdır ve içerisinde Pala d'Oro ve Loggia dei Cavalli gibi değerli eserler bulunur. Hangiyol Seyahat Rehberi ile tüm bu güzelliklerin tadına varın." />
      </Helmet>

      <h2 className="aaaaaa">Venedik, İtalya</h2>
      <br />

    <div className="destination-content">
      <div className="content-image-container">
        <div className="content-image-portrait"
          style={{ backgroundImage: `url(../../venice.jpg)` }}
          aria-label="İTALYA- VENEDİK"
          loading="lazy"
        ></div>
      </div>
    <p className="def">118 adanın üzerine kurulmuş olan ve yaklaşık 400 köprüsü bulunan romantik bir şehir Venedik. Ayrıca Avrupa’da araba girişinin yasak olduğu en büyük bölgedir. Bu yüzden şehire arabayla ulaşabilirsiniz fakat buradan sonra sadece iki ulaşım aracınız var; ayaklarınız ve vaporettalar 😀</p>
    <div className="content-image-container">
      <div className="content-image-portrait"
        style={{ backgroundImage: `url(../../venedik24.jpg)` }}
        aria-label="Accademia Bridge"
        loading="lazy"
        ></div>
      </div>
    <p className="ghibbb">Bizim şehre ulaşımımız Floransa’dan kalkan trenle Santa Lucia Train Station’a varmamızla oldu. İki tren istasyonu var bir diğeri de Venice Mestre. Şehrin yakınında inecekseniz Santa Lucia’yı tercih etmelisiniz. Farklı şehirlerden buraya gelen otobüsler de var.</p>
    <p className="jklccc">Konu başlıklarımızı aşağıdaki listede özetledik:</p>
    <div>
        <ul>
          {attractions.map((attraction) => (
            <li key={attraction.name}>
              <button 
                onClick={() => scrollToAttraction(attraction.id)}
                style={{
                  border: 'none',         
                  cursor: 'pointer',          
                  borderRadius: '0.35rem',                           
                }}
              >
                {attraction.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
    <p className="mnoddd">Trenden indiğimiz gibi fıtı fıtı turist bilgilendirme ofisine girip neler varmış diye bir baktık. Sonrasında tren istasyonunun yanındaki;  büyük kanal üzerinde bulunan dört köprüden biri olan Ponte Degli Scalzi yaya köprüsünü kullanarak karşıya Croce bölgesine geçtik.</p>
    <p className="stueee">Kalacağımız eve doğru giderken şehrin en büyük katolik kiliselerinden biri olan  Frari Santa Maria Gloriosa Bazilikası’na uğradık. Titian’ın ‘Assumption of The Virgin’ ve Donatello’nun ‘Saint John the Baptist’ heykeli de burada bulunuyor. Sanat galerisi havasında gezebileceğiniz gotik kutsal bir yer.</p>
    <p className="vwxfff">Kalacağımız eve varıp eşyalarımızı bıraktık ve kendimizi hemen Napolyon’un Dünya’nın en güzel misafir odası olarak tanımladığı San Marco Meydanı’na attık. Bu meydanda San Marco Bazilikası, Aziz Mark’ın Çan Kulesi, Palazzo Ducale, St. Mark Saat Kulesi’ni ayrıca Palazzo Ducale’in hemen arkasındaki  Bridge of Sighs(Ponte dei Sospiri) gibi turistik noktaları kolaylıkla gezebilirsiniz. Bizim bugün için az bir zamanımız kaldığından Palazzo Ducale’i ziyaret etmeyi tercih ettik. Buraya girmeden önce Venice 3 Museums Pass biletinden aldık. Bu bilet Doge’s Palace, Museo Correr, Archaeological Museum’a sıra beklemeksizin giriş imkanı sağlıyor.</p>
    <p className="yzaggg">*Venice 3 Museums Pass 2023 itibariyle 25 Euro.</p>
    <p className="bcdhhh">** Vaktimiz çok diğer müzeleri de gezeriz derseniz ekstra 10 müze girişli bir pass bileti daha var fakat Accademia gibi müzeleri kapsamadığı için biz almadık. Eğer o bilete dahil olan müzeler ilginizi çekiyorsa resmi sitesinden müzeleri kontrol edip o biletten de alabilirsiniz.</p>
    <h3 className="efgiii" id="palazzo-ducale">Palazzo Ducale</h3>
    <div className="content-image-container">
      <div className="content-image-portrait"
        style={{ backgroundImage: `url(../../venedik2.jpg)` }}
        aria-label="Palazzo Ducale"
        loading="lazy"
        ></div>
      </div>
    <p className="hijjjj">Şehrin geçmişte ne kadar zengin olduğunu görmek isterseniz Venedik’in en ikonik yapılarından biri olan bu saray etkileneceğiniz o kadar çok şeye sahip ki; toplantı salonlarındaki sanat eserlerinden, hücrelerin o korkunç soğukluğuna kadar geniş bir yelpazesi var. Girişte çok fazla sıra olabilir o yüzden biletinizi skip the line almakta fayda var. Hem sıra beklemeden girdik hem de akşam üzeri gittiğimiz için gezerken neredeyse kimse yoktu 😀</p>
    <h3 className="klmkkk" id="san-marco-bell-tower">San Marco Bell Tower</h3>
    <div className="content-image-container">
  <div className="content-image-portrait"
      style={{ backgroundImage: `url(../../venedik3.jpg)` }}
      aria-label="San Marco Bell Tower"
      loading="lazy"
  ></div>
</div>
    <p className="noplll">Eğer meydanda bulunan çan kulesine çıkmak isterseniz burası sabah 09:30’da açılıp akşam 21:15’de kapanıyor.</p>
    <p className="qrsmmm">*Çan kulesine çıkış 2023 itibariyle 10 Euro, 6 yaş altı ücretsiz.</p>
    <h3 className="tuvnnn" id="bridge-of-sighs">Bridge of Sighs(Ponte dei Sospiri)-Ahlar Köprüsü</h3>
    <div className="content-image-container">
  <div className="content-image-portrait"
      style={{ backgroundImage: `url(../../venedik23.jpg)` }}
      aria-label="bridge-of-sighs"
      loading="lazy"
  ></div>
</div>
    <p className="wxyooo">Lord Byron tarafından köprüye bu ismin verilmesinin sebebi Dükler sarayının hapishanesine gönderilen mahkumlar bu köprüden son kez geçer ve Venedik manzarasına bu köprüden bakıp ah çekerlermiş. Ne acı.</p>
    <h3 className="zabppp" id="monument-to-victor-emmanuel-ii">Monument to Victor Emmanuel II</h3>
    <div className="content-image-container">
  <div className="content-image-portrait"
      style={{ backgroundImage: `url(../../venedik4.jpg)` }}
      aria-label="Monument to Victor Emmanuel II"
      loading="lazy"
  ></div>
</div>
    <h3 className="ijkrrr" id="the-arsenal-of-venice">The Arsenal of Venice</h3>
    <div className="content-image-container">
  <div className="content-image-landscape"
      style={{ backgroundImage: `url(../../venedik5.jpg)` }}
      aria-label="The Arsenal of Venice"
      loading="lazy"
  ></div>
</div>
    <p className="lmnsss">Arsenal bölgesinde bulunan tersane hala askeri amaç için kullanılıyor ve bu yüzden tersane içindeki binalara girişler yasak. Gittiğiniz tarihte müzede sergi olup olmadığına bakabilirsiniz, arada sergiler oluyormuş.</p>
    <p className="opqttt">*2023 itibariyle giriş ücreti tam 5 Euro indirimli 3,5 Euro.</p>
    <h3 className="rstuuu" id="st-marks-clock-tower">St Marks Clock Tower</h3>
    <div className="content-image-container">
  <div className="content-image-portrait"
      style={{ backgroundImage: `url(../../venedik6.jpg)` }}
      aria-label="St Marks Clock Tower"
      loading="lazy"
  ></div>
</div>
    <p className="uvwvvv">1500’lerden kalma rönesans’ı yansıtan bu eser meydanda dikkat çeken korunmuş diğer yapılardan biri. Eğer bu saat kulesini gezmek isterseniz yaklaşık 1 saat süren özel bir rehberle gezebilirsiniz. Bunun için de öncesinde rezervasyon yaptırmanız şart.</p>
    <p className="xyzyyy">Not: Kuleye 6 yaş altındaki çocuklar alınmıyor. Ayrıca kule dik ve dar merdivenli bir yapıya sahip olduğu için yürümekte zorlananlar ve klostrofobisi olanlar için önerilmiyor.</p>
    <p className="abczzz">*2023 itibariyle giriş ücretleri tam 14 Euro indirimli 11 Euro. Bu bileti satın alırsanız bununla birlikte  Museo Correr, the National Archaelogical Museum and the State Rooms of the Biblioteca Marciana’ya da girebilirsiniz.</p>
    <h3 className="defwww" id="san-marco-bazilikasi">San Marco Bazilikası</h3>
    <div className="content-image-container">
  <div className="content-image-portrait"
      style={{ backgroundImage: `url(../../venedik7.jpg)` }}
      aria-label="San Marco Bazilikası"
      loading="lazy"
  ></div>
</div>
    <p className="ghiabab">Güne Venedik’in en ünlü yapısı olan bu bazilikayı gezmekle başladık. Gün içinde bitmeyen bir sıra olduğu için sabah gelmeyi tercih ettik.</p>
    <p className="jklcdcd">İtalya’nın en önemli dini yapılarından biri olan bu bazilikaya girerken kıyafetlerinizin kapalı olmasına dikkat etmekte fayda var çünkü kıyafetinizin açıklığından dolayı(mini etek ya da dekolte) içeri alınmayabilirsiniz.</p>
    <p className="mnodede">Haçlı seferleri sırasında Constantinopolis'in hipodromundan çalınıp getirilen dört at heykeli de burada sergilenmektedir.</p>
    <p className="pqrefef">*2023 itibariyle bazilikaya giriş ücreti 3 Euro. Bazilikanın içinde bulunan Pala d'Oro 5 Euro ve Loggia dei Cavalli 7 Euro. 6 yaş altı çocuklar ücretsiz.</p>
    <h3 className="stufgfg" id="rialto">Rialto Bridge (Ponte di Rialto)</h3>
    <div className="content-image-container">
  <div className="content-image-landscape"
      style={{ backgroundImage: `url(../../venedik20.jpg)` }}
      aria-label="Rialto Bridge"
      loading="lazy"
  ></div>
</div>
    <p className="vwxghgh">Buraya Venedik’in en ünlü ve eski köprüsü diyebilir miyiz? Hemen kendi kendime cevap vereyim 😀 Bence evet. Orjinalinde ahşap olan bu köprü zamanla hasar görünce sonradan taş köprüye dönüştürülmüş. Yoğun saatlerde giderseniz köprü üzerinde ilerlemek bir hayli zor olacaktır. Kalabalıktan hoşlanmıyorsanız en azından sabah ya da akşam geç saatlerde gitmeyi tercih edebilirsiniz. Biz hem sabah gidip hem de akşam dönerken gün batımına denk geldik. Venedik’te vaktiniz varsa bir gün güneşi burada batırabilir ve köprü üzerindeki mağazaları gezebilirsiniz.🙂</p>
    <h3 className="yzahihi" id="san-silvestro">San Silvestro</h3>
    <div className="content-image-container">
  <div className="content-image-portrait"
      style={{ backgroundImage: `url(../../venedik8.jpg)` }}
      aria-label="San Silvestro"
      loading="lazy"
  ></div>
</div>
    <p className="bcdijij">Hazır Rialto yakınlarındayken bu kiliseye de göz atabilirsiniz.</p>
    <p className="efgjkjk">*Giriş ücretsiz.</p>
    <h3 className="hijklkl" id="museo">Museo Correr-The National Archaelogical Museum-Biblioteca Marciana</h3>
    <div className="content-image-container">
  <div className="content-image-portrait"
      style={{ backgroundImage: `url(../../venedik21.jpg)` }}
      aria-label="Museo Correr"
      loading="lazy"
  ></div>
</div>
    <p className="klmlmlm">Arkeoloji müzesi ve Marianne Kütüphanesiyle birleşen Correr’i aynı anda gezdik. Resim, heykel, zırh ve silah gibi birçok koleksiyona ev sahipliği yapıyor. Burayı gezerken Venedik’in geniş tarihini daha somut bir şekilde görebilirsiniz. Dükler sarayını ortalama bir buçuk saate geziyorsanız bu üçlüyü 40 dakikada bitirebilirsiniz. Dükler sarayı için aldığımız kombine bilet burayı kapsadığı için ekstra bir ücret ödemedik.</p>
    <h3 className="nopmnmn" id="santa-maria-della-salute">Santa Maria della Salute</h3>
    <div className="content-image-container">
  <div className="content-image-portrait"
      style={{ backgroundImage: `url(../../venedik9.jpg)` }}
      aria-label="Santa Maria della Salute"
      loading="lazy"
  ></div>
</div>
    <p className="qrsnono">Dorsoduro yarımadasında uçta bulunan ve uzaktan manzara fotoğraflarınıza eşlik eden bazilika. Veba salgınına karşı mimari cevap olarak bu kiliseyi yaptırıp Madonna’ya adamışlar. Merdivenlerine oturup vakit geçirebilirsiniz.</p>
    <p className="tuvopop">*Giriş ücretsiz.</p>
    <h3 className="wxyprpr" id="venedik-yeme-icme">VENEDİK YEME-İÇME</h3>
    <p className="zabrsrs">Sanırım burada en pahalı şeylerden biri de yemek kısmı. Öncelikle çoğu yerde yemeğinizi mekanda oturarak yerseniz kişi başı ortalama 6 Euro civarı servis ücreti ödüyorsunuz. Alıp giderseniz de menüdeki fiyatı ödüyorsunuz.</p>
    <p className="cdetutu">Yerli insanlar Rialto Köprüsü kenarındaki restaurantları tamamen turist odaklı mekanlar oldukları ve şişirilmiş fiyatlarından dolayı  pek önermiyorlar.</p>
    <p className="fghuvuv">Ara sokaklarda hem daha uygun hem de daha lezzetli yemekler yiyebileceğiniz aşikar. Size önerebileceğimiz bir kaç yer;</p>
    <p className="ijkvyvy">Gelati Nico: hem denize sıfır hem de lezzetli dondurmalarıyla mutlaka uğramanız gereken yerlerden biri. Akşam üzeri giderseniz de Aperol Spritz eşliğinde güneşi neden burada batırmayasınız ki? Aperol Spritz 5 Euro yanında cips de veriyorlar. Çoğu yerde olan servis ücreti de yok.</p>
    <div className="content-image-container">
  <div className="content-image-portrait"
      style={{ backgroundImage: `url(../../venedik10.jpg)` }}
      aria-label="VENEDİK YEME-İÇME-2"
      loading="lazy"
  ></div>
</div>
    <p className="lmnyzyz">1000 Gourmet (Pizzacı): Öncelikle buraya akşam gelecekseniz rezervasyon yaptırmalısınız yoksa kapıda uzun bir kuyrukla karşılaşabilirsiniz. Pizzalar çeşidine göre değişmekle birlikte 12 Euro civarı. Servis ücretini de unutmayın.</p>
    <p className="opqzwzw">Hütte: Eve giderken denk geldiğimiz bu küçücük dükkandan böylesine lezzetli ve taze pizza yiyebileceğimizi tahmin etmemiştik. İçeride oturma alanı yok, ayakta yiyebileceğiniz küçük bir alan var. Biz pizzalarımızı alıp çıktık ve bir kanal kenarında yedik. Pizzalar dilimle satılıyor ortalama dilimler 5 Euro.</p>
    <div className="content-image-container">
  <div className="content-image-portrait"
      style={{ backgroundImage: `url(../../venedik22.jpg)` }}
      aria-label="VENEDİK YEME-İÇME-3"
      loading="lazy"
  ></div>
</div>
    <h3 className="rsta" id="ulasim">Ulaşım</h3>
    <div className="content-image-container">
  <div className="content-image-portrait"
      style={{ backgroundImage: `url(../../venedik11.jpg)` }}
      aria-label="Ulaşım"
      loading="lazy"
  ></div>
</div>
    <p className="uvwb">2023 itibariyle Venedik'te toplu ulaşım için uygulanan tarifeyi aşağıda özetledik;</p>
<div>
    <ul>
      <li className="xyzc">Gondol ücretleri 80 Euro.</li>
      <li className="abcd">Vaporetta tek yön 7,5 Euro</li>
      <li className="defe">24 vaporetto saatlik 20 Euro</li>
      <li className="ghif">48 vaporetto saatlik 30 Euro</li>
      <li className="jklg">72 vaporetto saatlik 40 Euro</li>
      <li className="mnoh">7 günlük vaporetto 60 Euro</li>
    </ul>
</div>
    <p className="pqri">Siz yine de güncel fiyatları gitmeden önce mutlaka kontrol edin.</p>
    <h3 className="xxxooopxpx" id="yuruyus">Google Maps için Venedik Yürüyüş Rotası</h3>
        <p className="wwwpxpxooo">
            Noktalar: Victor Emmanuel II Anıtı, Ahlar Köprüsü, Palazzo Ducale, Aziz Mark Çan Kulesi, Aziz Mark Saat Kulesi, San Marko Bazilikası, Rialto Köprüsü, Accademia Köprüsü, Santa Maria Della Salute.
        </p>
        <ul>
          <li className='map-links'>
            <a href="https://goo.gl/maps/SchpC349zFfJDxLaA" target="_blank" rel="noopener noreferrer">Rota için link</a>
          </li>
        </ul>
    <p className="stuj">Buraya kadar okuduğunuz için teşekkür ederiz. Biz Venedik’te keyifli vakit geçirdik, umarız sizler için de öyle olur.</p>
    <p className="vwxk">Sevgiyle kalın.</p>
</div>
      <br />
      <br />
      <DestinationSelector /> 
      <hr className="separator" /> 
      <CommentsSection componentName="destination5" /> 
    </div>
  );
};

export default Destination5;
