import React from 'react';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../../components/Destination.css';
import CommentsSection from '../../components/CommentsSection';
import DestinationSelector from '../../components/DestinationSelector';

const Destination7 = () => {
  const scrollToAttraction = (attractionId) => {
    const elementToScroll = document.getElementById(attractionId);
    if (elementToScroll) {
      elementToScroll.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const location = useLocation();
  const paragraphMarker = location.state?.textIdentifier || '';
  console.log(paragraphMarker);

  useEffect(() => {
    if (paragraphMarker) {
      const elementToHighlight = document.querySelector(`.${paragraphMarker}`);
      if (elementToHighlight) {
        elementToHighlight.classList.add('highlight');
        elementToHighlight.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [paragraphMarker]);

  const attractions = [
    { name: "Şehir İçi Ulaşım", id: "ulasim" },
    { name: "Gezilecek Yerler", id: "yerler" },
    { name: "Schneider Wibbel Saati ve Heykeli", id: "schneider-wibbel" },
    { name: "Little Tokyo", id: "little-tokyo" },
    { name: "Neuer Zollhof (Gehry Binaları)", id: "neuer-zollhof" },
    { name: "Hofgarten Park", id: "hofgarten-park" },
    { name: "Yemek", id: "yemek" },
    { name: "Google Haritalar için Yürüyüş Rotası", id: "google-haritalar" },
  ];

  return (
    <div className="destination-container">
      <Helmet>
        <title>Düsseldorf, Almanya Gezi Rehberi - Hangiyol Seyahat Rehberi</title>
        <meta name="description" content="Düsseldorf, Ren Nehri kıyısında yer alan Almanya'nın kalbinde bir şehir. Modern mimarisi, sanat galerileri ve alışveriş caddeleri ile ünlüdür. Düsseldorf, lezzetli yemekleri, canlı kültür etkinlikleri ve tarihi cazibesiyle ziyaretçilerini büyülemektedir. Renkli festivalleri ve lüks alışveriş olanakları ile unutulmaz bir seyahat deneyimi sunar." />
      </Helmet>

      <h2 className="aaaaaa">Düsseldorf, Almanya</h2>
      <br />

      <div className="destination-content">

        <h3 className="abc">DÜSSELDORF’TA GÖRMENİZ GEREKEN 15 YER</h3>
        <div className="content-image-container">
          <div className="content-image-landscape"
            style={{ backgroundImage: `url(../../dus1.jpg)` }}
            aria-label="Königsallee, Düsseldorf"
            loading="lazy"
          ></div>
        </div>

        <p className="jklccc">Konu başlıklarımızı aşağıdaki listede özetledik:</p>
        <div>
          <ul>
            {attractions.map((attraction) => (
              <li key={attraction.name}>
                <button
                  onClick={() => scrollToAttraction(attraction.id)}
                  style={{
                    border: 'none',
                    cursor: 'pointer',
                    borderRadius: '0.35rem',
                  }}
                >
                  {attraction.name}
                </button>
              </li>
            ))}
          </ul>
        </div>

        <h3 className="def" id="ulasim">Şehir içi ulaşım</h3>
        <p className="ghi">Biz şehre trenle geldiğimiz için Hauptbahnhof tren istasyonundan biletlerimizi aldık. İki gündüz geçireceğimiz için yeşil makinelerden 48 saatlik iki kişilik biletten satın aldık. Bileti satın alırken seyahat edeceğiniz uç noktaları yazıp kaç günlük istediğinizi ve kişi sayınızı  girip yeşil makinelerden kendiniz alabilirsiniz. Özellikle yeşil diye belirtiyorum krem rengi olan makinelerde yok çünkü hiç o makinelerin sırasına girmeyin. Ya da tren istasyonunun içindeki kitapçının da içinde bulunan bilet ofisindeki gişelerden de satın alabilirsiniz fakat çok fazla sıra oluyor ve ilerlemiyor. Biletlerimizin görselini buraya koyuyorum.</p>
        <p className="jkl">Biletinizi aynı anda tek kişilik, iki kişilik gibi 5 kişiye kadar satın alabilmenin yanında 1 günlük 2 günlük gibi kombinlerde yapabiliyorsunuz. Yani eğer kalabalıksanız tek bir bileti 5 kişiye kadar kullanmış oluyorsunuz. Hem daha uyguna gelmiş oluyor. Biletin süresi aldığınız saatten itibaren hesaplanıyor. Bu bileti bütün ulaşım araçlarında sınırsız kullanabiliyorsunuz. Güncel bilet fiyatlarına buradan bakabilirsiniz. https://www.rheinbahn.de/tickets-und-tarife/gelegenheitsfahrer/24-48-stundenticket</p>
        <p className="mno">Not: Toplu taşımada biz bunu nereye okutacağız şimdi diye kendi kendinizi boş yere heyecanlandırıp biletinizi makineye sokmaya kalkmayın, makineler biletinize bir delik açıyor ve sonrasında biletiniz geçersiz oluyor. Biletleriniz herhangi bir kontrol durumunda yanınızda bulunması gerekiyor yoksa büyük bir cezası var.</p>

        <h3 className="pqr" id="yerler" >Eveet sırada gezilecek yerler 🙂</h3>

        <div>
          <ul>
            <li className="stu">Altstadt</li>
            <li className="vwx">Burgplatz</li>
            <li className="yza">Marktplatz</li>
            <li className="bcd">Carlsplatz</li>
            <li className="efg">Königsallee</li>
            <li className="hij">Tritonbrunnen</li>
            <li className="klm">Schneider Wibbel Saati ve Heykeli</li>
            <li className="nop">Katholische Gemeinde St. Lambertus</li>
            <li className="qrs">Rheinuferpromenade</li>
            <li className="tuv">Medienhafen</li>
            <li className="wxy">Rheinturm</li>
            <li className="zab">Little Tokyo</li>
            <li className="cde">Neuer Zollhof(Gehry Binaları)</li>
            <li className="fgh">Hofgarten Park</li>
            <li className="ijk">Kirmes am Rhein</li>
          </ul>
        </div>

        <p className="lmn">Öncelikle Düsseldorf’ta gezmek için bir gününüz varsa o gün asla pazar günü olmamalı. Adınızı unutabilirsiniz ama bu bilgiyi unutmayın asfgdasgfa.</p>
        <p className="opq">Mağazalar, marketler, aklınıza gelebilecek her yer muhtemelen kapalı olacaktır.</p>
        <p className="rst">Boğazınıza bir şey kaçsa dur şu marketten su alalım deseniz yok arkadaşlar o yüzden ona göre plan yapın.😀</p>
        <p className="uvw">Düsseldorf küçük bir şehir olduğundan dolayı şehir merkezini bir günde çok rahat gezebilirsiniz.</p>
        <p className="xyz">Nereleri görelim derseniz ilk sırada Altstadt bölgesi var. Gezilip görülmesi gereken çoğu yer bu bölgede. Gezinize ilk buradan başlayabilirsiniz.</p>
        <p className="abc">Gün batımını izlemek için Burgplatz’a gidip ister merdivenlerde oturabilir isterseniz de nehir kenarında bulunan mekanlarda Düsseldorf birası olan Altbier’ı</p>
        <p className="def">yudumlayabilirsiniz. İçmesi kolay ve yumuşak bir bira.</p>
        <p className="ghi">Gezmeniz gereken diğer meydanlardan biri de Marktplatz. Alman mimarisini net bir şekilde görüp meydanın etrafında bulunan kafe ve barlarda keyifle vakit geçirebilirsiniz.</p>
        <p className="jkl">Carlsplatz meyve, sebze, et ve peynir ürünleri, yerel yiyeceklerin, çiçeklerin satıldığı bir pazar yeri. Görmeyi çok  isterdik fakat biz oradayken kapalı olduğu için göremedik.</p>
        <p className="mno">Kral caddesi olarak da bilinen Königsallee, Düsseldorf’un en lüks mağazalarına, pasajlarına ev sahipliği yapıyor.  Tritonbrunnen(Triton Çeşmesi) de bu caddede havuzun uç kısmında bulunuyor. Caddenin sonuna kadar yürürseniz görmüş olacaksınız.</p>


        <h3 className="pqr" id="schneider-wibbel" >Schneider Wibbel Saati ve Heykeli</h3>

        <div className="content-image-container">
          <div className="content-image-portrait"
            style={{ backgroundImage: `url(../../dus2.jpg)` }}
            aria-label="Schneider Wibbel Saati ve Heykeli"
            loading="lazy"
          ></div>
        </div>

        <p className="vwx">Zamanında Düsseldorf topraklarında fırıncılık yapan bir usta sarhoşken kavgaya karışıp birilerini bıçakla yaralıyor. Bu suç karşısında hapis cezasına çarptırılan fırıncı, cezasını çekmesi için bir arkadaşını ikna etmeyi başarıyor. Ceza çok uzun olmadığı için arkadaşı da cezayı kabul ediyor. Fırıncının arkadaşının hapishanede ölmesi üzerine olayın gerçek yüzü anlaşılıyor ve olay dönemin kralının kulağına gidiyor. Kral da bu haber karşısında üzülüp fırıncıyı affediyor. Yapının ikonik sembol olması ülkenin Fransız işgali altında olduğu dönemdeki öneminden geliyor.</p>
        <p className="yza">Heykelin dizlerinin ve sağ kolunun ovulmasının şans getirildiğine inanılıyor. Heykelin bulunduğu binanın yan cephesinde bulunan saatin içinden de ahşap Wibbel heykeli çıkıyor. Saatleri 11:00, 13:00, 15:00, 18:00, 21:00. Bu saatlerde orada olursanız ahşap heykeli de görebilirsiniz.</p>
        <p className="bcd">Görmeniz gereken yapılardan bir diğeri ise  Alstadt bölgesinde bulunan  Düsseldorf cemaatinin bölge kilisesi Katholische Gemeinde St. Lambertus.  Burgplatz’dan yürümeye başlayıp nehir kenarından dümdüz ilerlediğinizde Rheinuferpromenade(yürüyüş yolundan) geçip Düsseldorf Rıhtımı’(Medienhafen)’na ulaşacaksınız. Rıhtımdan geçerken Rheinturm (Ren Kulesi)’ne varmış olacaksınız. Gözlem kulesine çıkmak isterseniz eğer giriş ücreti yetişkin 10 Euro. Fakat kulede bulunan Qomo Restaurant & Bar’da bir şeyler yiyip içerseniz, gözlem kulesine ücretsiz girebilirsiniz. Gözlem kulesine giriş biletinizi ya online ya da oradan kredi kartıyla almalısınız. Nakit kabul etmiyorlar.</p>

        <h3 className="efg" id="little-tokyo" >Little Tokyo</h3>

        <div className="content-image-container">
          <div className="content-image-portrait"
            style={{ backgroundImage: `url(../../dus3.jpg)` }}
            aria-label="Little Tokyo"
            loading="lazy"
          ></div>
        </div>
        <p className="hij">Eğer Uzak Doğu kültürünü seviyor ve merak ediyorsanız burası size göre bir yer. Kaliteli Uzak Doğu yemekleri yiyebileceğiniz birçok restaurant ve kafelere sahip. Biz pazar günü ziyaret ettiğimiz için çoğu yer kapalıydı fakat deneyecek birkaç atıştırmalık bulmayı başardık safdsfdf</p>


        <h3 className="klm" id="neuer-zollhof" >Neuer Zollhof (Gehry Binaları)</h3>

        <div className="content-image-container">
          <div className="content-image-landscape"
            style={{ backgroundImage: `url(../../dus4.jpg)` }}
            aria-label="Neuer Zollhof(Gehry Binaları)"
            loading="lazy"
          ></div>
        </div>
        <p className="nop">Rıhtımdan daha bu kuleye varmadan önce karşıdan farklı stillerde gözüken binaları göreceksiniz. Prag’da ki dans eden binayı bilmeyeniniz yoktur. İşte bu binanın mimarı olan Frank Gehry’nin buradaki binaları Gehry Binaları olarak biliniyor.</p>


        <h3 className="qrs" id="hofgarten-park" >Hofgarten Park</h3>

        <div className="content-image-container">
          <div className="content-image-portrait"
            style={{ backgroundImage: `url(../../dus5.jpg)` }}
            aria-label="Hofgarten Park"
            loading="lazy"
          ></div>
        </div>

        <p className="tuv">Almanya’nın ilk ve en eski halka açık bahçesi olan çok büyük bir alana yayılmış olan parkı. Vaktiniz varsa bolca burada yürüyüş yapabilirsiniz.</p>

        <div className="content-image-container">
          <div className="content-image-portrait"
            style={{ backgroundImage: `url(../../dus6.jpg)` }}
            aria-label="Düsseldorf am Rhein"
            loading="lazy"
          ></div>
        </div>

        <p className="wxy">Ve son olarak eğer Düsseldorf’u Temmuz’un üçüncü haftasında ziyaret ediyorsanız 2022 yılına kadar Ren nehrinin en büyük fuarı olarak adlandırılan Ren Fuar’ına (Kirmes am Rhein) katılabilirsiniz. 10 gün süren bu fuar Düsseldorf-Oberkassel’de düzenleniyor.</p>
        
        <h3 className="zab" id="yemek" >Yemek</h3>

        <div className="content-image-container">
          <div className="content-image-portrait"
            style={{ backgroundImage: `url(../../dus7.jpg)` }}
            aria-label="Altbier"
            loading="lazy"
          ></div>
        </div>

        <p className="cde">Buraya kadar gelip körili sosis yemeden dönmeyin. Biz Hammer caddesinde bulunan Curry isimli yerde yedik. Aman aman bir sosis değildi ama kötü de değildi. Neuer Zollhof(Gehry Binaları)’u görmeye gittiğinizde burada öğlen yemeği molası verebilirsiniz.  Mekanda oturduğunuz yerde binalar karşınızda kalacak. Sosisi ekmekle mi yoksa patatesle mi istediğiniz sorulursa patates diyebilirsiniz. Biz ekmek istedik ve dünyanın en ince dilim ekmeğini koymuş olabilirler yanına adadfadaf bir daha sorsalar patates deriz 😀. Ortalama sosis fiyatları 4,5 - 6 Euro biralar da 3-4 Euro arası. Kredi kartı geçerli değil. Ödeme nakit yapılıyor.</p>
        
        <div className="content-image-container">
          <div className="content-image-portrait"
            style={{ backgroundImage: `url(../../dus8.jpg)` }}
            aria-label="Currywurst"
            loading="lazy"
          ></div>
        </div>
        
        <p className="fgh">Altstadt bölgesinde bulunan Berger caddesi Uerige’de bira için. Burası hem imalathane hem de sokağa yayılmış birahane. Bizim çay bahçeleri gibi düşünebilirsiniz asdsadsfa</p>
        
        <div className="content-image-container">
          <div className="content-image-portrait"
            style={{ backgroundImage: `url(../../dus9.jpg)` }}
            aria-label="Uerigebrauerei"
            loading="lazy"
          ></div>
        </div>
        
        <p className="ijk">Burada üretilen Altbier isimli biranızı alıp sokak boyunca orda burda dikilip oturabileceğiniz eğlenceli bir yer. Atıştırmalıklar da var. Bira 2.80 - 3 Euro civarı.</p>
        <p className="lmn">Little Tokyo’da bulunan Summit of Asian Treats’de Red Bean Taiyaki denedik gayet lezzetliydi fakat mayhoş tatlar sevmiyorsanız farklı tatlar deneyebilirsiniz. Fiyatlar 3-4 Euro civarı.</p>
        <p className="opq">Eğer yemekleri daha uygun bir hale getirmek isterseniz şehrin çoğu yerinde bulunan Lidl marketlerden alışveriş yapabilirsiniz. Birçok markette hazır sandviçler ya da yemeye hazır yemekler bulunmakta bunlardan da alıp deneyebilirsiniz. Fakat yazımın başında da dediğim gibi pazar günleri marketler kapalı bunu bilerek gezinizi planlamalısınız. Düsseldorf’ta pazar günü açık olan iki market var. Biri Hauptbahnhof tren istasyonunda bulunan EDEKA market diğeri ise havalimanında bulunan Rewe market.</p>
        
        <h3 className="xxxooopxpx" id="rota">Google Maps için Madrid Yürüyüş Rotası</h3>

        <p className="wwwpxpxooo">
            Yürüyüş rotası 1: Little Tokyo, Hofgarten, Alstadt, Basilika St. Lambertus, Burgplatz, Marketplatz, Schneider-Wibbel Heykeli ve Saati, Carlsplatz, Königsallee, Tritonenbrunnen
        </p>
        <ul>
          <li className='map-links'>
            <a href="https://goo.gl/maps/mqbArAyzWyM1y7E6A" target="_blank" rel="noopener noreferrer">Rota 1 için link</a>
          </li>
        </ul>
        <p className="xxxpxpx">
            Yürüyüş rotası 2: Rheinuferpromenade, Rheinkirmes Platz, Rhine Tower, Neuer Zollhof, Medienhafen
        </p>
        <ul>
          <li className='map-links'>
            <a href="https://goo.gl/maps/sAjcZCx56Ayr4vcy5" target="_blank" rel="noopener noreferrer">Rota 2 için link</a>
          </li>
        </ul>
        
        <p className="rst">Keyifli gezmeler.</p>
        <p className="uvw">Sevgiyle kalın.</p>
      </div>
      <br />
      <br />
      <DestinationSelector />
      <hr className="separator" />
      <CommentsSection componentName="destination7" />
    </div>
  );
};

export default Destination7;
