import React from 'react';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../../components/Destination.css';
import CommentsSection from '../../components/CommentsSection';
import DestinationSelector from '../../components/DestinationSelector';

const Destination6 = () => {
  const scrollToAttraction = (attractionId) => {
    const elementToScroll = document.getElementById(attractionId);
    if (elementToScroll) {
      elementToScroll.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const location = useLocation();
  const paragraphMarker = location.state?.textIdentifier || '';
  console.log(paragraphMarker);

  useEffect(() => {
    if (paragraphMarker) {
      const elementToHighlight = document.querySelector(`.${paragraphMarker}`);
      if (elementToHighlight) {
        elementToHighlight.classList.add('highlight');
        elementToHighlight.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [paragraphMarker]);

  const attractions = [
    { name: "Plaza Mayor", id: "plaza-mayor" },
    { name: "Plaza de la Villa", id: "plaza-de-la-villa" },
    { name: "Almudena Katedrali", id: "almudena-katedrali" },
    { name: "Madrid Kraliyet Sarayı", id: "madrid-kraliyet-saray" },
    { name: "The Sabatini Gardens", id: "the-sabatini-gardens" },
    { name: "Debod Temple", id: "debod-temple" },
    { name: "Don Quixote & Sancho Panza Monumento Cervantes", id: "don-quixote" },
    { name: "Cines Callao", id: "cines-callao" },
    { name: "Debod Temple", id: "debod-temple" },
    { name: "Casa de los Portugueses", id: "casa-de-los-portugueses" },
    { name: "Calle Gran Via", id: "calle-gran-via" },
    { name: "Iglesia de San Jose", id: "iglesia-de-san-jose" },
    { name: "Cibeles Sarayı", id: "cibeles-saray" },
    { name: "El Retiro Park", id: "el-retiro-park" },
    { name: "Cuesta de Mayano (Kitapçılar sokağı)", id: "cuesta-de-mayano" },
    { name: "CaixaForum Madrid", id: "caixaforum-madrid" },
    { name: "Museo Del Prado", id: "museo-del-prado" },
    { name: "Museo Reina Sofia", id: "museo-reina-sofia" },
    { name: "Google Haritalar için Yürüme Rotası", id: "rota" },
  ];

  return (
    <div className="destination-container">
    <Helmet>
      <title>Madrid, İspanya Gezi Rehberi - Hangiyol Seyahat Rehberi</title>
      <meta name="description" content="Madrid, İspanya gezisi için seyahat rehberiniz Hangiyol sitesinde. Madrid'in tarihi ve kültürel zenginliklerini keşfedin, önemli mekanları gezip görün." />
    </Helmet>

    <h2 className="aaaaaa">Bir Günde Madrid</h2>
    <br />

  <div className="destination-content">
        <p className="abc">Ne 1 günde Madrid mi? Dediğinizi duyar gibiyim.</p>
        <p className="def">Havanın yaz mevsiminde aşağı yukarı 22:30 gibi karardığını baz alırsak sabah 7:30’dan bu saate kadar 15 saat eder 😀 E sizin tabi en az 2 gününüz varsa daha sakin gezebilirsiniz 🙂</p>
        <h3 className="ghi" >Ulaşım</h3>
        <p className="jkl">Akşam 9 gibi Madrid Barajas Uluslararası Havalimanı’na geldik.</p>
        <p className="mno">Havalimanında hangi terminalde inerseniz inin şehir merkezine ulaşmak için otobüs var. Fakat metroyla geçmek isterseniz sadece T2’de bulunan metro 8 hattını kullanıp birkaç aktarma yapmanız gerekecektir. Bu yüzden otobüs bize daha kolay geldiği için Cibeles Meydanında inebileceğimiz bir otobüse binip ulaşımımızı o şekilde sağladık.</p>
        <p className="pqr">Şehir merkezine geldiğimizde saat 22:00’ı çoktan geçmişti, tüm gün Yaş’da gezdiğimiz ve çok yorgun olduğumuz için kalacağımız yere gidip bu geceyi dinlenerek geçirdik.</p>
        <p className='pqrpqrpqra'>Şehirdeki gezilecek yerleri aşağıda bir liste halinde görebilirsiniz:</p>
        <div>
        <ul>
          {attractions.map((attraction) => (
            <li key={attraction.name}>
              <button 
                onClick={() => scrollToAttraction(attraction.id)}
                style={{
                  border: 'none',         
                  cursor: 'pointer',          
                  borderRadius: '0.35rem',                           
                }}
              >
                {attraction.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="content-image-container">
            <div className="content-image-portrait"
                style={{ backgroundImage: `url(../../mad22.jpg)` }}
                aria-label="Puerta"
                loading="lazy"
            ></div>
        </div> 
        <p className="stu">Sabah 07:30 gibi tüm enerjimizi toplamış halde sokağa çıktığımızda akşamdan kalanları görünce önce bir şok geçirdik. Adım başı sokaklarda geceden kalmış insanlar uyukluyordu. Dinamikleri yüksek meydanlardan biri😀 Neyse ki binaların güzelliği dikkatimizi çekmeyi başardı ve şehrin iki önemli meydanından biri olan Puerta del Sol meydanına (Güneşin Kapısı) geldik. Bence tam zamanında gelmişiz gün biz bu meydandayken yavaş yavaş doğdu. Asıl olayının bu olduğunu düşünüyoruz asdffaddasf 😀</p>
        <div className="content-image-container">
            <div className="content-image-portrait"
                style={{ backgroundImage: `url(../../mad1.jpg)` }}
                aria-label="Puerta"
                loading="lazy"
            ></div>
        </div> 
        <p className="vwx">Puerta del Sol’de; Ayı ve Çilek Ağacı Heykeli (El Oso y El Madrona), meydanda yer alan en eski yapı olan ve üzerindeki saatle dikkat çeken Real Casa de Correos ve İspanya’nın sembolik merkezi olan Sıfır Kilometre levhası da bu binanın önündedir.</p>
        <div className="content-image-container">
            <div className="content-image-portrait"
                style={{ backgroundImage: `url(../../madrid.jpg)` }}
                aria-label="Puerta"
                loading="lazy"
            ></div>
        </div> 
        <h3 className="yza" id="plaza-mayor">Plaza Mayor</h3>
        <div className="content-image-container">
            <div className="content-image-portrait"
                style={{ backgroundImage: `url(../../mad2.jpg)` }}
                aria-label="Plaza Mayor"
                loading="lazy"
            ></div>
        </div>
        <p className="bcd">Şehrin en önemli bir diğer meydanı ise burası. Yerel yönetim ve kültürel amaçlarla kullanılan Casa de la Panaderia,Giambologna ve Pietro Tacca tarafından tasarlanan III.Felipe’ye ait atlı bronz heykeli ve Casa de la Carnıceria(kırmızı bina) da bu meydandadır.</p>
        <h3 className="efg" id="plaza-de-la-villa">Plaza de la Villa</h3>
        <div className="content-image-container">
            <div className="content-image-portrait"
                style={{ backgroundImage: `url(../../mad3.jpg)` }}
                aria-label="Plaza de la Villa"
                loading="lazy"
            ></div>
        </div>
        <p className="hij">Puerta del Sol’den başlayan Calle Mayor caddesinin yanında bulunan bu meydan şehrin tarihi binalarına hala ev sahipliği yapıyor. 17.yy’dan 21.yy’a kadar belediye binası olarak kullanılan Casa de la Vela da bu meydandadır.</p>
        <h3 className="klm" id="almudena-katedrali">Almudena Katedrali</h3>
        <div className="content-image-container">
            <div className="content-image-landscape"
                style={{ backgroundImage: `url(../../mad4.jpg)` }}
                aria-label="Almudena Katedrali"
                loading="lazy"
            ></div>
        </div>
            <p className="nop">Roma Katolik katedrali olan Almudena ayrıca Madrid Başpiskoposluğunun yönetim merkezidir. Saat 10:00 - 21:00 arası ziyaret edebilirsiniz.</p>
            <p className="qrs">*Giriş ücretsiz fakat 1 Euro bağış talep edilebiliyor.</p>
            <h3 className="tuv" id="madrid-kraliyet-saray">Madrid Kraliyet Sarayı</h3>
            <div className="content-image-container">
        <div className="content-image-landscape"
            style={{ backgroundImage: `url(../../mad5.jpg)` }}
            aria-label="Madrid Kraliyet Saray"
            loading="lazy"
        ></div>
        </div>
            <p className="wxy">Almudena Katedraliyle yanyana olan saray İspanya Kraliyet Ailesi’nin ikametgah yeri fakat burada yaşamıyorlar. Bu yüzden parasını bastırıp rahatça gezebilirsiniz 😀 3418 odasıyla Avrupa'daki en büyük kraliyet sarayıdır.</p>
            <p className="zab">*-Giriş ücreti tam bilet 10 Euro,</p>
            <p className="cde">*-Emekliler, geniş aileler,5-16 yaş arası çocuklar 25 yaş altı  öğrenciler 8 Euro</p>
            <p className="fgh">*-5 yaş altı çocuklar ve engelliler ücretsiz.</p>
            <h3 className="ijk" id="the-sabatini-gardens">The Sabatini Gardens</h3>
            <div className="content-image-container">
        <div className="content-image-portrait"
            style={{ backgroundImage: `url(../../mad6.jpg)` }}
            aria-label="The Sabatini Gardens"
            loading="lazy"
        ></div>
        </div>
            <p className="lmn">Sarayın bir parçası olan bu bahçe 1978’de halka açılmış. Bitkilerden oluşan labirentlerin içinde kaybolurcasına gezebilirsiniz. Hem dinlenelim hem de saray manzarasında oturup hayallere dalalım diyorsanız bahçe ücretsiz buyrun gezin tutan yok 😀</p>
            <h3 className="opq" id="debod-temple">Debod Temple</h3>
            <div className="content-image-container">
        <div className="content-image-landscape"
            style={{ backgroundImage: `url(../../mad7.jpg)` }}
            aria-label="Debod Temple"
            loading="lazy"
        ></div>
        </div>
            <p className="rst">Buraya ait olmayan Mısır’dan getirilen Antik Mısır Tapınağı. Çok fazla sıra olabiliyor girişinde. Bizim çok fazla vaktimiz olmadığı için dışını gezip etrafındaki parkta dolaştık. Parkta vakit geçirmek için bile aslında buraya gelebilirsiniz.</p>
            <p className="uvw">*Giriş ücretsiz.</p>
            <h3 className="xyz" id="don-quixote">Don Quixote & Sancho Panza Monumento Cervantes</h3>
            <div className="content-image-container">
        <div className="content-image-portrait"
            style={{ backgroundImage: `url(../../mad8.jpg)` }}
            aria-label="(heykel fotosu gelecek)"
            loading="lazy"
        ></div>
        </div>
            <p className="defxxxdefx">Madrid'in kalbinde yer alan Don Quixote & Sancho Panza Monumento Cervantes, İspanyol edebiyatının en büyük figürlerinden biri olan Don Quixote'un hikayesine saygı duruşu niteliğinde. Bu yüzden buraya da uğramadan edemedik. Tabii bu kadar yürüdükten sonra mola verme vakti geldi. Bu yüzden kısa bir araştırma yapıp yakınlarda yer alan Bar Tapería TapaSpaña isimli mekana gittik.</p>
            <div className="content-image-container">
        <div className="content-image-portrait"
            style={{ backgroundImage: `url(../../mad20.jpg)` }}
            aria-label="Cines Callao"
            loading="lazy"
        ></div>
        </div>
        <p className="defooodefo">Bu mekanı seçmemizdeki en büyük sebep dışındaki iştah açıcı resimler oldu. Fiyat olarak da gayet uygun bulduk. Bir kahvaltılık yiyecek artı içecek olarak bir bira veya bir kahve, 4.5 euro'dan başlayan fiyatlara satılıyordu. Bir tabak patatesli omlet, bir adet salamlı kare tost, bir kahve ve bir biradan oluşan aşağıdaki yemeğimiz 9.5 euro tuttu. Patatesli omlet oldukça lezzetli ve doyurucuydu.</p>
        <div className="content-image-container">
        <div className="content-image-portrait"
            style={{ backgroundImage: `url(../../mad21.jpg)` }}
            aria-label="Cines Callao"
            loading="lazy"
        ></div>
        </div>
        <p className="defyyyxxdefo">Tabii ki yemek demişken Madrid’in ünlü pazarı Mercado de San Miguel'den bahsetmeden de olmaz. Taze yiyecekler için bir numaralı adresiniz burası olacaktır. Karnınız aç olmasa bile burada gördüğünüz tüm yiyeceklerden denemek isteyeceksiniz.</p>
        <div className="content-image-container">
        <div className="content-image-portrait"
            style={{ backgroundImage: `url(../../mad23.jpg)` }}
            aria-label="San Miguel"
            loading="lazy"
        ></div>
        </div>
            <h3 className="def" id="cines-callao">Cines Callao</h3>
            <div className="content-image-container">
        <div className="content-image-portrait"
            style={{ backgroundImage: `url(../../mad18.jpg)` }}
            aria-label="Cines Callao"
            loading="lazy"
        ></div>
        </div>
            <p className="ghi">Madrid'de simgesel bir yer olan bu sinemada genellikle filmlerin galaları yapılır. Vaktiniz varsa belki ispanyolca bir film izler ya da önünden usulca gidersiniz 😀</p>
            <h3 className="jkl" id="casa-de-los-portugueses">Casa de los Portugueses</h3>
            <div className="content-image-container">
        <div className="content-image-portrait"
            style={{ backgroundImage: `url(../../mad9.jpg)` }}
            aria-label="Casa de los Portugueses"
            loading="lazy"
        ></div>
        </div>
            <p className="mno">Sol semtinde bulunan kiremitleri ve balkonlarından sarkan bitkilerle bütünleşmiş tarihi sevimli bina. Önünden geçerseniz mutlaka dikkatinizi çeker.</p>
            <h3 className="pqr" id="calle-gran-via">Calle Gran Via</h3>
            <div className="content-image-container">
        <div className="content-image-portrait"
            style={{ backgroundImage: `url(../../mad10.jpg)` }}
            aria-label="Calle Gran Via"
            loading="lazy"
        ></div>
        </div>
            <p className="stu">Özellikle lüks tüketim ürünlerinin satıldığı  mağazalara sahip olan bu caddeye yakın konaklama yapabilirsiniz. Gecesi ayrı gündüzü ayrı her saat yaşayan bir yer. Baştan sona yürüyüp ara sokaklarda kaybolup, buradaki mekanlarda bir şeyler yiyip içebilirsiniz.</p>
            <h3 className="vwx" id="iglesia-de-san-jose">Iglesia de San Jose</h3>
            <div className="content-image-container">
        <div className="content-image-portrait"
            style={{ backgroundImage: `url(../../mad11.jpg)` }}
            aria-label="Iglesia de San Jose"
            loading="lazy"
        ></div>
        </div>
            <p className="yza">Calle de Alcalá'da bulunan bu barok yapıda kilisenin kayda değer bir sanat koleksiyonu vardır. Gran Via’daysanız burayı da mutlaka rotanıza eklemelisiniz.</p>
            <h3 className="bcd" id="cibeles-saray">Cibeles Sarayı</h3>
            <div className="content-image-container">
        <div className="content-image-portrait"
            style={{ backgroundImage: `url(../../mad12.jpg)` }}
            aria-label="Cibeles Saray"
            loading="lazy"
        ></div>
        </div>
            <p className="efg">Meydandan adını alan bu saray 1900’lerde postane olarak inşa edilmiş düşünebiliyor musunuz? İsminin eskiden Telekomünikasyon Sarayı  olmasını geçtim bir postane binası nasıl böylesine ihtişamlı olabilir ki ? Tabii teknolojinin gelişimiyle buranın işlerinde de bir hayli düşüş olunca biz batmadan burayı elden çıkaralım demişler 😛 Şaka bir yana sonrasında belediye mirasına dahil edilip günümüzde de kültür merkezi ve Madrid Kent Konseyi’nin genel merkezi haline gelmiş.</p>
            <p className="hij">*Binanın içini ücretsiz gezebilirsiniz.</p>
            <p className="klm">**Terasa çıkmak isterseniz yetişkin 3 Euro, 12 yaş altı 1,5 Euro.</p>
            <p className="nop">***Açık olduğu tarih ve saatler: Pazartesi hariç, Salı’dan Pazar gününe kadar  10:30 - 14:00 ve 16:00 - 19:30 saatleri arasında ziyaret edebilirsiniz.</p>
            <h3 className="qrs" id="el-retiro-park">El Retiro Park</h3>
            <div className="content-image-container">
        <div className="content-image-portrait"
            style={{ backgroundImage: `url(../../mad13.jpg)` }}
            aria-label="El Retiro Park"
            loading="lazy"
        ></div>
        </div>
            <p className="tuv">Ben burada uyurum dedim ve bu parkta öğlen uykusuna yattım canım dostlarım😀 Öyle güzel bir park hayal edin yani; yan devrilip yatacak kadar. Bir yanda flamenko dansı eden çokça yaş almış fakat içleri daha on sekiz olan bir grup, bir yanda yoga yapıp bisiklet süren insanlar, bir taraftan gölde sandalda salınanlar, bir yanda çocuklu aileler, etkinlikler, eğlenceler. Yok yok diyeceğiniz Madrid’in en güzel parkı diyebiliriz. Ayrıca sadece sergi zamanları açık olan Palacio de Cristal de park içinde. Mutlaka buraya kadar gelmişken görmeden gitmeyin. Parkta bulunan bir diğer görülecek yer ise Alfonso XII anıtı gölün kenarında devasa gözüken sütunlu anıt.</p>
            <div className="content-image-container">
        <div className="content-image-portrait"
            style={{ backgroundImage: `url(../../mad19.jpg)` }}
            aria-label="El Retiro Park 2"
            loading="lazy"
        ></div>
        </div>
            <p className="wxy">Gölde bot kiralamak isterseniz ki kesin kiralayın keyifli geçen bir etkinlik.</p>
            <p className="zab">*Pazartesi-Cuma arası 45 dakika 6 Euro, Cumartesi ve Pazar 8 Euro. En fazla 4 kişi binebilir. Pazartesi - Pazar: 10:00 - 14:00 / 15:00 - Kapanış Saati, yılın zamanına bağlı olarak 18:30 ile 20:00 arasında değişmektedir.</p>
            <h3 className="cde" id="cuesta-de-mayano">Cuesta de Mayano(Kitapçılar sokağı)</h3>
            <div className="content-image-container">
        <div className="content-image-portrait"
            style={{ backgroundImage: `url(../../mad14.jpg)` }}
            aria-label="Cuesta de Mayano"
            loading="lazy"
        ></div>
        </div>
            <p className="fgh">Rotamızda burası olduğu için parktan kitapçılar yönünden çıktık. Eğer ikinci el kitaplar seviyorsanız dekorları pinterestten fırlamış gibi olan bu kitapçıları gezebilirsiniz.</p>
            <h3 className="ijk" id="caixaforum-madrid">CaixaForum Madrid</h3>
            <div className="content-image-container">
        <div className="content-image-portrait"
            style={{ backgroundImage: `url(../../mad15.jpg)` }}
            aria-label="CaixaForum Madrid"
            loading="lazy"
        ></div>
        </div>
            <p className="lmn">Kültür merkezi olan bu forum farklı zamanlarda etkinlik ve sergilere ev sahipliği yapıyor. Gideceğiniz tarihlerde ilginizi çekebilecek sergi olup olmadığını kontrol edip ona göre gidebilirsiniz. Her etkinliğin fiyatı farklı olabileceğinden yazmıyorum. Buraya kadar gelmişken hemen girişinde bulunan instagram noktalarından biri olan CaixaForum dikey bahçeyi de görebilirsiniz.</p>
            <h3 className="opq" id="museo-del-prado">Museo Del Prado</h3>
            <div className="content-image-container">
        <div className="content-image-portrait"
            style={{ backgroundImage: `url(../../mad16.jpg)` }}
            aria-label="Museo Del Prado"
            loading="lazy"
        ></div>
        </div>
            <p className="rst">Ve gezimizin son durağından bir önceki yer. Sona kalan dona kalmaz efendim. Bu müze öyle hadi iki satır yazdım bitti bir yer de değil. Madrid’e gelip buraya girmeden giderseniz de Madrid’e geldik demeyin. Buraya gelmeden önce oturup ders çalışır gibi çalışmalısınız hangi tablo nerede, hangi odada, o odaya nereden gidilir gibi gibi gibi… Müzede gözden kaçırmamanız gereken eserler, tablolar, müzeye ücretsiz giriş gibi altın bilgileri Prado Müzesi yazımdan okuyabilirsiniz.</p>
            <p className="uvw">*Giriş ücreti tam bilet 15 Euro.</p>
            <h3 className="xyz" id="museo-reina-sofia">Museo Reina Sofia</h3>
            <div className="content-image-container">
        <div className="content-image-landscape"
            style={{ backgroundImage: `url(../../mad17.jpg)` }}
            aria-label="Museo Reina Sofia"
            loading="lazy"
        ></div>
        </div>
            <p className="abc">Madrid’de bulunan Ulusal Sanat müzesidir. Prado müzesinden sonra mutlaka gidilmesi gereken müzelerden biri. Salvador Dali, Joan Miro ve Juan Gris’in eserlerine ev sahipliği yaptığı gibi, Pablo Picasso’nun meşhur La Guernica tablosu da burada sergilenmektedir daha başka bir sebep göstermeye gerek var mı buraya gitmeniz için bence yok, konu kapanmıştır dostlar.</p>
            <p className="def">*Giriş ücreti tam bilet 12 Euro.</p>
            <h3 className="xxxooopxpx" id="rota">Google Maps için Madrid Yürüyüş Rotası</h3>
        <p className="wwwpxpxooo">
            Yürüyüş rotası 1: Puerta del Sol, Plaza Mayor, Mercado de San Miguel, Plaza de la Villa, Almudena Katedrali, Madrid Kraliyet Sarayı, The Sabatini Gardens, Debod Temple, Cines Callao
        </p>
        <ul>
          <li className='map-links'>
            <a href="https://goo.gl/maps/k9vhquturNrPzciK7" target="_blank" rel="noopener noreferrer">Rota 1 için link</a>
          </li>
        </ul>
        <p className="xxxpxpx">
            Yürüyüş rotası 2: Puerta del Sol, Casa de los Portugueses, Calle Gran Via, Iglesia de San Jose, Cibeles Sarayı, El Retiro Park, Cuesta de Mayano, CaixaForum Madrid, Museo Del Prado, Museo Reina Sofia
        </p>
        <ul>
          <li className='map-links'>
            <a href="https://goo.gl/maps/GAAQNo2ibz8XX2it5" target="_blank" rel="noopener noreferrer">Rota 2 için link</a>
          </li>
        </ul>
            <p className="ghi">Keyifli gezmeler, daha fazla hikaye ve bilgiler için instagramdan takip etmeyi unutmayın 🙂</p>
            <p className="jkl">Sevgiyle kalın.</p>
    </div>
          <br />
          <br />
          <DestinationSelector /> 
          <hr className="separator" /> 
          <CommentsSection componentName="destination6" /> 
    </div>
  );
};

export default Destination6;
