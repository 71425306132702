import React from 'react';
import '../../components/tipsPage.css';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import CommentsSection from '../../components/CommentsSection';
import DestinationSelector from '../../components/DestinationSelector';

const Tip4 = () => {
  const location = useLocation();
  const paragraphMarker = location.state?.textIdentifier || '';
  console.log(paragraphMarker);

  useEffect(() => {
    if (paragraphMarker) {
      const elementToHighlight = document.querySelector(`.${paragraphMarker}`);
      if (elementToHighlight) {
        elementToHighlight.classList.add('highlight');
        elementToHighlight.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [paragraphMarker]);

  return (
    <div className={`tipsPage-container`} >
        <Helmet>
            <title>Yolcu Sorumluluğunda Aktarma</title>
            <meta name="description" content="Aktarmalı uçuşlar, yolcu sorumluluğunda aktarma konsepti ve önemli detayları öğrenin. Minimum transfer sürelerine uymak, uzun bekleme ayarlamak, seyahat sigortası yaptırmak gibi önlemlerle seyahatinizi daha sorunsuz hale getirin. Hangiyol ile sorunlara dayanıklı seyahat planları yapın!" />
        </Helmet>

      <h2 className="aaa">Yolcu Sorumluluğunda Aktarma</h2>
      <br />
      <div className="tipsPage-content">
      <p className="bbb">Uçak biletlerinizi bir havayolu şirketinden tek bir rezervasyon numarası ile aldığınızda genellikle aktarmanız garanti altındadır. Bu, tüm uçuşlarınızın birbiriyle koordineli olduğu ve bir uçuşun gecikmesi veya iptal edilmesi durumunda havayolu şirketinin size alternatif bir çözüm sunacağı anlamına gelir. Ancak, seyahat acentelerinden veya farklı havayolu şirketlerinden alınan aktarmalı biletler, bu tür garantilerden yoksun olabilir. Bu nedenle, rezervasyon özetinizde "yolcu sorumluluğunda aktarma" şeklinde bir ibare görürseniz ekstra dikkatli olmalısınız.</p>
      
      <p className="ddd">Yolcu sorumluluğunda aktarma, uçuşlarınızın birbiriyle bağlantılı olmadığı ve herhangi bir şekilde garanti altında bulunmadığı anlamına gelir. Örnek olarak, A şehrinden çıkıp B şehri üzerinden C şehrine gideceğinizi varsayalım ve uçuşunuz yolcu sorumluluğunda olsun. A'dan B'ye ve B'den C'ye olmak üzere iki uçuşunuz olsun. Bu iki uçuş aynı havayolu firmasından olabilir veya farklı havayolu şirketlerine ait olabilir. Ancak, A'dan B'ye olan uçuşunuz iptal edilirse veya B'den C'ye olan uçuşunuzdan daha ileri bir saate veya tarihe ertelenirse, havayolu şirketi veya seyahat acentanız size ücretsiz bir şekilde başka bir uçuş bulma veya biletinizin tamamını iade etme gibi bir sorumluluğa sahip olmayacaktır. Bu durumda, kendinize yeni bir bilet almanız gerekecektir. Ayrıca, havayolu şirketinizin iptal ve iade koşullarına göre artık kullanmayacak olduğunuz o uçuşun ücretinin geri alabileceğiniz kadar olan kısmı için başvuruda bulunmanız gerekecektir.</p>
      
      <p className="fff">Aktarmalı uçuşlar, ekonomik açıdan doğrudan uçuşlarla kıyaslandığında genellikle daha uygun fiyatlar sunar. Bu nedenle birçok yolcu için cazip bir seçenektir. Ancak, aktarmalı uçuşlar, planlı ve dikkatli bir yaklaşım gerektirir. Seyahatinizi daha sorunsuz hale getirmek için aşağıdaki önlemleri alabilirsiniz:</p>
      <ul>
        <li>Minimum Transfer Sürelerine Uymak</li>
        <li>Uzun Bekleme Ayarlamak</li>
        <li>Seyahat Sigortası Yaptırmak</li>
        <li>İletişim Bilgilerinizi Güncel Tutmak</li>
      </ul>
      <h3 className="ggg">Minimum Transfer Sürelerine Uymak</h3>
      <p className="hhh">Havaalanlarının minimum transfer süresi (minimum connection time) olarak isimlendirilen parametreleri bulunmaktadır ve bu parametre havaalanına göre değişiklik göstermektedir. Örneğin Doha havalimanının minimum transfer süresi 45 dakika iken İstanbul Havalimanı'nın minimum transfer süresi 1 saattir. Yani iki uçuşunuz arasında minimum bu kadar süre bulunması gerekmektedir. Gideceğiniz havalimanının bağlantı süresine dikkat edin ve gerektiğinde hızlı bir şekilde bağlantı noktasına geçin. Bu, uçuşlar arasındaki geçişin daha sorunsuz olmasına yardımcı olabilir.</p>
      <h3 className="iii">Uzun Bekleme Ayarlamak</h3>
      <p className="jjj">Tabii ki zaman kısıtlamalarına göre bu konuda yeterince esnek olamayabilirsiniz ama iki uçuş arasında uzun bir bekleme süresi olması son anda ortaya çıkan gecikme ve iptallere karşı seyahat planınızı daha dayanıklı bir hale getirecektir. Hatta, eğer vize kısıtlaması olmazsa ve vaktiniz varsa aktarma yapacağınız şehirde 12 saatlik bir bekleme ayarlayarak o şehri de gezi planınıza dahil etmeyi düşünebilirsiniz. </p>
      <h3 className="kkk">Seyahat Sigortası Yaptırmak</h3>
      <p className="mmm">Seyahat sigortası, beklenmedik durumlar için bir güvence sağlayabilir. Bu tür sigortalar, uçuş iptalleri veya gecikmeleri gibi durumlarda mali kayıplarınızı azaltmanıza yardımcı olabilir. Seyahat sigortası gibi seçenekler siz biletlerinizi alırken seyahat acentası tarafından size sunulacaktır. Ancak, bu tür sigortalar oldukça pahalı olabilmektedir. Seyahatiniz için ayırdığınız bütçeye göre sizin için iyi bir seçenek olup olmayacağına karar verebilirsiniz.</p>
      <h3 className="nnn">İletişim Bilgilerinizi Güncel Tutmak</h3>
      <p className="ooo">Havayolu şirketinizin, erteleme veya iptal gibi durumlarda size ulaşabilmesi için iletişim bilgilerinizin güncel olması gerekmektedir. Bu tür beklenmedik durumlardan ne kadar erken haberdar olursanız, o kadar erken çözüm bulabilirsiniz.</p>
      <p className="ppp">Sevgiyle kalın!</p>
      <p className="lll">Hangiyol</p>
      </div>
      <br />
      <br />
      <DestinationSelector /> 
      <hr className="separator" />
      <CommentsSection componentName="Tip4" /> 
    </div>
  );
};

export default Tip4;
