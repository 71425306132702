import React from 'react';
import NavigationMenu from './NavigationMenu';
import SearchForm from './SearchForm';
import './header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';

const Header = ({ isNightMode, toggleNightMode }) => {
    return (
      <header className="header">
        <nav className="nav">
          <div className="logo">
            <a href="/">
                <img src="./header_mp_logo.svg" alt="Hangiyol Seyahat Rehberi" />
            </a>
          </div>
          <div className="navigation">
            <NavigationMenu />
          </div>
          {isNightMode ? (
            <button onClick={toggleNightMode} title="Gündüz Modu">            
                <FontAwesomeIcon icon={faSun} />           
            </button>          
            ) : (
            <button onClick={toggleNightMode} title="Gece Modu">
                <FontAwesomeIcon icon={faMoon} />
            </button>
          )}
          <div className="search">
            <SearchForm />
          </div>
        </nav>
      </header>
    );
  };
  
  export default Header;