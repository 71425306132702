import React from 'react';
import { useNavigate } from 'react-router-dom';
import "./HeroSection.css";

const HeroSection = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/CountryPage');
  };

  return (
    <section className="hero">
      <div className="hero-content" >
        <h1 onClick={handleButtonClick}>Hangiyol ile Seyahate Başlayın!</h1>
      </div>
    </section>
  );
};

export default HeroSection;
