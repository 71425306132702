import React from 'react';
import { useNavigate } from 'react-router-dom';
import './DestinationPage.css';
import cityDestinations from './destinationIndex';

const CountryPage = () => {
  const navigate = useNavigate();

  const handleClick = (countryId) => {
    navigate(`/DestinationPage`, { state: { countrySelection: countryId } });
  };

  // Create an array to store unique country IDs
  const uniqueCountryIds = [];

  return (
    <div className="DestinationPageParent">
      <div>
        <h2>Ülkeler</h2>
        <div className="destination-list-dp">
          {cityDestinations.map((country) => {
            // Check if the countryId is already in the uniqueCountryIds array
            if (!uniqueCountryIds.includes(country.countryId)) {
              // If not, add it to the array and render the country card
              uniqueCountryIds.push(country.countryId);
              return (
                <div key={country.countryId} className="destination-card" onClick={() => handleClick(country.countryId)}>
                  <img src={country.countryImage} alt={country.countryName} loading="lazy"/>
                  <h5>{country.countryName}</h5>
                </div>
              );
            }
            // If the countryId is already in the array, skip rendering the country card
            return null;
          })}
        </div>
      </div>
    </div>
  );
};

export default CountryPage;
