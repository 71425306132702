import React from 'react';
import "./sideComponents.css";

const AboutPage = ({ isNightMode }) => {
  const imageData = [
    { key: 1, src: '../../nur12.jpg', altText: 'nuremberg-weissgerbergasse' },
  ];

  return (
    <div className={`sideComponents-container ${isNightMode ? 'night-mode' : ''}`} >
      <h2 className="sideComponents-title">Biz Kimiz?</h2>
      <div className="sideComponents-content">
      <div className='content-image-container'>
          <div className="content-image-portrait"
              style={{ backgroundImage: `url(${imageData[0].src})` }}
              aria-label={imageData[0].src}
              loading="lazy"
          ></div>
        </div>
        <p>
          Herkese merhaba biz Hilal ve Yusuf.
        </p>
        <span style={{ display: 'flex' }}>
          <p style={{fontWeight: 'bold'}}>Hilal: </p>
          <p>Çocuklarını çok seven bir öğretmen.</p>
        </span>
        <span style={{ display: 'flex' }}> 
          <p style={{fontWeight: 'bold'}}>Yusuf: </p>
          <p>Beyaz yakalı bir biyolog.</p>
          </span>
        <p>
          2017 yılından beri birlikte seyahat ediyor, farklı kültürler tanıyıp, yeni yerler keşfediyoruz. Bu kadar yer geziyorken tecrübelerimizi neden başkalarıyla da paylaşmıyoruz diyerek bir web sitesi kurmaya ve anılarımızı anlatmaya karar verdik. Sitemizde seyahatle ilgili bir çok yazıyı bulabilir olmayan bilgiler hakkında sorular sorabilirsiniz. Bizimle birlikte olduğunuz ve destekleriniz için şimdiden teşekkürler.
        </p>
        <p>Instagramdan takip etmeyi unutmayın 🙂</p>
        <p>Sevgiyle kalın.</p>
      </div>
    </div>
  );
};

export default AboutPage;
