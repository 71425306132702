import React from 'react';
import "./sideComponents.css";

const ContactPage = ({ isNightMode }) => {
  return (
    <div className={`sideComponents-container ${isNightMode ? 'night-mode' : ''}`} >
      <h2 className="sideComponents-title">İletişim</h2>
      <div className="sideComponents-content">
        <p>
          Kafanıza takılan herhangi bir konuyla ilgili bizimle iletişime geçebilirsiniz. Lütfen instagram profilimizi ziyaret edin:
        </p>
        <ul>
          <li className='contact-links'>
            <a href="https://instagram.com/hangiyol?igshid=MzRlODBiNWFlZA==">Instagram</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ContactPage;
