import React from 'react';
import '../../components/tipsPage.css';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import CommentsSection from '../../components/CommentsSection';
import DestinationSelector from '../../components/DestinationSelector';

const Tip3 = () => {
  const location = useLocation();
  const paragraphMarker = location.state?.textIdentifier || '';
  console.log(paragraphMarker);

  useEffect(() => {
    if (paragraphMarker) {
      const elementToHighlight = document.querySelector(`.${paragraphMarker}`);
      if (elementToHighlight) {
        elementToHighlight.classList.add('highlight');
        elementToHighlight.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [paragraphMarker]);

  return (
    <div className={`tipsPage-container`} >
        <Helmet>
            <title>Konaklama Türleri Hakkında</title>
            <meta name="description" content="Konaklama türleri hakkında bilgi edinin. Oteller, hosteller, kısa süreli kiralık evler ve daha fazlası. Oda seçenekleri, yatak türleri, check-in yöntemleri ve dikkat edilmesi gerekenler hakkında en yardımcı bilgileri Hangiyol'da okuyun." />
        </Helmet>

      <h2 className="aaa">Konaklama Türleri Hakkında</h2>
      <br />
      <div className="tipsPage-content">
      <p className="bbb">Günümüzde seyahat etmek artık sadece otellerde konaklama seçeneğine sıkışmış değil. Seyahat edenlerin ihtiyaçlarına ve tercihlerine daha iyi uyum sağlamak için farklı konaklama türleri ortaya çıktı. Bu makalede, farklı konaklama türlerini ve dikkat etmeniz gereken bazı önemli noktaları inceleyeceğiz.</p>
      <h3 className="ccc">Konaklama Türleri</h3>
      <p className="ddd">Farklı konaklama türleri arasında oteller, geleneksel ve yaygın bir seçenek olup genellikle konforlu odalar, restoranlar ve diğer olanaklar sunarlar. Hosteller daha bütçe dostu bir alternatif sunar ve genellikle genç gezginler arasında popülerdir, burada odaları paylaşarak konaklama maliyeti düşürülebilir. Ayrıca Airbnb gibi platformlar aracılığıyla bulunan kısa süreli kiralık evler, daha fazla özgürlük ve mahremiyet sağlayan bir diğer seçenektir. Konaklama yerlerinde farklı oda seçenekleri sunulur; tamamı size ait olan evler, özel oda, ortak banyolu özel oda ve paylaşımlı oda gibi. Yatak türleri arasında ise twin yatak, arkadaşlar veya aile üyeleri için ideal iki ayrı yatağı içerirken, duble yatak çiftler için genellikle tercih edilen bir büyük yatak türüdür. Ayrıca, ranzalar, bütçe gezginleri için uygun olan hostellerde sıkça görülen bir seçenektir.</p>
      <h3 className="eee">Self Check-in ve Dikkat Edilmesi Gerekenler</h3>
      <p className="ggg">Geleneksel otellerde resepsiyonist size yardımcı olur ve check-in işleminizi gerçekleştirir. Fakat günümüzde oldukça yaygınlaşan kısa süreli kiralık evler gibi bazı yerlerde ise farklı bir yol izlenir. Kimi ev sahibi sizinle buluşup odanızın veya evinizin anahtarını size teslim eder, kimi ise kendi kendinize odaya veya eve giriş yapabilmeniz için anahtarı sadece sizin şifresini bildiğiniz bir kutuya koyar.</p>
      <p className="hhh">Check-in konusunda dikkat edilmesi gereken en önemli nokta en geç ne zaman gelebileceğinizi söyleyen kuralların kontrol edilmesidir. Avrupa'da oteller dışındaki çoğu yerde check-in için saat kısıtlaması uygulanmaktadır. Bazı yerler misafirlerini en geç 17.00'a kadar kabul ederken bazıları gece yarısına hatta daha da geç saatlere kadar girişe izin vermektedir. Tabii ki geç check-in'e izin veren çoğu yer self check-in uygulamaktadır. Bir şehre seyahat planı yaparken otobüs, tren veya uçak biletlerinizi check-in yapabileceğiniz saatlere denk getirmek çok önemlidir. Çünkü bu saati kaçırırsanız, o gecelik ödeme yapmış olsanız dahi mülke alınmazsınız ve ödemeniz de yanmış olur. Bazı yerler ise sizden ek ücret talep edebilirler. Örneğin kimi yerler saat 8'e kadar 20 euro, 12'ye kadar 50 euro gibi geç giriş tarifeleri uygulayabilmektedir.</p>
      <p className="iii">Self check-in başlı başına çok dikkatli olunması gereken bir konudur. Bu tür durumlarda, daha o şehre gitmeden, mülk sahibi ile iletişime geçip mülke nasıl gireceğinizi netleştirmeniz gerekir. Çünkü kalacağınız yerin tam adresi genelde rezervasyonu yaptığınız uygulamada belirtilmez. Bu yüzden gitmeden önce tam adresi öğrenmeniz, kapı numarasını bilmeniz, eğer anahtar kilitli kutuda olacaksa kutunun şifresini edinmeniz gerekmektedir. Bazı ev sahipleri yanınızda telefonunuz olacağını, gelince onları arayacağınızı ve bu bilgilere bu şekilde ulaşacağınızı varsayıp sizinle önden iletişime geçmeyebilirler. O yüzden, gittiğiniz yerde bir telefon hattınız olmayacak ise, bu dediğim konuları mutlaka gitmeden önce açıklığa kavuşturmanız gerekir.</p>
      <p className="jjj">Sonuç olarak, seyahat ederken konaklama türünü ve detaylarını dikkatlice düşünmek, keyifli ve sorunsuz bir tatil deneyimi için önemlidir. Tercihlerinizi ve bütçenizi göz önünde bulundurarak en uygun konaklama seçeneğini seçebilirsiniz.</p>
      <p className="kkk">Sevgiyle kalın!</p>
      <p className="lll">Hangiyol</p>
      </div>
      <br />
      <br />
      <DestinationSelector /> 
      <hr className="separator" />
      <CommentsSection componentName="Tip3" /> 
    </div>
  );
};

export default Tip3;
